import React from "react";
import { SERVER_URL } from "../../func/constants";
import { copyToBuffer } from "../../func/common";

const Endpoints = ({ path, id }) => {
  const haveID = id !== undefined;
  return (
    <div className="card p-3 endpoints">
      <h6 className="mb-3">Endpoints</h6>
      <div className="d-flex align-items-baseline mb-3">
        <div class="input-group">
          <span class="input-group-text" id="basic-addon1">
            GET
          </span>
          <input
            type="text"
            class="form-control me-2 bg-white"
            value={haveID ? `${path}/${id}` : path}
            readOnly
          />
        </div>
        <i
          className="bi bi-files curs-poin me-2"
          onClick={(e) => {
            let url = path;
            if (haveID) url += `${id}`;
            copyToBuffer(SERVER_URL + url);
            e.target.classList.add("text-success");
            setTimeout(() => {
              e.target.classList.remove("text-success");
            }, 2000);
          }}
        />
        <i
          className="bi bi-info-circle"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Тут должна быть информация"
        />
      </div>
      <div className="d-flex align-items-baseline mb-3">
        <div class="input-group">
          <span class="input-group-text" id="basic-addon1">
            POST
          </span>
          <input
            type="text"
            class="form-control me-2 bg-white"
            value={path}
            readOnly
          />
        </div>
        <i
          className="bi bi-files curs-poin me-2"
          onClick={() => {
            let url = path;
            copyToBuffer(SERVER_URL + url);
          }}
        />
        <i
          className="bi bi-info-circle"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Тут должна быть информация"
        />
      </div>
      {haveID && (
        <div className="d-flex align-items-baseline mb-3">
          <div class="input-group">
            <span class="input-group-text" id="basic-addon1">
              DELETE
            </span>
            <input
              type="text"
              class="form-control me-2 bg-white"
              value={`${path}/${id}`}
              readOnly
            />
          </div>
          <i
            className="bi bi-files curs-poin me-2"
            onClick={() => {
              let url = path;
              if (haveID) url += `${id}`;
              copyToBuffer(SERVER_URL + url);
            }}
          />
          <i
            className="bi bi-info-circle"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Тут должна быть информация"
          />
        </div>
      )}
    </div>
  );
};

export default Endpoints;
