import React, { useReducer, useState, useEffect } from "react";
import api from "../../../../func/api";
import Select from "react-select/async";
import $ from "jquery";
import { errorCatch } from "../../../../func/common";
import { ReactSortable } from "react-sortablejs";

const Arr = ({
  changeData,
  data,
  path,
  dataObjInside,
  bodyInsaid,
  namesRefColl,
  dataRefColl,
  setNamesRefColl,
  defaultsNames,
  dispatch,
}) => {
  const [arr, setArr] = useState(
    dataObjInside[bodyInsaid.nameDB]
      ? dataObjInside[bodyInsaid.nameDB].map((el) => ({ value: el }))
      : []
  );
  const [blockView, setBlockView] = useState(
    dataObjInside[bodyInsaid.nameDB]
      ? dataObjInside[bodyInsaid.nameDB]?.length < 10
      : true
  );

  const textView = arr.map((e) => e.value).join(bodyInsaid.separator);

  const elemsHTML = arr.map((elem, i) => {
    return (
      <div className="block-group d-flex me-3 border">
        <span className="block-group-left pointer elem-hand">
          <i className="bi bi-arrows-move" />
        </span>
        <input
          className="block-group-name form-control p-2 border-0"
          style={{ borderRadius: "0px" }}
          value={elem.value}
          onChange={(e) => {
            elem.value = e.target.value;
            setArr([...arr]);
            e.target.style.width = (e.target.value.length + 1) * 8 + 40 + "px";
          }}
        ></input>
        <span
          className="block-group-right ms-auto pointer"
          onClick={() => {
            arr.splice(i, 1);
            setArr([...arr]);
          }}
        >
          <i className="bi bi-x" />
        </span>
      </div>
    );
  });

  useEffect(() => {
    if (dataObjInside[bodyInsaid.nameDB] === undefined) {
      dataObjInside[bodyInsaid.nameDB] = [];
      dispatch({ data: dataObjInside });
    }
  }, []);

  useEffect(() => {
    dataObjInside[bodyInsaid.nameDB] = arr.map((e) => e.value);

    dispatch({ data });
  }, [arr]);

  //   useEffect(() => {}, []);
  return (
    <>
      <div className="d-flex justify-content-between">
        {" "}
        <h6 className="mb-2">{bodyInsaid.name}</h6>{" "}
        <div className="form-check form-switch ">
          <input
            className="form-check-input"
            type="checkbox"
            checked={blockView}
            onChange={(e) => {
              setBlockView(e.target.checked);
            }}
          />
          <label class="form-check-label">Отображать блоками </label>
        </div>
      </div>

      {blockView ? (
        <div className="card bg-gray p-3 mb-3 border-0">
          {elemsHTML?.length ? (
            <ReactSortable
              list={arr}
              setList={setArr}
              // className="row"
              style={{ display: "flex", flexWrap: "wrap" }}
              handle=".elem-hand"
            >
              {elemsHTML}
            </ReactSortable>
          ) : (
            <p className="text-secondary text-center">
              Еще нет записей. Нажмите кнопку ниже, чтобы добавить одну
            </p>
          )}
          <div className="w-100 mt-2 d-flex justify-content-center">
            <div
              className="block-group-btn pointer d-flex justify-content-center cursor-drag"
              onClick={() => {
                arr.push({ value: "" });
                setArr([...arr]);
              }}
            >
              <i className="bi bi-plus text-primary" />
            </div>
          </div>
        </div>
      ) : (
        <textarea
          className="p-4 w-100 rounded border"
          value={textView}
          onChange={(e) => {
            setArr(
              e.target.value
                .split(bodyInsaid.separator)
                .map((el) => ({ value: el }))
            );
          }}
        />
      )}
    </>
  );
};

export default Arr;
