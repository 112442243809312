import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import api from "../../func/api";
import { errorCatch } from "../../func/common";

const SettingsRestList = ({ cms }) => {
  const [rests, setRests] = useState([]);

  const getData = () => {
    api
      .getSettingsRests()
      .then((settingsRest) => {
        console.log(`settingsRest`, settingsRest);
        setRests(settingsRest);
      })
      .catch((err) => {
        errorCatch(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const restListHTML = rests.map((el) => {
    return (
      <tr key={el.slug}>
        <td>
          <Link
            to={`/front/${cms}/settings/rest/${el.slug}`}
            className="tr-title"
          >
            {el.name}
          </Link>
        </td>
        <td className="text-end">
          <i
            className="bi bi-trash pointer me-3"
            onClick={() => {
              api
                .deleteSettingsRest(el._id)
                .then((result) => {
                  getData();
                })
                .catch((err) => {
                  errorCatch(err);
                });
            }}
          />
          <i className="bi bi-files-alt pointer me-3" />
        </td>
      </tr>
    );
  });

  return (
    <main className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center mb-4 mt-4">
            <div>
              <h4 className="m-0">REST запросы</h4>
            </div>
            <div>
              <Link
                className="btn btn-primary"
                to={`/front/${cms}/settings/rest/new`}
              >
                Создать
              </Link>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="">
            {/* <ul class="nav nav-tabs border-0">
              <li class="nav-item">
                <a class="nav-link p-2 active" aria-current="page" href="#">
                  Все
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link p-2" href="#">
                  Активные (10)
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link p-2 disabled"
                  href="#"
                  tabindex="-1"
                  aria-disabled="true"
                >
                  Архив
                </a>
              </li>
            </ul> */}
          </div>
          <div className="d-flex align-items-center filters">
            <input
              placeholder="Поиск"
              className="ms-2 ps-2 rounded text-secondary border-secondary border-1 filters-input"
            />
            <button className="btn btn-outline-dark ms-2">
              <i className="bi bi-search" />
            </button>
          </div>
        </div>
        <div className="border rounded mb-3">
          <table className="table table-striped mb-0">
            <thead className="th-title">
              <tr>
                <th>Название</th>
                <th className="text-end pe-4">Управление</th>
              </tr>
            </thead>
            <tbody>{restListHTML}</tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <nav aria-label="Page navigation example">
              <ul class="pagination m-0">
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="input-group input-group_small">
            <span class="input-group-text">Выводить</span>
            <input type="text" class="form-control" placeholder="10" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default SettingsRestList;
