import React, { useEffect, useState } from "react";
import PopupEditMedia from "./PopupMedia";
import EmptyMedia from "./EmptyMedia";
import UploadMedia from "./UploadMedia";
import { SERVER_URL } from "../../func/constants";

const UploadMediaBlock = (props) => {
  const {
    activePic,
    getData,
    setEditMedia,
    isCollection,
    openChooseMedia,
    setActivePic,
  } = props;

  useEffect(() => {
    return () => {};
  }, []);

  console.log(`activePicBlock`, activePic);

  return activePic ? (
    <UploadMedia
      state={props.state}
      changeUploadType={props.changeUploadType}
      activePic={activePic}
      getData={getData}
      setEditMedia={setEditMedia}
      isCollection={isCollection}
      openChooseMedia={openChooseMedia}
    />
  ) : (
    <EmptyMedia
      state={props.state}
      changeUploadType={props.changeUploadType}
      getData={getData}
      isCollection={isCollection}
      openChooseMedia={openChooseMedia}
      setActivePic={setActivePic}
    />
  );
};

export default UploadMediaBlock;
