import React, { useState, useEffect, useCallback, useRef } from "react";
import $ from "jquery";
import { Draggable } from "react-beautiful-dnd";
import Select from "react-select";
import { SERVER_URL } from "../../func/constants";
// import 'cropperjs/dist/cropper.css';
import Cropper from "cropperjs";
import api from "../../func/api";

const grid = 8;
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const colourStyles = {
  container: (styles) => ({
    ...styles,
    backgroundColor: "white",
    flexGrow: "1",
  }),

  control: (styles) => ({
    ...styles,
    borderRadius: "0px 2px 2px 0px",
    // borderRight: "none",
  }),
};

let crop = 0;
let cropTimer = null;

const cropInit = (elem) => {
  if (elem) {
    crop = new Cropper(elem, {
      aspectRatio: NaN,
      preview: ".img-preview",
      crop: function (event) {
        // console.log(event.detail.x);
        // console.log(event.detail.y);
        // console.log(event.detail.width);
        // console.log(event.detail.height);
        // console.log(event.detail.rotate);
        // console.log(event.detail.scaleX);
        // console.log(event.detail.scaleY);
      },
      ready: function (e) {
        console.log(e);
      },
      responsive: true,
    });
    crop.reset();
    console.log(`crop`, crop);
  } else {
    cropTimer = setTimeout(() => {
      cropInit(elem);
    }, 250);
  }
};

const PopupEditMedia = (props) => {
  //   const [count, setCount] = useState(0);
  const { close, link, save, elem } = props;
  const imgMain = useRef(null);
  console.log(`props`, props);

  useEffect(() => {
    // console.log(`imgMain.current`, imgMain.current);
    if (link) {
      const imgHtml = document.querySelector(".media-block>img");
      cropInit(imgHtml);
    }

    return () => {
      if (crop) {
        crop.destroy();
      }
      if (cropTimer) {
        clearTimeout(cropTimer);
        cropTimer = null;
      }
    };
  }, [link]);

  // const photodRef = useCallback((node) => {
  //   console.log(`node`, node);
  //   if (node !== null && !crop) {
  //     crop = new Cropper(node, {
  //       aspectRatio: NaN,
  //       preview: ".img-preview",
  //       crop: function (event) {
  //         // console.log(event.detail.x);
  //         // console.log(event.detail.y);
  //         // console.log(event.detail.width);
  //         // console.log(event.detail.height);
  //         // console.log(event.detail.rotate);
  //         // console.log(event.detail.scaleX);
  //         // console.log(event.detail.scaleY);
  //       },
  //       ready: function (e) {
  //         console.log(e.type);
  //         console.log(`cropper.getContainerData();`, crop.getContainerData());
  //       },
  //     });
  //   }
  // }, []);

  return (
    <div class="modal-dialog modal-xl">
      <div class="modal-content p-3">
        <div class="row">
          <div class="col-8 modal-img">
            <div class="border h-100 media-block">
              <img src={link} ref={imgMain}></img>
            </div>
          </div>
          <div class="col-4 modal-tool">
            <div class="img-preview border mb-3"></div>
            <div class="img-btns">
              <button
                class="btn bg-gr me-2 mb-2"
                onClick={() => {
                  if (crop) {
                    crop.zoom(0.1);
                  }
                }}
              >
                Zoom in<i class="bi bi-zoom-in ms-2"></i>
              </button>
              <button
                class="btn bg-gr me-2 mb-2"
                onClick={() => {
                  if (crop) {
                    crop.zoom(-0.1);
                  }
                }}
              >
                Zoom out<i class="bi bi-zoom-in ms-2"></i>
              </button>
              <button
                class="btn bg-gr me-2 mb-2"
                onClick={() => {
                  if (crop) {
                    const contData = crop.getContainerData();
                    crop.setCanvasData({
                      width: contData.width,
                      top: 0,
                      left: 0,
                    });
                  }
                }}
              >
                Width<i class="bi bi-arrow-left-right ms-2"></i>
              </button>
              <button
                class="btn bg-gr me-2 mb-2"
                onClick={() => {
                  if (crop) {
                    const contData = crop.getContainerData();
                    crop.setCanvasData({
                      height: contData.height,
                      top: 0,
                      left: 0,
                    });
                  }
                }}
              >
                Height<i class="bi bi-arrow-down-up ms-2"></i>
              </button>
              <button
                class="btn bg-gr me-2 mb-2"
                onClick={() => {
                  if (crop) {
                    crop.rotate(-45);
                  }
                }}
              >
                Rotate
                <i class="bi bi-arrow-counterclockwise ms-2"></i>
              </button>
              <button
                class="btn bg-gr me-2 mb-2"
                onClick={() => {
                  if (crop) {
                    crop.rotate(45);
                  }
                }}
              >
                Rotate<i class="bi bi-arrow-clockwise ms-2"></i>
              </button>
              <button
                class="btn bg-gr me-2 mb-2"
                onClick={() => {
                  if (crop) {
                    crop.reset();
                  }
                }}
              >
                Undo<i class="bi bi-arrow-repeat ms-2"></i>
              </button>
            </div>
            <div class="d-flex">
              <button
                class="btn btn-primary me-3"
                download="cropped.jpg"
                onClick={(e) => {
                  if (crop) {
                    const data = crop
                      .getCroppedCanvas()
                      .toDataURL("image/jpeg");
                    // e.target.href =
                    api
                      .updMedia({ data, name: props.name, id: props.id })
                      .then((result) => {
                        console.log(`result`, result);

                        save({
                          ...result,
                          url: result.url + "?v" + Math.random() * 100,
                        });
                        close();
                      })
                      .catch((err) => {
                        console.log(`err`, err);
                      });
                  }
                }}
              >
                Save
              </button>
              <button class="btn btn-outline-dark" onClick={close}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupEditMedia;
