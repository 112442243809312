import React, { useState } from "react";
import { SERVER_URL } from "../../func/constants";
import $ from "jquery";

const EmptyMedia = (props) => {
  const { isCollection, getData, openChooseMedia, setActivePic } = props;
  const [showURL, setShowURL] = useState(false);
  const [progress, setProgress] = useState(0);
  const changeUrl = () => {
    setShowURL(!showURL);
  };
  return (
    <div className="card-body p-0">
      <div className="d-flex">
        <div className="card-camera bg-gray w-50 d-flex justify-content-center align-items-center rounded-start">
          <i className="bi bi-camera-fill bg-camera fs-60" />
        </div>
        <div className="d-flex flex-column justify-content-between w-50 p-3">
          <div>
            <h6>Фото или видео</h6>
            <p>Загрузите с устройства, URL или перетащите файл в эту область</p>
          </div>
          <div>
            {showURL ? (
              <div class="input-group mt-3 mb-3">
                <input type="text" class="form-control" />
                <span class="input-group-text pointer">Upload</span>
                <span class="input-group-text pointer" onClick={changeUrl}>
                  <i className="bi bi-x" />
                </span>
              </div>
            ) : (
              <div className="input-group">
                <button
                  type="button"
                  class="btn border bg-btn"
                  onClick={(e) => {
                    $(e.target).find('input[type="file"]').click();
                  }}
                >
                  Загрузить
                  <input
                    type="file"
                    multiple
                    name="uploadImg"
                    accept="image/jpeg,image/png,image/svg+xml"
                    onInput={(e) => {
                      if (e.target.files.length) {
                        let cmsSlug = null;
                        if (window.location.pathname.includes("/front/")) {
                          let path = window.location.pathname.split("/front/");
                          path.shift();

                          if (path.length) {
                            path = path[0].split("/")[0];
                          }

                          cmsSlug = path;
                        }
                        if (!cmsSlug) return;
                        const formData = new FormData();
                        e.target.files[0].alt = "test";
                        formData.append("uploadImg", e.target.files[0]);
                        let xhr = new XMLHttpRequest();

                        xhr.onloadend = () => {
                          if (xhr.status == 200) {
                            console.log("Успех");
                            // console.log(`xhr`, xhr);
                            getData(xhr.response);
                            const body = JSON.parse(xhr.response);

                            if (body && body.length === 1 && body[0])
                              setActivePic({ ...body[0] });
                          } else {
                            console.log("Ошибка " + this.status);
                          }
                        };
                        xhr.onprogress = function (event) {
                          setProgress((event.loaded / event.total) * 100);
                          console.log(
                            "Загружено на сервер " +
                              event.loaded +
                              " байт из " +
                              event.total
                          );
                        };
                        xhr.open(
                          "POST",
                          SERVER_URL + "/" + cmsSlug + "/upload-media"
                        );
                        xhr.send(formData);
                      }
                    }}
                  />
                </button>
                <div className="dropdown">
                  <button
                    type="button"
                    class="btn border bg-btn dropdown-toggle"
                    id="dropdownUploadMenu"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-caret-down" />
                  </button>
                  <ul
                    class="dropdown-menu mt-2"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        class="dropdown-item"
                        type="button"
                        onClick={changeUrl}
                      >
                        URL
                      </a>
                    </li>
                    {/* {isCollection ? (
                    <li>
                      <a
                        class="dropdown-item"
                        type="button"
                        onClick={openChooseMedia}
                      >
                        Media
                      </a>
                    </li>
                  ) : (
                    ""
                  )} */}
                  </ul>
                </div>
              </div>
            )}
            <div className="d-flex border rounded progbar mt-2">
              <div
                className="rounded-start border bg-primary"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmptyMedia;
