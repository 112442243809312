import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import api from "../../func/api";
import { errorCatch } from "../../func/common";
import Pagination from "../common/Pagination";

const RoleList = ({ cms }) => {
  const [roles, setRoles] = useState([]);
  const [limitInPage, setLimitInPage] = useState(40);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);

  const getData = () => {
    api
      .getRoles(`page=${page}&limit=${limitInPage}`)
      .then((roles) => {
        console.log(`roles`, roles);
        setRoles(roles);
      })
      .catch((err) => {
        errorCatch(err);
      });
  };

  const getCount = () => {
    api
      .getInfoCMSCount()
      .then(({ count }) => {
        setCount(count);
      })
      .catch((err) => {
        errorCatch(err);
      });
  };

  useEffect(() => {
    getCount();
  }, []);

  useEffect(() => {
    getData();
  }, [page, limitInPage]);

  const roleListHTML = roles.map((el) => {
    return (
      <tr key={el.slug}>
        <td>
          <Link
            to={`/front/${cms}/settings/role/${el.slug}`}
            className="tr-title"
          >
            {el.name}
          </Link>
        </td>
        <td className="text-end">
          <i
            className="bi bi-trash pointer me-3"
            onClick={() => {
              api
                .deleteRole(el._id)
                .then((result) => {
                  getData();
                })
                .catch((err) => {
                  errorCatch(err);
                });
            }}
          />
          <i className="bi bi-files-alt pointer me-3" />
        </td>
      </tr>
    );
  });

  return (
    <main className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center mb-4 mt-4">
            <div>
              <h4 className="m-0">Роли</h4>
            </div>
            <div>
              <Link
                className="btn btn-primary"
                to={`/front/${cms}/settings/role/new`}
              >
                Создать
              </Link>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="">
            {/* <ul class="nav nav-tabs border-0">
              <li class="nav-item">
                <a class="nav-link p-2 active" aria-current="page" href="#">
                  Все
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link p-2" href="#">
                  Активные (10)
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link p-2 disabled"
                  href="#"
                  tabindex="-1"
                  aria-disabled="true"
                >
                  Архив
                </a>
              </li>
            </ul> */}
          </div>
          <div className="d-flex align-items-center filters">
            <input
              placeholder="Поиск"
              className="ms-2 ps-2 rounded text-secondary border-secondary border-1 filters-input"
            />
            <button className="btn btn-outline-dark ms-2">
              <i className="bi bi-search" />
            </button>
          </div>
        </div>
        <div className="border rounded mb-3">
          <table className="table table-striped mb-0">
            <thead className="th-title">
              <tr>
                <th>Название</th>
                <th className="text-end pe-4">Управление</th>
              </tr>
            </thead>
            <tbody>{roleListHTML}</tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limitInPage={limitInPage}
          setLimitInPage={setLimitInPage}
          countAll={count}
        />
      </div>
    </main>
  );
};

export default RoleList;
