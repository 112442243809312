import React, { useReducer, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../func/api";
import { settingsFields } from "../../func/constants";
import ModalDelete from "../common/ModalDelete";
import ModalOvelay from "../common/ModalOverlay";
import SearchElem from "../common/SearchElem";
import ModalEdit from "./ModalEdit";
import Order from "./Order";

const activeTypeToText = {
  collections: ["Коллекция", "коллекцию"],
  pages: ["Страница", "страницу"],
  components: ["Компонент", "компонент"],
};

const changeState = (state, action) => {
  return { ...action.data };
};

const defaultData = {
  name: "",
  body: [],
};

const createNew = ({ type, data }) => {
  if (type === "model") {
    return api.postConstr(data);
  } else if (type === "component") {
    return api.postComponents(data);
  } else {
    return api.postSinglePage(data);
  }
};
const deletePoint = ({ type, id }) => {
  if (type === "model") {
    return api.deleteSchema(id);
  } else if (type === "component") {
    return api.deleteComponent(id);
  } else {
    return api.deleteSinglePage(id);
  }
};

const Constructor = ({ slug, type, cms }) => {
  const [data, dispatch] = useReducer(changeState, defaultData);
  const [activeType, setActiveType] = useState("");

  const [schemas, setSchemas] = useState([]);
  const [components, setComponents] = useState([]);
  const [singlePage, setSinglePage] = useState([]);
  const [dataField, setDataField] = useState({});

  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);
  const [modalPath, setModalPath] = useState([]);
  const [typeCreate, setTypeCreate] = useState("model");
  const [search, setSearch] = useState("");
  const [newName, setNewName] = useState("");

  const [deleteText, setDeleteText] = useState("");
  const [deleteTitle, setDeleteTitle] = useState("");
  const [deleteFunc, setDeleteFunc] = useState(null);

  const [lockTypes, setLockTypes] = useState({});

  const closePopUp = () => {
    setOpen(false);
    setDataField({});
    setNewName("");
    setModalPath([]);
  };

  const schemasSelect = [];
  const schemasList = [];
  const componentsSelect = [];
  const componentsList = [];
  const singlePagesSelect = [];
  const singlePagesList = [];

  useEffect(() => {
    api
      .getLockTypes()
      .then((result) => {
        setLockTypes(result);
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  }, []);

  const getDatas = () => {
    api
      .getConstr()
      .then((result) => {
        setSchemas(result);
        dispatch({ data: result[0] });
      })
      .catch((err) => {
        console.log(`err`, err);
      });

    // api
    //   .getDatas("select")
    //   .then((result) => {
    //     console.log(`result`, result);
    //   })
    //   .catch((err) => {
    //     console.log(`err`, err);
    //   });

    api
      .getComponents()
      .then((result) => {
        setComponents(result);
      })
      .catch((err) => {
        console.log(`err`, err);
      });
    api
      .getSinglePages()
      .then((result) => {
        console.log(`single`, result);
        setSinglePage(result);
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  };

  useEffect(() => {
    getDatas();
  }, []);

  useEffect(() => {
    let activeData = null;
    let activeType = null;
    let typeCreate = null;
    if (slug) {
      activeData = schemas.find((model) => slug === model.slug);
      activeType = "collections";
      typeCreate = "model";
      if (!activeData) {
        activeData = singlePage.find((model) => slug === model.slug);
        activeType = "pages";
        typeCreate = "page";
        if (!activeData) {
          activeData = components.find((model) => slug === model.slug);
          activeType = "components";
          typeCreate = "component";
        }
      }
    }

    dispatch({ data: activeData ?? schemas[0] ?? defaultData });
    setActiveType(activeType ?? "");
    setTypeCreate(typeCreate ?? "model");
  }, [slug, schemas, singlePage, components]);

  const fields = [];

  schemas.forEach((el) => {
    if (!search || el.name.toLowerCase().includes(search.toLowerCase())) {
      schemasSelect.push({ label: el.name, value: el.nameDB });
      schemasList.push(
        <Link
          // onClick={() => {
          //   if (typeCreate !== "model") setTypeCreate("model");
          //   dispatch({ data: el });
          // }}
          className={`constr-item d-block ${
            data.slug === el.slug ? "active" : ""
          }`}
          to={`/front/${cms}/constructor/${el.slug}`}
        >
          <p class="text-dark m-0 ps-0" type="button">
            {el.name}
          </p>
        </Link>,
      );
    }
  });

  singlePage.forEach((el) => {
    if (!search || el.name.toLowerCase().includes(search.toLowerCase())) {
      singlePagesSelect.push({ label: el.name, value: el.nameDB });
      singlePagesList.push(
        <Link
          // onClick={() => {
          //   if (typeCreate !== "page") setTypeCreate("page");
          //   dispatch({ data: el });
          // }}
          className={`constr-item d-block ${
            data.slug === el.slug ? "active" : ""
          }`}
          to={`/front/${cms}/constructor/${el.slug}`}
        >
          <p class="text-dark m-0 ps-0" type="button">
            {el.name}
          </p>
        </Link>,
      );
    }
  });

  components.forEach((el) => {
    if (!search || el.name.toLowerCase().includes(search.toLowerCase())) {
      componentsSelect.push({ label: el.name, value: el });
      componentsList.push(
        <Link
          className={`constr-item d-block ${
            data.slug === el.slug ? "active" : ""
          }`}
          to={`/front/${cms}/constructor/${el.slug}`}
          // onClick={() => {
          //   if (typeCreate !== "component") setTypeCreate("component");
          //   dispatch({ data: el });
          // }}
        >
          <p class="text-dark m-0 ps-0" type="button">
            {el.name}
          </p>
        </Link>,
      );
    }
  });

  // console.log(`componentsSelect`, componentsSelect);
  // console.log(`typeCreate`, typeCreate);

  let pathTimeCont = ["body"];
  let isComponent = false;

  const lockTypeData =
    data && data.lockName && lockTypes && lockTypes[data.lockName]
      ? lockTypes[data.lockName]
      : null;

  const lockTypeNameFields = lockTypeData
    ? lockTypeData.body.map((el) => el.nameDB)
    : [];

  console.log("lockTypeData :>> ", lockTypeData);
  console.log("lockTypeNameFields :>> ", lockTypeNameFields);

  const createHTMLFields = (prev, el, i, arr) => {
    if (el.typeField !== "component") {
      const isComponentInside = isComponent;
      prev.push(
        <div
          className={`d-flex pointer flex-row p-2 ps-3`}
          onClick={() => {
            if (!isComponentInside) {
              setOpen(true);
              setModalPath([...pathTimeCont, i]);
              setDataField(el);
            }
          }}
        >
          {/* <div className="constr-dash"></div> */}
          <div
            className={``}
            style={{ position: "relative", boxSizing: "content-box" }}
          >
            {settingsFields[el.typeField].badge}
            {lockTypeData !== null &&
              lockTypeNameFields.includes(el.nameDB) && (
                <i
                  className="bi bi-lock-fill"
                  style={{
                    position: "absolute",
                    bottom: "-5px",
                    right: "10px",
                    fontSize: "10px",
                    padding: "3px",
                    borderRadius: "50px",
                    backgroundColor: "#CED4DA",
                  }}
                ></i>
              )}
          </div>

          <span className="pe-1">{el.name}</span>
          <span className="text-secondary">
            ({settingsFields[el.typeField].name})
          </span>
          {(lockTypeData === null || !lockTypeNameFields.includes(el.nameDB)) &&
            !isComponentInside && (
              <i
                class="bi bi-trash pointer ms-auto"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteTitle("Удалить поле?");
                  setDeleteText(
                    `Поле «${el.name}» и его данные будут удалены безвозвратно.`,
                  );
                  setDeleteFunc(() => {
                    return () => {
                      data.body.splice(i, 1);
                      dispatch({ data });
                      setDeleteFunc(null);
                      setDeleteText("");
                    };
                  });
                }}
              />
            )}
        </div>,
      );
    } else {
      // pathTimeCont = [...pathTimeCont, i, "component", "body"];
      isComponent = true;
      prev.push(
        <div className={` comp-block-list pointer`}>
          <div
            className={`d-flex flex-row p-2 ps-3`}
            onClick={() => {
              // setOpen(true);
              // setModalPath(["body", i]);
              // setDataField(el);
            }}
          >
            {settingsFields[el.typeField].badge}
            <span className="pe-1">{el.name}</span>
            <span className="text-secondary">
              ({settingsFields[el.typeField].name})
            </span>
            <i
              class="bi bi-trash pointer ms-auto"
              onClick={() => {
                setDeleteTitle("Удалить поле?");
                setDeleteText(
                  `Поле «${el.name}» и его данные будут удалены безвозвратно.`,
                );
                setDeleteFunc(() => {
                  return () => {
                    data.body.splice(i, 1);
                    dispatch({ data });
                    setDeleteFunc(null);
                    setDeleteText("");
                  };
                });
              }}
            />
          </div>
          <div className="ms-33 border-start comp-block-list__elems ps-3">
            {el.component
              ? el.component.body.reduce(createHTMLFields, [])
              : null}
          </div>
        </div>,
      );
      isComponent = false;
      pathTimeCont = ["body"];
    }

    return prev;
  };
  if (data.body) fields.push(data.body.reduce(createHTMLFields, []));

  const edit = (
    <div className="container-fluid">
      <div className="row">
        <div className="col drop-bg block-2 page-construct__list p-0 pt-4">
          <SearchElem
            className="mb-3 p-3"
            onClick={(searchString) => setSearch(searchString)}
          />
          {/* <div class="input-group mb-3 p-3">
            <input
              type="text"
              class="form-control border-end-0"
              id="inputGroup"
              aria-describedby="inputGroup"
              placeholder="Поиск"
              onChange={({ target }) => {
                setSearch(target.value);
              }}
              value={search}
            />
            <i className="bi bi-search pointer input-group-text bg-light border-start-0 text-secondary" />
          </div> */}
          <div class="mb-3 px-3">
            <h6 className="d-flex justify-content-between align-items-center">
              Типы коллекций ({schemasList.length}){" "}
              <i
                className="bi bi-plus-circle text-primary curs-poin"
                onClick={() => {
                  setOpen(true);
                  if (typeCreate !== "model") setTypeCreate("model");

                  dispatch({ data: defaultData });
                }}
              ></i>
            </h6>
            {schemasList.slice(0, 3)}
            {schemasList.length > 3 && (
              <>
                <div
                  class="w-100 collapse border-0 bg-transparent "
                  id="collapseNewType"
                >
                  {schemasList.slice(3, schemasList.length)}
                </div>
                <div>
                  <a
                    class="text-primary ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    href="#collapseNewType"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseNewType"
                    style={{
                      opacity: 0.5,
                      padding: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    Посмотреть все ({schemasList.length})
                  </a>
                </div>
              </>
            )}
          </div>
          <div class="mb-3 px-3">
            <h6
              class=""
              className="d-flex justify-content-between align-items-center"
            >
              Одиночный тип
              <i
                className="bi bi-plus-circle text-primary curs-poin"
                onClick={() => {
                  setOpen(true);
                  if (typeCreate !== "page") setTypeCreate("page");

                  dispatch({ data: defaultData });
                }}
              ></i>
            </h6>
            <div>{singlePagesList.slice(0, 3)}</div>
            {singlePagesList.length > 3 && (
              <>
                <div
                  class="w-100 collapse border-0 bg-transparent "
                  id="collapseOneType"
                >
                  {singlePagesList.slice(3, singlePagesList.length)}
                </div>
                <div>
                  <a
                    class="text-primary ps-0"
                    data-bs-toggle="collapse"
                    href="#collapseOneType"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseOneType"
                    type="button"
                    style={{
                      opacity: 0.5,
                      padding: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    Посмотреть все ({singlePagesList.length})
                  </a>
                </div>
              </>
            )}
          </div>
          <div class="mb-3 px-3">
            <h6 className="d-flex justify-content-between align-items-center">
              Компоненты ({componentsList.length})
              <i
                className="bi bi-plus-circle text-primary curs-poin"
                onClick={() => {
                  setOpen(true);
                  if (typeCreate !== "component") setTypeCreate("component");
                  dispatch({ data: defaultData });
                }}
              ></i>
            </h6>
            {componentsList.slice(0, 3)}

            {componentsList.length > 3 && (
              <>
                <div
                  class="w-100 collapse border-0 bg-transparent "
                  id="collapseComp"
                >
                  {componentsList}
                </div>
                <div>
                  <a
                    class=" text-primary ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    href="#collapseComp"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseComp"
                    style={{
                      opacity: 0.5,
                      padding: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    Посмотреть все ({componentsList.length})
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col block-3 page-construct__fields">
          <div className="d-flex justify-content-between align-items-center mb-4 mt-4">
            <div>
              <h5 className="m-0">{data.name}</h5>
            </div>
            <div>
              <button className="btn btn-outline-danger" onClick={getDatas}>
                Отменить
              </button>
              <button
                className="btn btn-primary ms-3"
                onClick={(e) => {
                  let error = false;
                  createNew({ type: typeCreate, data, api })
                    .then(() => {
                      getDatas();
                    })
                    .catch((err) => {
                      console.log(`err`, err);
                    })
                    .finally(() => {
                      getDatas();
                      e.target.classList.add("btn-success");
                      setTimeout(() => {
                        e.target.classList.remove("btn-success");
                      }, 2000);
                    });
                }}
              >
                Сохранить
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="mb-0">Полей: {data.body.length}</p>
            <div>
              <button
                className="btn btn-outline-dark"
                onClick={() => {
                  setOpenOrder(true);
                }}
              >
                <i class="bi bi-border-style me-2" />
                Настроить порядок
              </button>
              <button
                className="btn btn-outline-primary ms-3"
                onClick={() => {
                  setDataField({});
                  setModalPath([]);
                  setOpen(true);
                }}
              >
                <i class="bi bi-plus text-primary me-2" />
                Новое поле
              </button>
            </div>
          </div>
          <div className="card product-card fs-6 mb-3">{fields}</div>
          <div className="d-flex justify-content-end align-items-center mb-4">
            <button
              className="btn btn-danger"
              onClick={() => {
                console.log("activeType", activeType);
                setDeleteText(
                  `${activeTypeToText[activeType][0]} "${data.name}" и все ее данные будут удалены безвозвратно.`,
                );
                setDeleteTitle(`Удалить ${activeTypeToText[activeType][1]}?`);
                setDeleteFunc(() => {
                  return () => {
                    deletePoint({ type: typeCreate, id: data._id, api })
                      .then(() => {
                        getDatas();
                        setDeleteText("");
                        setDeleteFunc(null);
                      })
                      .catch((err) => console.log(`err`, err));
                  };
                });
              }}
            >
              Удалить {data.name}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const order = <Order data={data} dispatch={dispatch} type={typeCreate} />;

  return (
    <main>
      {openOrder ? order : edit}
      <ModalOvelay open={open} closePopUp={closePopUp}>
        <ModalEdit
          data={data}
          open={open}
          setOpen={setOpen}
          dispatch={dispatch}
          path={modalPath}
          dataField={dataField}
          setDataField={setDataField}
          componentsSelect={componentsSelect}
          schemasSelect={schemasSelect}
          newName={newName}
          setNewName={setNewName}
          closePopUp={closePopUp}
          lockTypes={lockTypes}
        />
      </ModalOvelay>

      <FolderCreateModal />
      <ModalDelete
        title={deleteTitle}
        open={deleteText}
        text={deleteText}
        closePopUp={() => {
          setDeleteText("");
          setDeleteFunc(null);
        }}
        deleteFunc={deleteFunc}
      />
    </main>
  );
};

const FolderCreateModal = () => {
  return (
    <div
      class="modal"
      id="uploadMediaModal"
      tabindex="-1"
      aria-labelledby="uploadMediaModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light">
            <h5 class="modal-title" id="uploadMediaModalLabel">
              Создание папки
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Constructor;
