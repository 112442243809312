// import { observer } from "mobx-react";
import React, { useState } from "react";
// import api from "./api";
import { NavLink, Link } from "react-router-dom";
import { withRouter } from "react-router";
import api from "../func/api";
// import ProductCard from "./ProductCard";

const { Component } = React;

const Header = ({ user }) => {
  const userCheck = user !== undefined && user !== null;
  return (
    <div className="navbar navbar-dark navbar-expand-lg bg-dark navbar_top sticky-top">
      <div className="container-fluid">
        <NavLink to="/">
          <i className="bi bi-house-door-fill"> </i>Дэшборд{" "}
        </NavLink>
        <li>
          <button
            onClick={() => {
              api
                .getCSVFromLink()
                .then((res) => {
                  const { html } = res;
                  const url = encodeURI("data:text/csv;charset=utf-8," + html);
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", `exp.csv`);
                  document.body.appendChild(link);
                  link.click();

                  // Clean up and remove the link
                  link.parentNode.removeChild(link);
                })
                .catch((err) => {
                  console.log("err :>> ", err);
                });
            }}
          >
            save
          </button>
        </li>
        {userCheck && (
          <div className="d-flex">
            <div className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/profile">
                Профиль
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    api
                      .logout()
                      .then(() => {})
                      .catch((err) => {
                        console.log(`err`, err);
                      });
                  }}
                >
                  Выход
                </a>
              </li>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
