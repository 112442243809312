import React, { useEffect, useReducer, useRef, useState } from "react";
import api from "../../func/api";
import { errorCatch } from "../../func/common";
import {
  createDynamicLineEditAccess,
  createLineEditAccess,
} from "../../func/roleCommon";
import $ from "jquery";

const staticPoints = [
  {
    name: "Media",
    nameDB: "media",
  },
];

const domeanRegEx = new RegExp(/[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,9}/);
const ipRegEx = new RegExp(
  /((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)/
);

const staticPointsCollections = [];
const staticPointsPages = [];

const reducer = (state, action) => {
  return { ...action.state };
};

const SettingsRest = ({ id }) => {
  const [state, dispatch] = useReducer(reducer, {
    name: "",
    domains: [],
    ips: [],
    collections: {
      edit: false,
      read: true,
      create: false,
      delete: false,
      settings: false,
    },
    pages: {
      edit: true,
      read: true,
      create: true,
      delete: true,
      settings: false,
    },
    media: {
      edit: true,
      read: true,
      create: true,
      delete: true,
      settings: false,
    },
  });
  const [dataReady, setDataReady] = useState(false);

  const isNew = id === "new";

  const domeanInputRef = useRef(null);
  const ipInputRef = useRef(null);

  useEffect(() => {
    api
      .getSettingsRest(id)
      .then((restData) => {
        state.collectionsList = {};
        state.pagesList = {};
        restData.collections.forEach(({ name, slug }) => {
          staticPointsCollections.push({
            name,
            nameDB: slug,
            collection: true,
          });
          if (restData.role === null) {
            state.collectionsList[slug] = {
              default: true,
              edit: false,
              read: false,
              create: false,
              delete: false,
              settings: false,
            };
          } else if (restData.role.collectionsList[slug] === undefined) {
            restData.role.collectionsList[slug] = {
              default: true,
              edit: false,
              read: false,
              create: false,
              delete: false,
              settings: false,
            };
          }
        });
        restData.pages.forEach(({ name, slug }) => {
          staticPointsPages.push({
            name,
            nameDB: slug,
            pages: true,
          });
          if (restData.role.pagesList === undefined) {
            restData.role.pagesList = {};
          }
          if (restData.role === null) {
            state.pagesList[slug] = {
              default: true,
              edit: false,
              read: false,
              create: false,
              delete: false,
              settings: false,
            };
          } else if (restData.role.pagesList[slug] === undefined) {
            restData.role.pagesList[slug] = {
              default: true,
              edit: false,
              read: false,
              create: false,
              delete: false,
              settings: false,
            };
          }
        });
        if (restData.role === null) dispatch({ state });
        else dispatch({ state: restData.role });

        setDataReady(true);
      })
      .catch((err) => {
        errorCatch(err);
      });
  }, []);

  return (
    dataReady && (
      <main className="main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mt-4">
              <h4>{state.name}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <div className="card p-3 mb-4">
                <h6>Общие настройки</h6>
                <div class="input-group mb-2">
                  <span class="input-group-text text-secondary">Название</span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={state.name}
                    onChange={(e) => {
                      state.name = e.target.value;
                      dispatch({ state });
                    }}
                  />
                </div>
                <div class="input-group mb-2">
                  <span class="input-group-text text-secondary">Домен</span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Введите домен"
                    ref={domeanInputRef}
                  />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => {
                      //!добавить обрабочик ошибки валидации
                      const value = domeanInputRef.current.value;
                      if (
                        domeanRegEx.test(value) &&
                        !state.domains.includes(value)
                      ) {
                        state.domains.push(value);
                        dispatch({ state });
                        domeanInputRef.current.value = "";
                      }
                    }}
                  >
                    Добавить
                  </button>
                </div>
                {state.domains.length > 0 &&
                  state.domains.map((e, i) => {
                    return (
                      <div className="mb-2">
                        <span className="me-2">{e}</span>
                        <i
                          className="bi bi-x-circle-fill text-primary"
                          onClick={() => {
                            state.domains.splice(i, 1);
                            dispatch({ state });
                          }}
                        />
                      </div>
                    );
                  })}
                <div class="input-group mb-2">
                  <span class="input-group-text text-secondary">IP</span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Введите IP"
                    ref={ipInputRef}
                  />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => {
                      //!добавить обрабочик ошибки валидации
                      const value = ipInputRef.current.value;
                      if (ipRegEx.test(value) && !state.ips.includes(value)) {
                        state.ips.push(value);
                        dispatch({ state });
                        ipInputRef.current.value = "";
                      }
                    }}
                  >
                    Добавить
                  </button>
                </div>
                {state.ips.length > 0 &&
                  state.ips.map((e, i) => {
                    return (
                      <div className="mb-2">
                        <span className="me-2">{e}</span>
                        <i
                          className="bi bi-x-circle-fill text-primary"
                          onClick={() => {
                            state.ips.splice(i, 1);
                            dispatch({ state });
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
              <div className="card p-3 mb-4">
                {staticPoints.map((e) => {
                  return createLineEditAccess({ e, state, dispatch });
                })}
              </div>
              <div className="card p-3 mb-4">
                <div className="pb-3 mb-3 border-bottom">
                  {createLineEditAccess({
                    e: { name: "Доступ к коллекциям", nameDB: "collections" },
                    state,
                    dispatch,
                  })}
                </div>
                {staticPointsCollections.map((e) => {
                  return createDynamicLineEditAccess({
                    e,
                    state,
                    dispatch,
                    field: "collectionsList",
                  });
                })}
              </div>
              <div className="card rounded p-3 mb-4">
                <div className="pb-3 mb-3 border-bottom">
                  {createLineEditAccess({
                    e: { name: "Доступ к страницам", nameDB: "pages" },
                    state,
                    dispatch,
                  })}
                </div>
                {staticPointsPages.map((e) => {
                  return createDynamicLineEditAccess({
                    e,
                    state,
                    dispatch,
                    field: "pagesList",
                  });
                })}
              </div>
            </div>
            <div className="col-4">
              <div className=" card p-3 mb-4">
                <button
                  className="btn btn-primary mb-2"
                  onClick={(e) => {
                    api
                      .postSettingsRest(state)
                      .then((data) => {
                        if (isNew) {
                          window.location.pathname =
                            "/settings/rest/" + data.slug;
                        } else {
                          $(e.target).addClass("bg-success");
                          setTimeout(() => {
                            $(e.target).removeClass("bg-success");
                          }, 2500);
                          dispatch({ state: data });
                        }
                      })
                      .catch((err) => {
                        $(e.target).addClass("bg-danger");
                        setTimeout(() => {
                          $(e.target).removeClass("bg-danger");
                        }, 2500);
                        errorCatch(err);
                      });
                  }}
                >
                  Сохранить
                </button>
                <button className="btn btn-light mb-2">Отменить и выйти</button>
                <p className="text-secondary m-0 fs-14">
                  Правлено: 01.12.2020 12:10 (danil)
                </p>
              </div>

              <div className=" card p-3 mb-4">
                <h6>Доступы</h6>
                <div className="mb-3">
                  <p className="m-0">Чтение и изменения</p>
                  <p className="m-0 text-secondary fs-12">
                    Позволяет только редактировать и просматривать запись
                  </p>
                </div>
                <div className="mb-3">
                  <p className="m-0">Только чтение</p>
                  <p className="m-0 text-secondary fs-12">
                    Позволяет только просматривать запись
                  </p>
                </div>
                <div className="mb-3">
                  <p className="m-0">Полный доступ</p>
                  <p className="m-0 text-secondary fs-12">
                    Позволяет создавать, редактировать, просматривать и удалять
                    запись
                  </p>
                </div>
                <div className="">
                  <p className="m-0">Запретить все</p>
                  <p className="m-0 text-secondary fs-12">
                    Запрещает создавать, редактировать, просматривать и удалять
                    запись
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  );
};

export default SettingsRest;
