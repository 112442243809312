import React, { useEffect, useState } from "react";
import PopupEditMedia from "./PopupMedia";
import EmptyMedia from "./EmptyMedia";
import UploadMedia from "./UploadMedia";
import UploadMediaBlock from "./UploadMediaBlock";
import { SERVER_URL } from "../../func/constants";

const UploadMediaModal = (props) => {
  const {
    activePic,
    setActivePic,
    open,
    close,
    getData,
    editMediaDefault = false,
  } = props;
  console.log(`editMediaDefault`, editMediaDefault);

  const [editMedia, setEditMedia] = useState(editMediaDefault);

  useEffect(() => {
    return () => {};
  }, []);

  const closeEditMedia = () => {
    if (editMediaDefault) close();
    else setEditMedia(false);
  };

  const saveEditPic = () => {
    // getData();
    setActivePic((pic) => {
      console.log("pic :>> ", pic);
      return {
        ...pic,
        url: pic.url + "?v" + Math.random() * 100,
      };
    });
  };

  const imgLink = `${SERVER_URL + "/" + activePic?.url}`;
  console.log("activePicModal :>> ", activePic);
  return (
    <div
      class={`modal fade ${open ? "show" : ""}`}
      // id="uploadMediaModal"
      // tabindex="-1"
      // aria-hidden="true"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      onClick={(e) => {
        e.stopPropagation();

        if (e.currentTarget === e.target) close();
      }}
    >
      {!editMedia ? (
        <div
          class="modal-dialog modal-xl modal-dialog-centered modal-dialog-media"
          style={{ zIndex: 1070 }}
        >
          <div class="modal-content">
            <div class="modal-header bg-light">
              <h5 class="modal-title" id="uploadMediaModalLabel">
                Загрузка медиа
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  close();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <UploadMediaBlock
                activePic={activePic}
                getData={getData}
                setEditMedia={setEditMedia}
                setActivePic={setActivePic}
              />
            </div>
          </div>
        </div>
      ) : (
        <PopupEditMedia
          link={imgLink}
          // active={this.state.popupActive}
          id={activePic?._id}
          close={closeEditMedia}
          save={getData}
        />
      )}
    </div>
  );
};

export default UploadMediaModal;
