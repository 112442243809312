import React, { useReducer, useState, useEffect } from "react";
import { slugify } from "transliteration";
import api from "../../../../func/api";
import Select from "react-select/async";
import $ from "jquery";
import { errorCatch } from "../../../../func/common";
import { ReactSortable } from "react-sortablejs";

const Automat = ({
  changeData,
  data,
  path,
  dataObjInside,
  bodyInsaid,
  namesRefColl,
  dataRefColl,
  setNamesRefColl,
  defaultsNames,
  dispatch,
}) => {
  let nameFields = bodyInsaid.data.nameDBField;
  useEffect(
    () => {
      switch (bodyInsaid.settingField) {
        case "slugifator":
          dataObjInside[bodyInsaid.nameDB] = slugify(
            dataObjInside[bodyInsaid.data.nameDBField],
            {
              replace: [["_", "-"]],
              separator: "-",
            }
          );
          break;
        case "<":
          dataObjInside[bodyInsaid.nameDB] =
            dataObjInside[bodyInsaid.data.nameDBFields[0]] <
            dataObjInside[bodyInsaid.data.nameDBFields[1]];
          break;
        case ">":
          dataObjInside[bodyInsaid.nameDB] =
            dataObjInside[bodyInsaid.data.nameDBFields[0]] >
            dataObjInside[bodyInsaid.data.nameDBFields[1]];
          break;
        case "+":
          dataObjInside[bodyInsaid.nameDB] =
            dataObjInside[bodyInsaid.data.nameDBFields[0]] +
            dataObjInside[bodyInsaid.data.nameDBFields[1]];
          break;
        case "*":
          dataObjInside[bodyInsaid.nameDB] =
            dataObjInside[bodyInsaid.data.nameDBFields[0]] *
            dataObjInside[bodyInsaid.data.nameDBFields[1]];
          break;

        default:
          break;
      }

      dispatch({ data: dataObjInside });
    },
    bodyInsaid.data.nameDBFields
      ? [
          dataObjInside[bodyInsaid.data.nameDBFields[0]],
          dataObjInside[bodyInsaid.data.nameDBFields[1]],
        ]
      : [dataObjInside[bodyInsaid.data.nameDBField]]
  );

  return (
    <>
      <h6>{bodyInsaid.name}</h6>
      <div className="input-group mb-4">
        <input
          className="form-control"
          type="text"
          //   placeholder="Заголовок"
          value={dataObjInside[bodyInsaid.nameDB]}
          readOnly
        />
      </div>
    </>
  );
};

export default Automat;
