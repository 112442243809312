import React, { useRef, useState } from "react";
import { SERVER_URL } from "../../func/constants";
import { copyToBuffer } from "../../func/common";
import $ from "jquery";
import api from "../../func/api";

const UploadMedia = (props) => {
  const { isCollection, activePic, setEditMedia, getData, openChooseMedia } =
    props;
  const [showURL, setShowURL] = useState(false);
  const changeUrl = () => {
    setShowURL(!showURL);
  };

  const btnClickEffect = (e) => {
    e.target.classList.add("text-success");
    setTimeout(() => {
      e.target.classList.remove("text-success");
    }, 2000);
  };

  const linkImg = useRef(null);
  const size = activePic.size ? activePic.size / 1024 : 0;

  const imgLink = `${SERVER_URL + "/" + activePic?.url}`;
  return (
    activePic !== null && (
      <div className="card-body p-0">
        <div className="d-flex">
          <div
            className="p-2 card-camera  w-50 d-flex flex-column align-items-end rounded-start"
            style={{
              backgroundImage: "url(" + imgLink + ")",
            }}
          >
            {/* <div
              className="icon-badge bg-white rounded text-center mb-1 pointer"
              onClick={() => {}}
            >
              <i className="bi bi-trash-fill text-danger" />
            </div> */}
            <div
              className="icon-badge bg-white rounded text-center mb-1 pointer"
              onClick={() => {
                setEditMedia(true);
              }}
            >
              <i className="bi bi-pencil-fill" />
            </div>
            <div
              className="icon-badge bg-white rounded text-center mb-1 pointer"
              onClick={(e) => {
                copyToBuffer(imgLink);
                btnClickEffect(e);
              }}
            >
              <i className="bi bi-link-45deg" />
            </div>
            <div
              className="icon-badge bg-white rounded text-center mb-1 pointer"
              onClick={(e) => {
                linkImg.current.click();
                btnClickEffect(e);
                // var a = document.createElement("a");
                // a.setAttribute("href", imgLink);
                // a.setAttribute("download", true);
                // a.click();
              }}
            >
              <i className="bi bi-box-arrow-in-down" />
              <a
                href={imgLink}
                download
                style={{ opacity: 0, visibility: "hidden" }}
                ref={linkImg}
              ></a>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between w-50 p-3">
            <div>
              <h5 className="mb-4">{activePic.name}</h5>
              <div className="d-flex flex-wrap">
                <div className="w-50">
                  <p className="m-0 text-secondary fs-12">Размер</p>
                  {size !== undefined && (
                    <p className="m-0">
                      {size > 1024
                        ? (size / 1024).toFixed(2) + " MB"
                        : size.toFixed(2) + " KB"}
                    </p>
                  )}
                </div>
                {activePic.dateCreate !== undefined && (
                  <div className="w-50">
                    <p className="m-0 text-secondary fs-12">Дата</p>
                    <p className="m-0">{activePic.dateCreate}</p>
                  </div>
                )}
                <div className="w-50">
                  <p className="m-0 text-secondary fs-12">Размеры</p>
                  <p className="m-0">
                    {activePic.width}x{activePic.height}
                  </p>
                </div>
                <div className="w-50">
                  <p className="m-0 text-secondary fs-12">Расширение</p>
                  <p className="m-0">
                    {activePic.ext
                      ? activePic.ext?.toUpperCase()
                      : activePic?.ext}
                  </p>
                </div>
              </div>
            </div>
            {showURL ? (
              <div class="input-group mt-3 mb-3">
                <input type="text" class="form-control" />
                <span class="input-group-text pointer">Upload</span>
                <span class="input-group-text pointer" onClick={changeUrl}>
                  <i className="bi bi-x" />
                </span>
              </div>
            ) : (
              <div className="input-group">
                <button
                  type="button"
                  class="btn border bg-btn"
                  onClick={(e) => {
                    $(e.target).find('input[type="file"]').click();
                  }}
                >
                  Заменить
                  <input
                    type="file"
                    name="uploadImg"
                    accept="image/jpeg,image/png,image/svg+xml"
                    onInput={(e) => {
                      if (e.target.files.length) {
                        let cmsSlug = null;
                        if (window.location.pathname.includes("/front/")) {
                          let path = window.location.pathname.split("/front/");
                          path.shift();

                          if (path.length) {
                            path = path[0].split("/")[0];
                          }

                          cmsSlug = path;
                        }
                        if (!cmsSlug) return;

                        const formData = new FormData();
                        formData.append("uploadImg", e.target.files[0]);
                        let xhr = new XMLHttpRequest();

                        xhr.onloadend = () => {
                          if (xhr.status == 200) {
                            console.log("Успех");
                            // console.log(`xhr`, xhr);
                            console.log(`getData`, getData);
                            getData(xhr.response);
                          } else {
                            console.log("Ошибка " + this.status);
                          }
                        };

                        xhr.open(
                          "POST",
                          SERVER_URL + "/" + cmsSlug + "/upload-media"
                        );
                        xhr.send(formData);
                      }
                    }}
                  />
                </button>
                <div className="dropdown">
                  <button
                    type="button"
                    class="btn border bg-btn dropdown-toggle"
                    id="dropdownUploadMenu"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-caret-down" />
                  </button>
                  <ul
                    class="dropdown-menu mt-2"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        class="dropdown-item"
                        type="button"
                        onClick={changeUrl}
                      >
                        URL
                      </a>
                    </li>
                    {/* {isCollection ? (
                      <li>
                        <a
                          class="dropdown-item"
                          type="button"
                          onClick={openChooseMedia}
                        >
                          Media
                        </a>
                      </li>
                    ) : null} */}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        <div class="input-group mt-3">
          <span class="input-group-text">Alt</span>
          <input
            type="text"
            class="form-control"
            defaultValue={activePic.alt}
            onChange={(e) => {
              activePic.alt = e.target.value;
            }}
            onBlur={() => {
              api
                .postAltMedia({ _id: activePic._id, alt: activePic.alt })
                .then((result) => {})
                .catch((err) => {});
            }}
          />
        </div>
      </div>
    )
  );
};

export default UploadMedia;
