import React, { useEffect, useState } from "react";
import Select from "react-select";
import api from "../../func/api";
import $ from "jquery";
import { errorCatch } from "../../func/common";

const colourStyles = {
  container: (styles) => ({
    ...styles,
    backgroundColor: "white",
    flexGrow: "1",
  }),
  control: (styles) => ({
    ...styles,
    borderRadius: "0px 2px 2px 0px",
    // borderRight: "none",
  }),
};

const Person = ({ id, userData, cms }) => {
  const [data, setData] = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    password: "",
    role: "",
  });
  const [roles, setRoles] = useState([]);
  const [cmsList, setCmsList] = useState([]);
  const [passCheck, setPassCheck] = useState(false);

  const isNew = id === "new";

  useEffect(() => {
    if (isNew) {
      api
        .getRolesActive()
        .then((result) => {
          const rolesSelect = result.map((el) => {
            return { label: el.name, value: el._id };
          });
          setRoles(rolesSelect);
        })
        .catch((err) => {
          errorCatch(err);
        });
    } else {
      api
        .getUser(id)
        .then((result) => {
          console.log(`result`, result);
          setData(result.data);
          const rolesSelect = result.roles.map((el) => {
            return { label: el.name, value: el._id };
          });
          setRoles(rolesSelect);
        })
        .catch((err) => {
          errorCatch(err);
        });
    }
  }, []);

  useEffect(() => {
    if (userData?.role?.cmsSettings) {
      api
        .getInfoCMSs()
        .then((result) => {
          setCmsList(result);
        })
        .catch((err) => {
          errorCatch(err);
        });
    }
  }, [userData]);

  return (
    <main className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center mb-4 mt-4">
            <div>
              <h4 className="m-0">{data.name}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <div className="card p-3 mb-4">
              <h5>Общие настройки</h5>
              <div className="row">
                <div className="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-secondary">Имя</span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      value={data.name}
                      onChange={(e) => {
                        data.name = e.target.value;
                        setData({ ...data });
                      }}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-secondary">Логин</span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      value={data.username}
                      onChange={(e) => {
                        if (!e.target.value.includes(" ")) {
                          data.username = e.target.value;
                          setData({ ...data });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-secondary">Роль</span>
                    {(isNew || data.role) && (
                      <Select
                        options={roles}
                        defaultValue={
                          isNew
                            ? null
                            : {
                                value: data.role._id,
                                label: data.role.name,
                              }
                        }
                        styles={colourStyles}
                        onChange={(e) => {
                          data.role = e.value;
                          setData({ ...data });
                          // this.changeData(e.value, "brand");
                          //   console.log(`e`, e);
                        }}
                        placeholder="Выберите роль"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-secondary">Email</span>
                    <input
                      type="email"
                      class="form-control"
                      placeholder=""
                      value={data.email}
                      onChange={(e) => {
                        data.email = e.target.value;
                        setData({ ...data });
                      }}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-secondary">Телефон</span>
                    <input
                      type="number"
                      class="form-control"
                      placeholder=""
                      value={data.phone}
                      onChange={(e) => {
                        data.phone = e.target.value;
                        setData({ ...data });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-3">
              <h5>Пароль</h5>
              <div className="row">
                <div className="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-secondary">
                      Новый пароль
                    </span>
                    <input
                      type="password"
                      class="form-control"
                      placeholder=""
                      onChange={(e) => {
                        data.password = e.target.value;
                        setData({ ...data });
                      }}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-secondary">
                      Повторите пароль
                    </span>
                    <input
                      type="password"
                      class="form-control"
                      placeholder=""
                      onChange={(e) => {
                        if (data.password === e.target.value)
                          setPassCheck(true);
                        else setPassCheck(false);

                        setData(data);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            {/* <div className="card p-3 mb-4">
              <h5>Фото профиля</h5>
              <img src="" />
            </div> */}
            <div className="card p-3">
              <button
                className="btn btn-primary mb-2"
                onClick={(e) => {
                  api
                    .postUser(data)
                    .then((state) => {
                      console.log(`state`, state);
                      if (isNew) {
                        window.location.pathname = `/front/${cms}/settings/user/${state.username}`;
                      } else {
                        $(e.target).addClass("bg-success");
                        setTimeout(() => {
                          $(e.target).removeClass("bg-success");
                        }, 2500);
                        setData(state);
                      }
                    })
                    .catch((err) => {
                      $(e.target).addClass("bg-danger");
                      setTimeout(() => {
                        $(e.target).removeClass("bg-danger");
                      }, 2500);
                      console.log(`err`, err);
                    });
                }}
              >
                Сохранить
              </button>
              <button className="btn btn-light mb-2">Отменить и выйти</button>

              <p className="text-secondary m-0 fs-14">
                Обновлено: 01.12.2020 12:10 (Admin)
              </p>
            </div>
            <div className="card p-3">
              <h6>Доступ к CMS</h6>
              {cmsList.map((cms) => (
                <div className="form-check">
                  <label>{cms.name}</label>
                  <input
                    className="form-check-input pointer"
                    type="checkbox"
                    checked={data.cmsAccess ? data.cmsAccess[cms.slug] : false}
                    onChange={({ target }) => {
                      if (!data.cmsAccess) {
                        data.cmsAccess = {};
                      } else if (!target.value) {
                        const haveOneTrue = Object.values(
                          data.cmsAccess
                        ).filter((access) => access);
                        if (haveOneTrue.length === 1) {
                          return;
                        }
                      }
                      console.log("data :>> ", data);
                      data.cmsAccess[cms.slug] = target.checked;
                      setData({ ...data });
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Person;
