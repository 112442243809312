import React, { useEffect, useState } from "react";
import api from "../../func/api";
import { errorCatch } from "../../func/common";
import $ from "jquery";

const defaultState = {
  compress: true,
  compressValue: 80,
  adaptive: true,
  adaptiveValue: {
    large: 1000,
    medium: 750,
    small: 500,
    thumbnail: 236,
  },
};

const SettingsMedia = ({}) => {
  const [settings, setSettings] = useState(defaultState);
  console.log(`settings`, settings);

  useEffect(() => {
    api
      .getSettingsSelect("media")
      .then((result) => {
        console.log(`result`, result);
        if (result.settings.media) {
          setSettings(result.settings.media);
        }
      })
      .catch((err) => {
        errorCatch(err);
      });
  }, []);

  const editAdaptive = Object.keys(defaultState.adaptiveValue).some((type) => {
    return defaultState.adaptiveValue[type] !== settings.adaptiveValue[type];
  });
  const editCompress = settings.compressValue !== 80;

  return (
    <main className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-4 mt-4">
            <h4 className="m-0">Медиа</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <div className="card p-3 mb-4">
              <div className="row">
                <h5>Изображения</h5>
                <div className="col-6">
                  <h6>Включить сжатие изображения</h6>
                  <div class="btn-group">
                    <button
                      type="button"
                      class={`btn ${
                        !settings.compress && !editCompress
                          ? "btn-primary"
                          : "bg-light"
                      }`}
                      onClick={() => {
                        if (settings.compress) {
                          settings.compress = false;
                          setSettings({ ...settings });
                        }
                      }}
                    >
                      OFF
                    </button>
                    <button
                      type="button"
                      class={`btn ${
                        settings.compress && !editCompress
                          ? "btn-primary"
                          : "bg-light"
                      }`}
                      onClick={() => {
                        if (
                          !settings.compress ||
                          settings.compressValue !== defaultState.compressValue
                        ) {
                          settings.compressValue = defaultState.compressValue;
                          settings.compress = true;
                          setSettings({ ...settings });
                        }
                      }}
                    >
                      ON
                    </button>
                    <button
                      type="button"
                      class={`btn ${editCompress ? "btn-primary" : "bg-light"}`}
                      data-bs-toggle="collapse"
                      data-bs-target="#optimSettings"
                      aria-expanded="false"
                      aria-controls="optimSettings"
                    >
                      <i className="bi bi-gear" />
                    </button>
                  </div>
                  <p className="text-secondary fs-12">
                    Быстрая загрузка за счет снижения качества
                  </p>
                  <div className="collapse" id="optimSettings">
                    <div class="input-group mb-2">
                      <span class="input-group-text text-secondary">
                        Сжатие
                      </span>
                      <span class="input-group-text text-secondary">%</span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="80%"
                        value={settings.compressValue}
                        max="100"
                        min="50"
                        onChange={(e) => {
                          settings.compress = true;
                          settings.compressValue = +e.target.value;
                          setSettings({ ...settings });
                        }}
                      />
                    </div>
                    <p className="text-secondary fs-12">
                      При низком проценте, изображения будут терять в качестве и
                      загружаться дольше, но изображение будет весить меньше{" "}
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <h6>Включить адаптивную загрузку</h6>
                  <div class="btn-group">
                    <button
                      type="button"
                      class={`btn ${
                        !settings.adaptive && !editAdaptive
                          ? "btn-primary"
                          : "bg-light"
                      }`}
                      onClick={() => {
                        if (settings.adaptive) {
                          settings.adaptive = false;
                          setSettings({ ...settings });
                        }
                      }}
                    >
                      OFF
                    </button>
                    <button
                      type="button"
                      class={`btn ${
                        settings.adaptive && !editAdaptive
                          ? "btn-primary"
                          : "bg-light"
                      }`}
                      onClick={() => {
                        settings.adaptiveValue = defaultState.adaptiveValue;
                        settings.adaptive = true;
                        setSettings({ ...settings });
                      }}
                    >
                      ON
                    </button>
                    <button
                      type="button"
                      class={`btn ${editAdaptive ? "btn-primary" : "bg-light"}`}
                      data-bs-toggle="collapse"
                      data-bs-target="#adaptSettings"
                      aria-expanded="false"
                      aria-controls="adaptSettings"
                    >
                      <i className="bi bi-gear" />
                    </button>
                  </div>
                  <p className="text-secondary fs-12">
                    Будут сгенерированы большое, среднее, маленькое изображение
                  </p>
                  <div className="collapse" id="adaptSettings">
                    <div class="input-group mb-2">
                      <span class="input-group-text text-secondary">
                        Большие
                      </span>
                      <span class="input-group-text text-secondary">px</span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="800 px"
                        value={settings.adaptiveValue.large}
                        onChange={(e) => {
                          settings.adaptive = true;
                          settings.adaptiveValue.large = +e.target.value;
                          setSettings({ ...settings });
                        }}
                      />
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text text-secondary">
                        Средние
                      </span>
                      <span class="input-group-text text-secondary">px</span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="600 px"
                        value={settings.adaptiveValue.medium}
                        onChange={(e) => {
                          settings.adaptive = true;
                          settings.adaptiveValue.medium = +e.target.value;
                          setSettings({ ...settings });
                        }}
                      />
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text text-secondary">
                        Маленькие
                      </span>
                      <span class="input-group-text text-secondary">px</span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="300 px"
                        value={settings.adaptiveValue.small}
                        onChange={(e) => {
                          settings.adaptive = true;
                          settings.adaptiveValue.small = +e.target.value;
                          setSettings({ ...settings });
                        }}
                      />
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text text-secondary">
                        Миниатюра
                      </span>
                      <span class="input-group-text text-secondary">px</span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="150 px"
                        value={settings.adaptiveValue.thumbnail}
                        onChange={(e) => {
                          settings.adaptive = true;
                          settings.adaptiveValue.thumbnail = +e.target.value;
                          setSettings({ ...settings });
                        }}
                      />
                    </div>
                    <p className="text-secondary fs-12">
                      Укажите самостоятельно ширину генерируемых изображений
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-3 mb-4">
              <h6>Загрузка</h6>
              <div className="d-flex align-items-center mb-3">
                <div className="me-3">
                  <input
                    type="checkbox"
                    id="check1"
                    defaultChecked
                    className="me-1 form-check-input"
                  />
                  <label for="check1">count</label>
                </div>
                <div className="me-3">
                  <input
                    type="checkbox"
                    id="check2"
                    defaultChecked
                    className="me-1 form-check-input"
                  />
                  <label for="check2">find</label>
                </div>
                <div className="me-3">
                  <input
                    type="checkbox"
                    id="check3"
                    defaultChecked
                    className="me-1 form-check-input"
                  />
                  <label for="check3">finddone</label>
                </div>
                <div className="me-3">
                  <input
                    type="checkbox"
                    id="check4"
                    defaultChecked
                    className="me-1 form-check-input"
                  />
                  <label for="check4">search</label>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <input
                    type="checkbox"
                    id="check5"
                    defaultChecked
                    className="me-1 form-check-input"
                  />
                  <label for="check5">destroy</label>
                </div>
                <div className="me-3">
                  <input
                    type="checkbox"
                    id="check6"
                    defaultChecked
                    className="me-1 form-check-input"
                  />
                  <label for="check6">upload</label>
                </div>
                <div className="me-3">
                  <input
                    type="checkbox"
                    id="check7"
                    defaultChecked
                    className="me-1 form-check-input"
                  />
                  <label for="check7">updatesettings</label>
                </div>
                <div className="me-3">
                  <input
                    type="checkbox"
                    id="check8"
                    defaultChecked
                    className="me-1 form-check-input"
                  />
                  <label for="check8">getsettings</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className=" card p-3">
              <button
                className="btn btn-primary mb-2"
                onClick={(e) => {
                  api
                    .postSettings({ media: settings })
                    .then((data) => {
                      $(e.target).addClass("bg-success");
                      setTimeout(() => {
                        $(e.target).removeClass("bg-success");
                      }, 2500);
                    })
                    .catch((err) => {
                      $(e.target).addClass("bg-danger");
                      setTimeout(() => {
                        $(e.target).removeClass("bg-danger");
                      }, 2500);
                      console.log(`err`, err);
                    });
                }}
              >
                Сохранить
              </button>
              <button className="btn btn-light mb-2">Отменить и выйти</button>
              <p className="text-secondary m-0 fs-14">
                Правлено: 01.12.2020 12:10 (danil)
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SettingsMedia;
