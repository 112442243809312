import React from "react";
import switchCaseForFields from "./switchCaseForFields";

const ConstrBlocks = ({
  body,
  cols,
  data,
  dispatch,
  path = [],
  namesRefColl,
  setNamesRefColl,
  dataRefColl,
  setDataRefColl,
  funcOpenMedia,
  funcChooseFromMedia,
  isComponent = false,
  defaultsNames,
  api,
}) => {
  let blocks = [];
  let refBlock = [];

  let activeIndex = 0;

  let colIndex = 0;
  let rowLengthComp = cols[colIndex] ? cols[colIndex].length : 1;
  let rowLengthCompCounter = rowLengthComp;
  const childsHTML = [];
  const refChildsHTML = [];
  let childsTimeHTML = [];
  body.forEach((el, i) => {
    if (el.typeField === "ref" && !isComponent)
      refChildsHTML.push(
        switchCaseForFields({
          body,
          data,
          dispatch,
          path,
          namesRefColl,
          setNamesRefColl,
          dataRefColl,
          setDataRefColl,
          activeIndex: i,
          rowLength: rowLengthComp,
          funcOpenMedia,
          funcChooseFromMedia,
          defaultsNames,
        })
      );
    else {
      if (rowLengthCompCounter === 0) {
        colIndex += 1;
        rowLengthComp = cols[colIndex] ? cols[colIndex].length : 1;
        rowLengthCompCounter = rowLengthComp;
        childsHTML.push(<div className="row">{childsTimeHTML}</div>);
        childsTimeHTML = [];
      }
      const emptyField = cols[colIndex]
        ? !cols[colIndex][rowLengthComp - rowLengthCompCounter]
        : false;
      rowLengthCompCounter -= 1;
      if (emptyField)
        childsTimeHTML.push(
          <div className={`col-${12 / rowLengthComp}`}></div>
        );
      else
        childsTimeHTML.push(
          switchCaseForFields({
            body,
            data,
            dispatch,
            path,
            namesRefColl,
            setNamesRefColl,
            dataRefColl,
            setDataRefColl,
            activeIndex: i,
            rowLength: rowLengthComp,
            funcOpenMedia,
            funcChooseFromMedia,
            defaultsNames,
          })
        );
    }
  });

  childsHTML.push(<div className="row">{childsTimeHTML}</div>);

  // if (cols.length) {
  //   cols.forEach((row) => {
  //     const rowLength = row.length;
  //     const rowHTMLBlocks = [];
  //     row.forEach((col) => {
  //       if (body[activeIndex].typeField === "ref" && !isComponent) {
  //         refBlock.push(
  //           switchCaseForFields({
  //             body,
  //             data,
  //             dispatch,
  //             path,
  //             namesRefColl,
  //             setNamesRefColl,
  //             dataRefColl,
  //             setDataRefColl,
  //             activeIndex,
  //             rowLength,
  //             funcOpenMedia,
  //             funcChooseFromMedia,
  //           })
  //         );

  //         activeIndex++;
  //       } else {
  //         if (col) {
  //           rowHTMLBlocks.push(
  //             switchCaseForFields({
  //               body,
  //               data,
  //               dispatch,
  //               path,
  //               namesRefColl,
  //               setNamesRefColl,
  //               dataRefColl,
  //               setDataRefColl,
  //               activeIndex,
  //               rowLength,
  //               funcOpenMedia,
  //               funcChooseFromMedia,
  //             })
  //           );
  //           activeIndex++;
  //         } else {
  //           rowHTMLBlocks.push(<div className={`col-${12 / rowLength}`}></div>);
  //         }
  //       }
  //     });
  //     blocks.push(<div className="row">{rowHTMLBlocks}</div>);
  //   });
  // } else {
  //   body.forEach((el, i) => {
  //     if (el.typeField === "ref")
  //       refBlock.push(
  //         switchCaseForFields({
  //           body,
  //           data,
  //           dispatch,
  //           path,
  //           namesRefColl,
  //           setNamesRefColl,
  //           dataRefColl,
  //           setDataRefColl,
  //           activeIndex: i,
  //           rowLength: 1,
  //           funcOpenMedia,
  //           funcChooseFromMedia,
  //         })
  //       );
  //     else
  //       blocks.push(
  //         <div className="row">
  //           {switchCaseForFields({
  //             body,
  //             data,
  //             dispatch,
  //             path,
  //             namesRefColl,
  //             setNamesRefColl,
  //             dataRefColl,
  //             setDataRefColl,
  //             activeIndex: i,
  //             rowLength: 1,
  //             funcOpenMedia,
  //             funcChooseFromMedia,
  //           })}
  //         </div>
  //       );
  //   });
  // }

  // return [blocks, refBlock];
  return [childsHTML, refChildsHTML];
};

export default ConstrBlocks;
