import React, { useState, useEffect } from "react";

/**
 *
 * @param {React.ProfilerProps&{open:boolean,timeout:number,closePopUp:function():void}} props
 * @returns
 */
const ModalOvelay = (props) => {
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    if (props.open) {
      setOpen(true);
    } else if (props.timeout) {
      setTimeout(() => {
        setOpen(false);
      }, props.timeout);
    } else {
      setOpen(false);
    }
  }, [props.open]);
  return (
    <div
      className={`overlay ${open ? "show" : "fade"}`}
      onClick={(e) => {
        if (
          e.currentTarget === e.target ||
          e.target.classList.contains("modal")
        )
          props.closePopUp();
      }}
    >
      {props.children}
    </div>
  );
};

export default ModalOvelay;
