import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../func/api";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Endpoints from "../common/Endpoints";
import { io } from "socket.io-client";
import ssio from "socket.io-stream";

import { SERVER_URL, SOCKET_URL } from "../../func/constants";
import Pagination from "../common/Pagination";
import SearchElem from "../common/SearchElem";
import {
  fieldsSetting,
  getFiltReq,
  parseFilterToQuery,
} from "../common/funcs/common";
import FilterTable from "../common/FilterTable";
import ModalOvelay from "../common/ModalOverlay";
import SidebarBox from "../common/SidebarBox";
import ExcelSettings from "../common/ExcelSettings";
import ModalDelete from "../common/ModalDelete";
import InForModelContainer from "../common/InForModel";
const socket = io(SOCKET_URL, {
  autoConnect: false,
  reconnection: false,
});

let fields = {};
let selectOps = [];

const typeSorts = ["asc", "desc"];

/**
 *
 * @type {{typeSort: null|string, name: null|string}}
 */
const defaultSortSettings = {
  name: null,
  typeSort: null,
};

const sortToIcon = {
  asc: "bi bi-sort-down-alt",
  desc: "bi bi-sort-up-alt",
};

function Collection({
  nameColl,
  typeModel,
  routProps,
  userData,
  setUserData,
  cms,
  cmsData,
}) {
  const [data, setData] = useState([]);
  const [schema, setSchema] = useState({});
  const [refDatas, setRefDatas] = useState({});
  const [filters, setFilters] = useState([]);
  const [sortSettings, setSortSettings] = useState(defaultSortSettings);
  const [typeAnd, setTypeAnd] = useState(true);
  const [filterOn, setFilterOn] = useState(false);
  const [sortTableOn, setSortTableOn] = useState(false);
  const [excelSettingOn, setExcelSettingOn] = useState(false);
  const [inForModelOn, setInForModelOn] = useState(false);
  const [endPointsOn, setEndPointsOn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [showSortListHTML, setShowSortListHTML] = useState([]);
  const [countDocs, setCountDocs] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    routProps.location.search.includes("page=")
      ? routProps.location.search.split("page=")[1].split(";")[0]
      : 1
  );
  const [limitInPage, setLimitInPage] = useState(40);
  const [dowloadProgress, setDowloadProgress] = useState(0);
  const [statusImportExcel, setStatusImportExcel] = useState({
    status: "download",
  });
  const [errorImportExcel, setErrorImportExcel] = useState(null);
  const [warningImportExcel, setWarningImportExcel] = useState(null);
  const [progressImportExcel, setProgressImportExcel] = useState(null);
  const [statusExportExcel, setStatusExportExcel] = useState({
    status: "start",
  });
  const [importPopupExcel, setImportPopupExcel] = useState(false);
  const [exportPopupExcel, setExportPopupExcel] = useState(false);

  const [deleteText, setDeleteText] = useState("");
  const [deleteFunc, setDeleteFunc] = useState(null);
  const [canEdit, setCanEdit] = useState(false);

  const getSortTableOn = () => {
    setSortTableOn(!sortTableOn);
  };

  const getFilterOn = () => {
    setFilterOn(!filterOn);
  };

  const getEndPointsOn = () => {
    setEndPointsOn(!endPointsOn);
  };

  useEffect(() => {
    if (cmsData && schema && Object.keys(schema).length) {
      if (cmsData._id !== schema.infoCMS) {
        if (
          cmsData.shareCollections.filter(
            (share) => share.collectionID === schema._id
          )[0].filter
        )
          setCanEdit(false);
        else setCanEdit(true);
      } else setCanEdit(true);
    }
  }, [cmsData, schema]);

  const getDataElems = () => {
    api
      .getDatas({
        typeModel,
        model: nameColl,
        pagin: { skip: +limitInPage * (pageNumber - 1), limit: +limitInPage },
      })
      .then((result) => {
        setData(result);
      })
      .catch((err) => {
        setData([]);
        console.error(`err`, err);
      });
  };

  const changeSortSettings = (name) => {
    if (sortSettings.name === name) {
      const index = typeSorts.indexOf(sortSettings.typeSort);
      if (index === typeSorts.length - 1) {
        setSortSettings(defaultSortSettings);
      } else {
        setSortSettings({
          name,
          typeSort: typeSorts[index + 1],
        });
      }
    } else {
      setSortSettings({ name, typeSort: typeSorts[0] });
    }
  };

  const getHeaderCell = (name, nameDB) => {
    const isSort = sortSettings.name === nameDB;
    return (
      <th
        onClick={() => {
          changeSortSettings(nameDB);
        }}
        style={{ cursor: "pointer" }}
      >
        {name}
        {isSort && (
          <i
            className={sortToIcon[sortSettings.typeSort]}
            style={{ marginLeft: "5px" }}
          />
        )}
      </th>
    );
  };

  useEffect(() => {
    if (filters.length || sortSettings?.name)
      getFiltReq({
        filters,
        sortSettings,
        typeAnd,
        typeModel,
        nameColl,
        setData,
        pageNumber,
        limitInPage,
        api,
      });
    else getDataElems();
  }, [pageNumber, limitInPage, sortSettings]);

  const getData = () => {
    fields = {};
    selectOps = [];
    getDataElems();

    api
      .getSchema(nameColl)
      .then((result) => {
        result.body.forEach((el) => {
          if (el.typeField !== "json")
            selectOps.push({
              label: el.name,
              value: el.nameDB,
              type: fieldsSetting[el.typeField] ? el.typeField : "overVar",
              selectVars: el.selectVars,
            });

          fields[el.name] = el.typeField;
        });
        setSchema({ ...result });
      })
      .catch((err) => console.error(`err`, err));
  };

  useEffect(() => {
    if (
      userData?.settings &&
      userData?.settings[cms] &&
      userData?.settings[cms][nameColl]?.sortList &&
      schema.body &&
      schema.body.filter(
        (el) =>
          el.typeField !== "text" &&
          el.typeField !== "component" &&
          el.typeField !== "ref"
      ).length === userData?.settings[cms][nameColl]?.sortList.length
    ) {
      setShowSortListHTML(userData?.settings[cms][nameColl]?.sortList);
    } else if (schema.body) {
      const timeArrayForSchemaFields = [];

      schema.body.forEach(({ name, nameDB, typeField }) => {
        if (
          typeField !== "text" &&
          typeField !== "component" &&
          typeField !== "ref"
        )
          timeArrayForSchemaFields.push({
            name,
            nameDB,
            typeField,
            active: true,
          });
      });

      setShowSortListHTML([...timeArrayForSchemaFields]);
    }
  }, [userData, schema.body, cms, nameColl]);

  useEffect(() => {
    setData([]);
    setSchema({});
    setRefDatas({});
    setShowSortListHTML([]);
    getData();
  }, [nameColl]);

  useEffect(() => {
    socket.on("status", (data) => {
      setStatusImportExcel(data);

      if (data?.process) {
        setProgressImportExcel(data.process);
      } else {
        setProgressImportExcel(null);
      }

      switch (data?.status) {
        case "error":
          setErrorImportExcel(data);
          break;
        case "warning":
          setWarningImportExcel(data);
          break;
      }
    });
  }, []);

  const allElemsHTML = [];

  const defaultName = schema.defaultField;

  data.forEach((el, i) => {
    let firstLine = true;
    allElemsHTML.push(
      <tr>
        <td>
          <input type="checkbox" className="form-check-input" />
        </td>
        {defaultName ? (
          <td
            style={{
              whiteSpace: "nowrap",
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            onClick={() => {
              changeSortSettings(defaultName);
            }}
          >
            <Link
              to={`/front/${cms}/collections/${nameColl}/${el._id}`}
              className="tr-title"
            >
              {el[defaultName] ? String(el[defaultName]) : ""}
            </Link>
          </td>
        ) : null}

        {showSortListHTML.map((elem) => {
          if (defaultName && elem.nameDB === defaultName) {
            return null;
          }
          let nodeElem = el[elem.nameDB] ? String(el[elem.nameDB]) : "";
          if (!defaultName && firstLine) {
            firstLine = false;
            nodeElem = (
              <Link
                to={`/front/${cms}/collections/${nameColl}/${el._id}`}
                className="tr-title"
              >
                {nodeElem}
              </Link>
            );
          }
          if (elem.active && elem.typeField !== "ref") {
            return (
              <td
                style={{
                  whiteSpace: "nowrap",
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {nodeElem}
              </td>
            );
          }
          return null;
        })}
        <td
          className="position-absolute text-end"
          style={{
            width: "150px",
            right: "1px",
            top: "auto",
            opacity: 1,
            backgroundColor: "#fff",
          }}
        >
          {/* <i className="bi bi-eye me-3" />
          <i className="bi bi-files-alt me-3" /> */}
          <i
            className="bi bi-trash me-3"
            onClick={() => {
              setDeleteText(
                `Запись «${
                  defaultName
                    ? el[defaultName]
                      ? String(el[defaultName])
                      : el._id
                    : el._id
                }» будет удалена безвозвратно из коллекции.`
              );
              setDeleteFunc(() => {
                return () => {
                  api
                    .deleteDatas({ typeModel, model: nameColl, data: el })
                    .then((result) => {
                      getData();
                      setDeleteText("");
                      setDeleteFunc(null);
                    })
                    .catch((err) => {
                      console.error(`err`, err);
                    });
                };
              });
            }}
          />
        </td>
      </tr>
    );
  });

  return (
    <main>
      {endPointsOn ? (
        <div className="collection-endpoints">
          <Endpoints path={`/data/${typeModel}/${nameColl}`} />
        </div>
      ) : null}

      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="d-flex justify-content-between align-items-center mb-4 mt-4">
              <div>
                <h5 className="">{schema.name} </h5>
                <p
                  className="text-muted"
                  style={{
                    fontSize: "80%",
                  }}
                >
                  Всего документов: {countDocs}
                </p>
              </div>
              <div>
                <button
                  className="btn btn-outline-primary me-2"
                  onClick={getEndPointsOn}
                >
                  Endpoints
                </button>
                {canEdit && (
                  <button
                    className="btn btn-outline-primary me-2"
                    onClick={() => {
                      setInForModelOn(!inForModelOn);
                    }}
                  >
                    Заполнить
                  </button>
                )}
                {canEdit && (
                  <div className="btn-group  me-2" role="group">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => {
                        socket.connect();
                        socket.once("connect", (socketOn) => {
                          let filtPost = parseFilterToQuery({
                            filters,
                            typeAnd,
                          });

                          let sort = undefined;
                          if (sortSettings?.name) {
                            sort = {
                              [sortSettings.name]: sortSettings.typeSort,
                            };
                          }

                          socket.emit("importExcel", {
                            nameColl,
                            filtrs: filtPost,
                            sort,
                            cms,
                          });
                          setExportPopupExcel(true);
                          socket.on("status", (data) => {
                            setStatusExportExcel(data);
                          });
                          socket.on("importExcel", function (data) {
                            const aLink = document.createElement("a");
                            aLink.href = SERVER_URL + data.path;
                            aLink.target = "_blank";
                            aLink.download = "export.xlsx";
                            aLink.click();

                            setStatusExportExcel({ status: "finish" });
                          });
                        });
                      }}
                    >
                      Экспорт
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={(e) => {
                        document.getElementById("importFile").click();
                      }}
                    >
                      Импорт
                      <input
                        type="file"
                        id="importFile"
                        name="uploadImg"
                        accept="application/vnd.ms-exce,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsb,.csv,.json"
                        onInput={(e) => {
                          if (e.target.files.length) {
                            socket.connect();
                            socket.once("connect", () => {
                              setImportPopupExcel(true);
                              var stream = ssio.createStream();
                              var file = e.target.files[0];
                              // socket.send({ file: reader.result });
                              if (socket.connected) {
                                const blobStream =
                                  ssio.createBlobReadStream(file);
                                ssio(socket).emit("exportExcel", stream, {
                                  size: file.size,
                                  nameColl,
                                  name: e.target.files[0].name,
                                  cms,
                                });
                                let size = 0;
                                blobStream.on("data", (chunk) => {
                                  size += chunk.length;

                                  setDowloadProgress(
                                    Math.floor((size / file.size) * 100)
                                  );
                                });
                                blobStream.pipe(stream);
                              }
                            });
                          }
                        }}
                      />
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => {
                        setExcelSettingOn(!excelSettingOn);
                      }}
                    >
                      <i className="bi bi-gear fs-6 pointer" />
                    </button>
                  </div>
                )}

                {canEdit && (
                  <Link
                    className="btn btn-primary"
                    to={`/front/${cms}/collections/${nameColl}/new`}
                  >
                    <i className="bi bi-plus" />
                    Новый {schema.name}
                  </Link>
                )}
              </div>
            </div>
            {/* <Filters /> */}
            <div className="d-flex justify-content-between mb-3">
              <div className=""></div>
              <div className="d-flex align-items-center filters">
                <button
                  onClick={getSortTableOn}
                  className="btn btn-outline-dark flex-shrink-0"
                >
                  <i className="bi bi-gear fs-6 me-2" />
                  Поля
                </button>

                <button
                  className={`btn ${
                    filters.length ? "btn-filters" : "btn-outline-dark"
                  } ms-2 flex-shrink-0`}
                  onClick={getFilterOn}
                >
                  <i className="bi bi-filter me-2" />
                  Фильтры {filters.length ? `(${filters.length})` : ""}
                </button>
                <SearchElem
                  className=" ps-2"
                  onClick={(searchString) => {
                    getFiltReq({
                      filters,
                      sortSettings,
                      typeAnd,
                      typeModel,
                      nameColl,
                      setData,
                      pageNumber,
                      limitInPage,
                      search: searchString,
                      api,
                    });
                  }}
                />
              </div>
            </div>
            <div className="position-relative">
              <div
                className="mb-3 rounded border "
                style={{ overflowX: "auto" }}
              >
                <table
                  className="table-striped mb-0 table"
                  style={{ marginRight: "150px" }}
                >
                  <thead className="th-title" style={{ paddingRight: "150px" }}>
                    <tr>
                      <th className="table-checkbox">
                        <input type="checkbox" className="form-check-input" />
                      </th>
                      {defaultName && showSortListHTML.length
                        ? getHeaderCell(
                            showSortListHTML.find(
                              (e) => e.nameDB === defaultName
                            )?.name,
                            defaultName
                          )
                        : null}
                      {showSortListHTML.map((el, i) => {
                        if (defaultName && el.nameDB === defaultName)
                          return null;
                        if (el.active) return getHeaderCell(el.name, el.nameDB);
                        return null;
                      })}
                      {/* <th className="th-naming">Название</th>
                    <th>Редактировано</th> */}
                      <th
                        className="position-absolute pe-4 text-end"
                        style={{
                          width: "150px",
                          right: "1px",
                          top: "1px",
                          backgroundColor: "#fff",
                        }}
                      >
                        Управление
                      </th>
                    </tr>
                  </thead>
                  <tbody>{allElemsHTML}</tbody>
                </table>
              </div>
            </div>

            <Pagination
              page={pageNumber}
              setPage={setPageNumber}
              setCount={setCountDocs}
              typeModel={typeModel}
              model={nameColl}
              route={routProps}
              limitInPage={limitInPage}
              setLimitInPage={setLimitInPage}
              api={api}
            />

            <ModalOvelay
              key={"sortSettings"}
              open={sortTableOn}
              timeout={300}
              closePopUp={(e) => {
                if (e) {
                  e.stopPropagation();
                  e.preventDefault();
                }
                setSortTableOn(false);
              }}
            >
              <SidebarBox open={sortTableOn}>
                <SortTable
                  showSortListHTML={showSortListHTML}
                  setShowSortListHTML={setShowSortListHTML}
                  userData={userData}
                  setUserData={setUserData}
                  nameColl={nameColl}
                  cms={cms}
                />
              </SidebarBox>
            </ModalOvelay>

            <ModalOvelay
              key={"excelSettings"}
              open={excelSettingOn}
              timeout={300}
              closePopUp={(e) => {
                if (e) {
                  e.stopPropagation();
                  e.preventDefault();
                }
                setExcelSettingOn(false);
              }}
            >
              <SidebarBox open={excelSettingOn}>
                <ExcelSettings
                  nameColl={nameColl}
                  selectOps={selectOps}
                  fields={fields}
                />
              </SidebarBox>
            </ModalOvelay>

            <ModalOvelay
              key={"InForModel"}
              open={inForModelOn}
              timeout={300}
              closePopUp={(e) => {
                if (e) {
                  e.stopPropagation();
                  e.preventDefault();
                }
                setInForModelOn(false);
              }}
            >
              <SidebarBox open={inForModelOn} className="w-700">
                <InForModelContainer
                  typeModel={typeModel}
                  nameColl={nameColl}
                  schemaNameDB={schema.nameDB}
                  fields={fields}
                  selectOps={selectOps}
                  lockName={schema.lockName}
                />
              </SidebarBox>
            </ModalOvelay>

            <ModalOvelay
              key={"filterSettings"}
              open={filterOn}
              timeout={300}
              closePopUp={(e) => {
                if (e) {
                  e.stopPropagation();
                  e.preventDefault();
                }
                setFilterOn(false);
              }}
            >
              <SidebarBox open={filterOn}>
                <FilterTable
                  schema={schema}
                  refDatas={refDatas}
                  nameColl={nameColl}
                  setData={setData}
                  filters={filters}
                  setFilters={setFilters}
                  sortSettings={sortSettings}
                  typeAnd={typeAnd}
                  setTypeAnd={setTypeAnd}
                  getData={getData}
                  typeModel={typeModel}
                  search={searchInput}
                  pageNumber={pageNumber}
                  limitInPage={limitInPage}
                  selectOps={selectOps}
                  fields={fields}
                  userData={userData}
                  setUserData={setUserData}
                  setFilterOn={setFilterOn}
                  cms={cms}
                />
              </SidebarBox>
            </ModalOvelay>

            {importPopupExcel && (
              <ExcelProgress
                dowloadProgress={dowloadProgress}
                statusImportExcel={statusImportExcel}
                setImportPopupExcel={setImportPopupExcel}
                error={errorImportExcel}
                warning={warningImportExcel}
                progress={progressImportExcel}
              />
            )}
            {exportPopupExcel && (
              <ExcelProgressExport
                statusExportExcel={statusExportExcel}
                setExportPopupExcel={setExportPopupExcel}
              />
            )}
            <ModalDelete
              title={"Удалить запись?"}
              open={deleteText}
              text={deleteText}
              closePopUp={() => {
                setDeleteText("");
                setDeleteFunc(null);
              }}
              deleteFunc={deleteFunc}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

function SortTable({
  showSortListHTML,
  setShowSortListHTML,
  userData,
  setUserData,
  nameColl,
  cms,
}) {
  const saveSortTableDB = (showSortListHTML) => {
    if (!userData.settings) {
      userData.settings = {};
    }
    if (!userData.settings[cms]) {
      userData.settings[cms] = {};
    }
    if (userData.settings[cms][nameColl]) {
      userData.settings[cms][nameColl].sortList = [...showSortListHTML];
    } else {
      userData.settings[cms][nameColl] = {
        sortList: [...showSortListHTML],
      };
    }
    api
      .postProfile(userData)
      .then((result) => {
        delete result.role;
        setUserData({ ...userData, ...result });
      })
      .catch((err) => {
        console.error(`err`, err);
      });
  };

  const sortListHTML = [];

  showSortListHTML.forEach((colName, i) => {
    sortListHTML.push(
      <Draggable draggableId={colName.nameDB} index={i} key={colName.nameDB}>
        {(provided) => (
          <div
            className="d-flex align-items-center w-100"
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <div>
              <input
                type="checkbox"
                className="collInput pointer form-check-input me-2"
                checked={colName.active}
                id={i}
                onChange={(e) => {
                  e.target.checked
                    ? (colName.active = true)
                    : (colName.active = false);
                  setShowSortListHTML([...showSortListHTML]);
                  saveSortTableDB([...showSortListHTML]);
                }}
              />
            </div>
            <div>{colName.nameDB}</div>
            <div className="ms-auto">
              <i className="bi bi-list" {...provided.dragHandleProps} />
            </div>
          </div>
        )}
      </Draggable>
    );
  });

  return (
    <>
      <div className="d-flex bg-light align-items-center mb-4 p-3">
        <h6 className="m-0">Отображаемые поля</h6>
        <div className="ms-auto">
          <button
            className="btn btn-outline-primary me-2"
            onClick={() => {
              showSortListHTML.forEach((el, i) => {
                showSortListHTML[i].active = true;
              });
              setShowSortListHTML([...showSortListHTML]);
              saveSortTableDB([...showSortListHTML]);
            }}
          >
            Выбрать все
          </button>
          <button
            className="btn btn-outline-primary me-2"
            onClick={() => {
              showSortListHTML.forEach((el, i) => {
                showSortListHTML[i].active = false;
              });
              setShowSortListHTML([...showSortListHTML]);
              saveSortTableDB([...showSortListHTML]);
            }}
          >
            Снять выбор
          </button>
        </div>
      </div>
      <DragDropContext
        onDragEnd={(result) => {
          if (result.source && result.destination) {
            const movedEl = showSortListHTML.splice(result.source.index, 1);
            showSortListHTML.splice(result.destination.index, 0, movedEl[0]);
            setShowSortListHTML([...showSortListHTML]);

            saveSortTableDB([...showSortListHTML]);
          }
        }}
      >
        <div className="p-3">
          <Droppable droppableId={"1"}>
            {(provided) => (
              <div
                className="mb-0 table border-0"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {sortListHTML}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </>
  );
}

/**
 * Renders a modal component to display the progress of an Excel import.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.dowloadProgress - The progress of the file download.
 * @param {Object} props.statusImportExcel - The status of the Excel import.
 * @param {Function} props.setImportPopupExcel - Function to set the import popup visibility.
 * @param {Object} props.error - The error object, if any.
 * @param {Object} props.warning - The warning object, if any.
 * @returns {JSX.Element} The rendered ExcelProgress component.
 */
const ExcelProgress = ({
  dowloadProgress,
  statusImportExcel,
  setImportPopupExcel,
  error,
  warning,
  progress,
}) => {
  const { length, index, percent } = progress || {};
  const status = statusImportExcel?.status;
  return (
    <div className="modal fade show" tabindex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Прогресс</h5>
            {/* <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button> */}
          </div>
          <div className="modal-body">
            <div
              style={{
                overflow: "auto",
              }}
            >
              {status === "download" && (
                <p>
                  Загрузка файла {dowloadProgress}% <br />{" "}
                </p>
              )}
              {status === "wait" && <p>Обновление в очереди, подождите</p>}
              {(status === "start" || status === "start2") && (
                <p>Происходит обновление данных... Ожидайте</p>
              )}
              {status === "finish" && (
                <p>Происходит обновление связанных коллекций... Ожидайте</p>
              )}
              {status !== "close" && status !== "finish" && (
                <b>Не закрывайте данное окно и не перезагружайте страницу!</b>
              )}
              {status === "close" && <p>Импорт завершен</p>}

              {error && (
                <p style={{ color: "red" }}>
                  Произошла ошибка [{error?.errorText}], попробуйте еще раз
                </p>
              )}
              {warning && <p style={{ color: "orange" }}>{warning?.text}</p>}
              {length || index ? (
                <p style={{ marginTop: "15px" }}>
                  Документов обработано <b>{index?.toLocaleString()}</b> из{" "}
                  <b>{length?.toLocaleString()}</b> [{percent}%] <br />
                </p>
              ) : null}
            </div>
          </div>
          {(status === "close" || status === "finish") && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setImportPopupExcel(false);
                }}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ExcelProgressExport = ({ statusExportExcel, setExportPopupExcel }) => {
  return (
    <div className="modal fade show" tabindex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Прогресс</h5>
            {/* <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button> */}
          </div>
          <div className="modal-body">
            {statusExportExcel.status === "start" && (
              <p>
                Отправка данных <br />{" "}
              </p>
            )}
            {statusExportExcel.status === "finish" && (
              <p>
                Экспорт завершен <br />{" "}
              </p>
            )}
            {statusExportExcel.status === "exportInfo" &&
              !statusExportExcel.startCreateExcel && (
                <p>
                  Документов обработано {statusExportExcel.index} (кратно 100){" "}
                  <br />{" "}
                </p>
              )}
            {statusExportExcel.status === "exportInfo" &&
              statusExportExcel.startCreateExcel && (
                <p>
                  Подготовка файла <br />{" "}
                </p>
              )}
          </div>
          {
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setExportPopupExcel(false);
                }}
              >
                Close
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Collection;
