const SERVER_URL =
  process.env.REACT_APP_TYPE === "dev"
    ? "http://api.cms.test.motorfirst.ru"
    : process.env.REACT_APP_TYPE === "local"
    ? "http://127.0.0.1:3260"
    : "https://api.cms.motorfirst.ru";

const SOCKET_URL =
  process.env.REACT_APP_TYPE === "dev"
    ? "http://socket.cms.test.motorfirst.ru"
    : process.env.REACT_APP_TYPE === "local"
    ? "http://127.0.0.1:3265"
    : "https://socket.cms.motorfirst.ru";

console.log(`SERVER_URL`, SERVER_URL);
const SITE_URL = "http://apollo.dev.guys.agency";

//http://127.0.0.1:3060
//http://api.cms.dev.guys.agency

const FIELDS_TYPE_FOR_SEARCH = ["input", "email", "select", "arr", "automat"];

const settingsFields = {
  input: {
    badge: (
      <span class="badge bg-warning me-3">
        <i class="bi bi-type" />
      </span>
    ),
    name: "Text",
    desc: "Маленький или длинный текст, например заголовок",
    def: {
      name: "",
      nameDB: "",
      typeField: "input",
      settingField: "input",
      defaultValue: "",
      regexpField: "",
      placeholderField: "",
      requiredField: false,
      uniqueField: false,
      indexField: false,
      closeField: false,
      maxLengthField: 0,
      minLengthField: 0,
      textIndexWeight: 1,
    },
  },
  email: {
    badge: (
      <span class="badge me-3" style={{ backgroundColor: "#EC009C" }}>
        <i class="bi bi-at" />
      </span>
    ),
    name: "Email",
    desc: "Поле электронной почты с проверкой формата",
    def: {
      name: "",
      nameDB: "",
      typeField: "email",
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      indexField: false,
      closeField: false,
      maxLengthField: 0,
      minLengthField: 0,
      textIndexWeight: 1,
    },
  },
  text: {
    badge: (
      <span class="badge me-3" style={{ backgroundColor: "#D96800" }}>
        <i class="bi bi-card-text" />
      </span>
    ),
    name: "Rich text",
    desc: "Элемент управления для редактирования текста",
    def: {
      name: "",
      nameDB: "",
      typeField: "text",
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      indexField: false,
      closeField: false,
      maxLengthField: 0,
      minLengthField: 0,
    },
  },
  password: {
    badge: (
      <span class="badge me-3" style={{ backgroundColor: "#EB0000" }}>
        <i class="bi bi-asterisk" />
      </span>
    ),
    name: "Password",
    desc: "Поле пароля с шифрованием",
    def: {
      name: "",
      nameDB: "",
      typeField: "password",
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      indexField: false,
      closeField: false,
      maxLengthField: 0,
      minLengthField: 0,
    },
  },
  number: {
    badge: (
      <span class="badge me-3" style={{ backgroundColor: "#1354D2" }}>
        <i class="bi bi-sort-numeric-down" />
      </span>
    ),
    name: "Number",
    desc: "Числа (integer, float, decimal)",
    def: {
      name: "",
      nameDB: "",
      typeField: "number",
      defaultValue: "",
      placeholderField: "",
      requiredField: false,
      uniqueField: false,
      indexField: false,
      closeField: false,
      maxLengthField: 0,
      minLengthField: 0,
    },
  },
  select: {
    badge: (
      <span class="badge me-3" style={{ backgroundColor: "#4A0094" }}>
        <i class="bi bi-list" />
      </span>
    ),
    name: "Select",
    desc: "Перечень значений, выбирается одно",
    def: {
      name: "",
      nameDB: "",
      typeField: "select",
      selectVars: [],
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
      isMulti: false,
      textIndexWeight: 1,
    },
  },
  date: {
    badge: (
      <span class="badge me-3" style={{ backgroundColor: "#17AB49" }}>
        <i class="bi bi-calendar-date" />
      </span>
    ),
    name: "Date",
    desc: "Элемент управления датой и временем",
    def: {
      name: "",
      nameDB: "",
      typeField: "date",
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
    },
  },
  image: {
    badge: (
      <span class="badge me-3" style={{ backgroundColor: "#88DF19" }}>
        <i class="bi bi-image" />
      </span>
    ),
    name: "Media",
    desc: "Аудио- видео- и прочие медиафайлы",
    def: {
      name: "",
      nameDB: "",
      typeField: "image",
      defaultValue: "",
      requiredField: false,
      closeField: false,
      isMulti: false,
    },
  },
  boolean: {
    badge: (
      <span class="badge me-3" style={{ backgroundColor: "#00C9D6" }}>
        <i class="bi bi-toggle-on" />
      </span>
    ),
    name: "Boolean",
    desc: "Да или нет, 1 или 0, Истина или Ложь",
    def: {
      name: "",
      nameDB: "",
      typeField: "boolean",
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
    },
  },
  jsonType: {
    badge: (
      <span class="badge me-3" style={{ backgroundColor: "#868E96" }}>
        <i class="bi bi-slash-square" />
      </span>
    ),
    name: "JSON",
    desc: "Данные в формате JSON",
    def: {
      name: "",
      nameDB: "",
      typeField: "jsonType",
      defaultValue: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
    },
  },
  ref: {
    badge: (
      <span class="badge me-3" style={{ backgroundColor: "#9911D9" }}>
        <i class="bi bi-share" />
      </span>
    ),
    name: "Relation",
    desc: "Ссылка на какой-либо тип контента",
    def: {
      name: "",
      nameDB: "",
      typeField: "ref",
      refTo: "",
      requiredField: false,
      uniqueField: false,
      closeField: false,
    },
  },
  component: {
    badge: (
      <span class="badge me-3" style={{ backgroundColor: "#2E3338" }}>
        <i class="bi bi-layers" />
      </span>
    ),
    name: "Component",
    desc: "Группа полей",
    def: {
      name: "",
      nameDB: "",
      typeField: "component",
      requiredField: false,
      isMulti: false,
    },
  },
  arr: {
    badge: (
      <span class="badge me-3" style={{ backgroundColor: "#FF776E" }}>
        <i class="bi bi-collection" />
      </span>
    ),
    name: "Array",
    desc: "Массив данных",
    def: {
      name: "",
      nameDB: "",
      typeField: "arr",
      separator: "",
      uniqueField: false,
      closeField: false,
      maxLengthField: 0,
      minLengthField: 0,
      textIndexWeight: 1,
    },
  },
  automat: {
    badge: (
      <span class="badge me-3" style={{ backgroundColor: "#B50062" }}>
        <i class="bi bi-circle" />
      </span>
    ),
    name: "Auto generate",
    desc: "Автоматически генерируемые поля",
    def: {
      name: "",
      nameDB: "",
      typeField: "automat",
      settingField: "",
      // uniqueField: false,
      closeField: false,
      textIndexWeight: 1,
      // maxLengthField: 0,
      // minLengthField: 0,
    },
  },
};

const CONSTANT_MENU = [
  {
    name: "Медиа",
    link: "/media",
    type: "media",
    icon: "circle",
    showIcon: true,
  },
  {
    folder: true,
    name: "Настройки",
    icon: "folder2",
    showIcon: true,
    elems: [
      {
        name: "Меню",
        link: "/settings/menu",
        type: "constructorCMSNav",
        icon: "circle",
        showIcon: true,
      },
      {
        name: "Медиа",
        link: "/settings/media",
        type: "siteSettings",
        icon: "circle",
        showIcon: true,
      },
      {
        name: "REST запросы",
        link: "/settings/rest",
        type: "restSettings",
        icon: "circle",
        showIcon: true,
      },
    ],
  },
  {
    folder: true,
    name: "Пользователи",
    icon: "folder2",
    showIcon: true,
    elems: [
      {
        name: "Список",
        link: "/settings/user",
        type: "users",
        icon: "circle",
        showIcon: true,
      },
      {
        name: "Роли",
        link: "/settings/role",
        type: "roles",
        icon: "circle",
        showIcon: true,
      },
    ],
  },

  {
    name: "Профиль",
    link: "/profile",
    icon: "circle",
    showIcon: true,
  },
];

export {
  SERVER_URL,
  SOCKET_URL,
  SITE_URL,
  settingsFields,
  CONSTANT_MENU,
  FIELDS_TYPE_FOR_SEARCH,
};
