// import { observer } from "mobx-react";
import React, { useState, useEffect, useReducer } from "react";
import $ from "jquery";
import { SERVER_URL } from "../../func/constants";
import { copyToBuffer } from "../../func/common";
import api from "../../func/api";
import moment from "moment";
import constrBlocks from "./funcs/constrBlocks";
import Select from "react-select";
import EmptyMedia from "../common/EmptyMedia";
import UploadMediaModal from "../common/UploadMediaModal";
import Endpoints from "../common/Endpoints";
import PopupChooseMedia from "../common/PopupChooseMedia";

const changeState = (state, action) => {
  return { ...action.data };
};

const chooseTypePage = {
  pages: "getSinglePage",
  collections: "getSchema",
};

const EditConstr = ({ typeModel, nameColl, id, userData, router, cms }) => {
  const [data, dispatch] = useReducer(changeState, {});
  const [schema, setSchema] = useState({});
  const [activeFields, setActiveFields] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [activePic, setActivePic] = useState(null);
  const [updateMedia, setUpdateMedia] = useState(null);
  const [chooseFromMedia, setChooseFromMedia] = useState(false);

  const [namesRefColl, setNamesRefColl] = useState([]);
  const [dataRefColl, setDataRefColl] = useState({});
  const [defaultsNames, setDefaultsNames] = useState({});
  const isNew = id === "new";
  const [showURL, setShowURL] = useState(false);

  const changeUrl = () => {
    setShowURL(!showURL);
  };

  const funcOpenMedia = (activePic, funcChange) => {
    setOpenMediaModal(true);
    setActivePic(activePic);
    setUpdateMedia(() => funcChange);
  };
  const funcCloseMedia = () => {
    setOpenMediaModal(false);
    setActivePic(null);
    setUpdateMedia(null);
  };

  const funcChooseFromMedia = (funcChange) => {
    setChooseFromMedia(true);
    setUpdateMedia(() => funcChange);
  };
  const funcCloseChooseFromMedia = () => {
    setChooseFromMedia(false);
    setUpdateMedia(null);
  };
  console.log(`updateMedia`, updateMedia);

  const isCollection = true;

  const getDatasCollForRef = () => {
    //! Переделать в промис алл
    namesRefColl.forEach((nameCollForFetch) => {
      if (!defaultsNames[nameCollForFetch])
        api
          .getSchema(nameCollForFetch)
          .then((result) => {
            console.log(`result`, result);
            defaultsNames[nameCollForFetch] = result.defaultField;
            setDefaultsNames({
              ...defaultsNames,
            });
          })
          .catch((err) => {
            console.log(`err`, err);
          });
    });
  };

  useEffect(() => {
    getDatasCollForRef();
  }, [namesRefColl]);

  useEffect(() => {
    setDataReady(false);

    const start = new Date();
    api[chooseTypePage[typeModel]](nameColl)
      .then((result) => {
        if (isNew) {
          const newData = {};
          result.body.forEach((el) => {
            if (el.typeField === "component") {
              if (el.isMulti) newData[el.nameDB] = [];
              else newData[el.nameDB] = {};
            } else if (el.typeField === "image" && el.isMulti) {
              newData[el.nameDB] = [];
            }
          });
          console.log(`1`, 1);
          dispatch({ data: newData });
          setSchema(result);
          setDataReady(true);
        } else {
          console.log(`2`, 2);
          api
            .getData({ typeModel, model: nameColl, id })
            .then((datas) => {
              console.log(`datas`, datas);
              if (datas.length) {
                dispatch({ data: datas[0] });
                setSchema(result);
                setDataReady(true);
              } else {
                const newData = {};
                result.body.forEach((el) => {
                  if (el.typeField === "component") {
                    if (el.isMulti) newData[el.nameDB] = [];
                    else newData[el.nameDB] = {};
                  } else if (el.typeField === "image" && el.isMulti) {
                    newData[el.nameDB] = [];
                  } else {
                    newData[el.nameDB] = "";
                  }
                });

                dispatch({ data: newData });
                setSchema(result);
                setDataReady(true);
              }
            })
            .catch((err) => {
              console.log(`err`, err);
            });
        }
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  }, [nameColl, id]);

  let blocksHTML = [];
  let refBlockHtml = [];
  useEffect(() => {
    if (schema.body) {
      setActiveFields(
        schema.body.reduce((activeArr, el) => {
          if (el.visible) {
            activeArr.push(el);
            return activeArr;
          }
          return activeArr;
        }, [])
      );
    }
  }, [schema]);

  console.log(`activeFields`, activeFields);

  if (activeFields.length && dataReady && schema.cols) {
    const constrBlocksDataHtml = constrBlocks({
      body: activeFields,
      cols: schema.cols,
      data,
      dispatch,
      namesRefColl,
      setNamesRefColl,
      dataRefColl,
      setDataRefColl,
      funcOpenMedia,
      funcChooseFromMedia,
      defaultsNames,
    });
    blocksHTML = constrBlocksDataHtml[0]; //return constrBlock массив на первом место block
    refBlockHtml = constrBlocksDataHtml[1];
  }

  return (
    <main className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12"></div>
        </div>
        <div className="d-flex mb-3">
          <h4 className="me-4">{data.name}</h4>
        </div>
        <div className="row position-relative">
          <div className="col-8">
            <div className="card mb-4">
              {/* <ManyMedia
                isCollection={isCollection}
                changeUrl={changeUrl}
                showURL={showURL}
              /> */}
              <div className="card-body">{blocksHTML}</div>
            </div>
          </div>
          <div className="col-4">
            <div className="card p-3 mb-4">
              <button
                className="btn btn-primary mb-2"
                onClick={(e) => {
                  const updateOrCreate = data._id ? "putDatas" : "postDatas";

                  api[updateOrCreate](typeModel, nameColl, data)
                    .then((data) => {
                      if (isNew && typeModel !== "pages") {
                        window.location.pathname =
                          "front/" +
                          cms +
                          "/collections/" +
                          nameColl +
                          "/" +
                          data._id;
                      } else {
                        $(e.target).addClass("bg-success");
                        setTimeout(() => {
                          $(e.target).removeClass("bg-success");
                        }, 2500);
                        dispatch({ data });
                      }
                    })
                    .catch((err) => {
                      $(e.target).addClass("bg-danger");
                      setTimeout(() => {
                        $(e.target).removeClass("bg-danger");
                      }, 2500);
                      console.log(`err`, err);
                    });
                }}
              >
                Сохранить
              </button>
              {typeModel !== "pages" && (
                <button
                  className="btn btn-light mb-2"
                  onClick={() => {
                    router.history.goBack();
                  }}
                >
                  Отменить и выйти
                </button>
              )}
              {typeModel !== "pages" && (
                <button className="btn btn-outline-danger mb-2">
                  Удалить запись
                </button>
              )}
              {data?._id ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <p className="text-secondary m-0 fs-12">
                    {data._id}
                    <i
                      className="bi bi-files ms-2 pointer"
                      onClick={(event) => {
                        try {
                          copyToBuffer(data._id);
                          event.currentTarget.classList.add("text-success");
                          setTimeout(() => {
                            event.target.classList.remove("text-success");
                          }, 1000);
                        } catch (error) {
                          console.log(`error`, error);
                        }
                      }}
                    />
                  </p>
                </div>
              ) : null}

              {/* <p className="text-secondary m-0 fs-14">
                Обновлено: 01.12.2020 12:10 (Admin)
              </p> */}
              {/* <p className="text-success m-0 fs-14">Опубликовано</p> */}
            </div>
            {refBlockHtml.length > 0 && (
              <div className="card p-3 pt-2 mb-4">
                {/* <div className="d-flex mb-1">
                <p className="mb-0 fw-500">Product</p>
                <p className="text-primary mb-0 ms-auto">К коллекции</p>
              </div> */}
                {refBlockHtml}
              </div>
            )}
            <div className="card p-3 mb-4">
              <button className="btn btn-outline-dark text-start mb-2">
                <i className="bi bi-border-style me-3" />
                Настроить порядок представления
              </button>
              <button className="btn btn-outline-dark text-start">
                <i className="bi bi-gear me-3" />
                Редактировать поля
              </button>
            </div>

            {id !== "new" && userData?.role?.endpoints && (
              <Endpoints
                path={`/data/${cms}/${typeModel}/${nameColl}`}
                id={id}
              />
            )}
          </div>
        </div>
      </div>
      {openMediaModal && (
        <UploadMediaModal
          activePic={activePic}
          setActivePic={setActivePic}
          open={openMediaModal}
          close={funcCloseMedia}
          getData={updateMedia}
          editMediaDefault={true}
        />
      )}
      {/* {chooseFromMedia && (
        <PopupChooseMedia
          active={chooseFromMedia}
          change={updateMedia}
          close={funcCloseChooseFromMedia}
        />
      )} */}
    </main>
  );
};

export default EditConstr;
