const settingsTypes = {
  edit: ["edit", "read"],
  read: ["read"],
  create: ["edit", "read", "create", "delete"],
  ban: [],
  settings: ["settings"],
  default: ["default"],
};

const checkChooseType = (obj, trueArr) => {
  if (typeof obj === "object")
    return Object.keys(obj).every((type) => {
      if (trueArr.includes(type)) return obj[type];
      else return !obj[type];
    });
  else return false;
};

const changeChooseType = (obj, trueArr) => {
  Object.keys(obj).forEach((type) => {
    if (trueArr.includes(type)) obj[type] = true;
    else obj[type] = false;
  });
  return obj;
};

const createLineEditAccessMainSettings = ({ e, state, dispatch }) => {
  const type = e.nameDB;

  const editAndRead = checkChooseType(state[type], settingsTypes.edit);
  const onlyRead = checkChooseType(state[type], settingsTypes.read);
  const allActions = checkChooseType(state[type], settingsTypes.create);
  const Ban = checkChooseType(state[type], settingsTypes.ban);
  const Settings = checkChooseType(state[type], settingsTypes.settings);

  return (
    <div className="pb-3 role-line">
      <h6 className="mb-3">{e.name}</h6>
      <form
        class="btn-group me-2"
        onChange={(el) => {
          state[type] = changeChooseType(
            state[type],
            settingsTypes[el.target.getAttribute("change-type")]
          );

          dispatch({ state });
        }}
      >
        <input
          type="radio"
          class="btn-check"
          name={type}
          id={type + "edit"}
          change-type="edit"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${
            editAndRead ? "activeRadio" : null
          }`}
          for={type + "edit"}
        >
          Чтение и изменения
        </label>

        <input
          type="radio"
          class="btn-check"
          name={type}
          id={type + "read"}
          change-type="read"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${onlyRead ? "activeRadio" : null}`}
          for={type + "read"}
        >
          Только чтение
        </label>

        <input
          type="radio"
          class="btn-check"
          name={type}
          id={type + "create"}
          change-type="create"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${
            allActions ? "activeRadio" : null
          }`}
          for={type + "create"}
        >
          Полный доступ
        </label>

        <input
          type="radio"
          class="btn-check"
          name={type}
          id={type + "ban"}
          change-type="ban"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${Ban ? "activeRadio" : null}`}
          for={type + "ban"}
        >
          Запретить
        </label>

        <input
          type="radio"
          class="btn-check"
          name={type}
          id={type + "settings"}
          change-type="settings"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${Settings ? "activeRadio" : null}`}
          type="button"
          for={type + "settings"}
          data-bs-toggle="collapse"
          data-bs-target={`#collapseCheckList${type}`}
          aria-expanded="false"
          aria-controls={`collapseCheckList${type}`}
        >
          <i className="bi bi-gear" />
        </label>
      </form>
      <div className="collapse" id={`collapseCheckList${type}`}>
        <div className="d-flex mt-1">
          <div className="d-flex align-items-center me-3">
            <input
              id="added"
              type="checkbox"
              className="me-1"
              checked={state[type].edit}
              onChange={() => {
                state[type].edit = !state[type].edit;
                dispatch({ state });
              }}
            />
            <label for="added">Добавление</label>
          </div>
          <div className="d-flex align-items-center me-3">
            <input
              id="watch"
              type="checkbox"
              className="me-1"
              checked={state[type].read}
              onChange={() => {
                state[type].read = !state[type].read;
                dispatch({ state });
              }}
            />
            <label for="watch">Просмотр</label>
          </div>
          <div className="d-flex align-items-center me-3">
            <input
              id="edit"
              type="checkbox"
              className="me-1"
              checked={state[type].create}
              onChange={() => {
                state[type].create = !state[type].create;
                dispatch({ state });
              }}
            />
            <label for="edit">Редактирование</label>
          </div>
          <div className="d-flex align-items-center me-3">
            <input
              id="delete"
              type="checkbox"
              className="me-1"
              checked={state[type].delete}
              onChange={() => {
                state[type].delete = !state[type].delete;
                dispatch({ state });
              }}
            />
            <label for="delete">Удаление</label>
          </div>
        </div>
      </div>
    </div>
  );
};
const createLineEditAccess = ({ e, state, dispatch, cmsSlug }) => {
  const type = e.slug ?? e.nameDB;

  let obj = state[type];
  if (cmsSlug) obj = state[type][cmsSlug];

  const editAndRead = checkChooseType(obj, settingsTypes.edit);
  const onlyRead = checkChooseType(obj, settingsTypes.read);
  const allActions = checkChooseType(obj, settingsTypes.create);
  const Ban = checkChooseType(obj, settingsTypes.ban);
  const Settings = checkChooseType(obj, settingsTypes.settings);

  return (
    <div className="pb-3 role-line">
      <h6 className="mb-3">{e.name}</h6>
      <form
        class="btn-group me-2"
        onChange={(el) => {
          obj = changeChooseType(
            obj,
            settingsTypes[el.target.getAttribute("change-type")]
          );

          dispatch({ state });
        }}
      >
        <input
          type="radio"
          class="btn-check"
          name={type}
          id={cmsSlug + "-" + type + "edit"}
          change-type="edit"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${
            editAndRead ? "activeRadio" : null
          }`}
          for={cmsSlug + "-" + type + "edit"}
        >
          Чтение и изменения
        </label>

        <input
          type="radio"
          class="btn-check"
          name={type}
          id={cmsSlug + "-" + type + "read"}
          change-type="read"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${onlyRead ? "activeRadio" : null}`}
          for={cmsSlug + "-" + type + "read"}
        >
          Только чтение
        </label>

        <input
          type="radio"
          class="btn-check"
          name={type}
          id={cmsSlug + "-" + type + "create"}
          change-type="create"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${
            allActions ? "activeRadio" : null
          }`}
          for={cmsSlug + "-" + type + "create"}
        >
          Полный доступ
        </label>

        <input
          type="radio"
          class="btn-check"
          name={type}
          id={cmsSlug + "-" + type + "ban"}
          change-type="ban"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${Ban ? "activeRadio" : null}`}
          for={cmsSlug + "-" + type + "ban"}
        >
          Запретить
        </label>

        <input
          type="radio"
          class="btn-check"
          name={type}
          id={cmsSlug + "-" + type + "settings"}
          change-type="settings"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${Settings ? "activeRadio" : null}`}
          type="button"
          for={cmsSlug + "-" + type + "settings"}
          data-bs-toggle="collapse"
          data-bs-target={`#collapseCheckList${type}`}
          aria-expanded="false"
          aria-controls={`collapseCheckList${type}`}
        >
          <i className="bi bi-gear" />
        </label>
      </form>
      <div className="collapse" id={`collapseCheckList${type}`}>
        <div className="d-flex mt-1">
          <div className="d-flex align-items-center me-3">
            <input
              id="added"
              type="checkbox"
              className="me-1"
              checked={state[type].edit}
              onChange={() => {
                state[type].edit = !state[type].edit;
                dispatch({ state });
              }}
            />
            <label for="added">Добавление</label>
          </div>
          <div className="d-flex align-items-center me-3">
            <input
              id="watch"
              type="checkbox"
              className="me-1"
              checked={state[type].read}
              onChange={() => {
                state[type].read = !state[type].read;
                dispatch({ state });
              }}
            />
            <label for="watch">Просмотр</label>
          </div>
          <div className="d-flex align-items-center me-3">
            <input
              id="edit"
              type="checkbox"
              className="me-1"
              checked={state[type].create}
              onChange={() => {
                state[type].create = !state[type].create;
                dispatch({ state });
              }}
            />
            <label for="edit">Редактирование</label>
          </div>
          <div className="d-flex align-items-center me-3">
            <input
              id="delete"
              type="checkbox"
              className="me-1"
              checked={state[type].delete}
              onChange={() => {
                state[type].delete = !state[type].delete;
                dispatch({ state });
              }}
            />
            <label for="delete">Удаление</label>
          </div>
        </div>
      </div>
    </div>
  );
};

const createDynamicLineEditAccess = ({
  e,
  state,
  dispatch,
  field,
  cmsSlug,
}) => {
  const type = e.slug ?? e.nameDB;

  let obj = state[field][type];
  if (cmsSlug) obj = state[field][cmsSlug][type];

  const editAndRead = checkChooseType(obj, settingsTypes.edit);
  const onlyRead = checkChooseType(obj, settingsTypes.read);
  const allActions = checkChooseType(obj, settingsTypes.create);
  const ban = checkChooseType(obj, settingsTypes.ban);
  const settings = checkChooseType(obj, settingsTypes.settings);
  const defaultBool =
    obj === undefined ? true : checkChooseType(obj, settingsTypes.default);

  return (
    <div className="pb-3 role-line" key={cmsSlug + "-" + type}>
      <h6 className="mb-3">{e.name}</h6>
      <form
        class="btn-group me-2"
        key={cmsSlug}
        tttt={cmsSlug}
        onChange={(el) => {
          obj = changeChooseType(
            obj,
            settingsTypes[el.target.getAttribute("change-type")]
          );

          dispatch({ state });
        }}
      >
        <input
          type="radio"
          class="btn-check"
          name={type}
          id={cmsSlug + "-" + type + "default"}
          change-type="default"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${
            defaultBool ? "activeRadio" : null
          }`}
          for={cmsSlug + "-" + type + "default"}
        >
          Дефолт
        </label>
        <input
          type="radio"
          class="btn-check"
          name={type}
          id={cmsSlug + "-" + type + "edit"}
          change-type="edit"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${
            editAndRead ? "activeRadio" : null
          }`}
          for={cmsSlug + "-" + type + "edit"}
        >
          Чтение и изменения
        </label>

        <input
          type="radio"
          class="btn-check"
          name={type}
          id={cmsSlug + "-" + type + "read"}
          change-type="read"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${onlyRead ? "activeRadio" : null}`}
          for={cmsSlug + "-" + type + "read"}
        >
          Только чтение
        </label>

        <input
          type="radio"
          class="btn-check"
          name={type}
          id={cmsSlug + "-" + type + "create"}
          change-type="create"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${
            allActions ? "activeRadio" : null
          }`}
          for={cmsSlug + "-" + type + "create"}
        >
          Полный доступ
        </label>

        <input
          type="radio"
          class="btn-check"
          name={type}
          id={cmsSlug + "-" + type + "ban"}
          change-type="ban"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${ban ? "activeRadio" : null}`}
          for={cmsSlug + "-" + type + "ban"}
        >
          Запретить
        </label>

        <input
          type="radio"
          class="btn-check"
          name={type}
          id={cmsSlug + "-" + type + "settings"}
          change-type="settings"
          autocomplete="off"
        />
        <label
          class={`btn btn btn-sm btn-light ${settings ? "activeRadio" : null}`}
          type="button"
          for={cmsSlug + "-" + type + "settings"}
          data-bs-toggle="collapse"
          data-bs-target={`#collapseCheckList${type}`}
          aria-expanded="false"
          aria-controls={`collapseCheckList${type}`}
        >
          <i className="bi bi-gear" />
        </label>
      </form>
      <div className="collapse" id={`collapseCheckList${type}`}>
        <div className="d-flex mt-1">
          <div className="d-flex align-items-center me-3">
            <input
              id="added"
              type="checkbox"
              className="me-1"
              checked={obj?.edit}
              onChange={() => {
                obj.edit = !obj.edit;
                dispatch({ state });
              }}
            />
            <label for="added">Добавление</label>
          </div>
          <div className="d-flex align-items-center me-3">
            <input
              id="watch"
              type="checkbox"
              className="me-1"
              checked={obj?.read}
              onChange={() => {
                obj.read = !obj.read;
                dispatch({ state });
              }}
            />
            <label for="watch">Просмотр</label>
          </div>
          <div className="d-flex align-items-center me-3">
            <input
              id="edit"
              type="checkbox"
              className="me-1"
              checked={obj?.create}
              onChange={() => {
                obj.create = !obj.create;
                dispatch({ state });
              }}
            />
            <label for="edit">Редактирование</label>
          </div>
          <div className="d-flex align-items-center me-3">
            <input
              id="delete"
              type="checkbox"
              className="me-1"
              checked={obj?.delete}
              onChange={() => {
                obj.delete = !obj.delete;
                dispatch({ state });
              }}
            />
            <label for="delete">Удаление</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export {
  createLineEditAccessMainSettings,
  createDynamicLineEditAccess,
  createLineEditAccess,
  checkChooseType,
  changeChooseType,
};
