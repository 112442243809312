import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import api from "../../func/api";
import { errorCatch } from "../../func/common";

function PersonList({ nameColl, cms }) {
  const [users, setUsers] = useState([]);

  const getData = () => {
    api
      .getUsers()
      .then((result) => {
        setUsers(result);
      })
      .catch((err) => {
        errorCatch(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const listUsersHTML = users.map((el) => {
    const cmsArrAccess = el.cmsAccess ? Object.keys(el.cmsAccess) : [];
    return (
      <tr key={el.slug}>
        <td>
          <input type="checkbox" className="form-check-input" />
        </td>
        <td>
          <Link
            to={`/front/${cms}/settings/user/${el.username}`}
            className="tr-title"
          >
            {el.name}
          </Link>
        </td>
        <td>
          <span className="badge bg-secondary">{el.role?.name}</span>
        </td>
        {el.cmsAccess && cmsArrAccess.length && (
          <td>
            {cmsArrAccess.map((cms) => (
              <span className="badge bg-secondary me-1">{cms}</span>
            ))}
          </td>
        )}
        <td className="text-end">
          <i
            className="bi bi-trash pointer me-3"
            onClick={() => {
              api
                .deleteUser(el._id)
                .then((result) => {
                  getData();
                })
                .catch((err) => {
                  errorCatch(err);
                });
            }}
          />
        </td>
      </tr>
    );
  });

  return (
    <main className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center mb-4 mt-4">
            <div>
              <h4 className="m-0">Пользователи</h4>
            </div>
            <div>
              <div>
                <Link
                  className="btn btn-primary"
                  to={`/front/${cms}/settings/user/new`}
                >
                  Создать
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="">
            {/* <ul class="nav nav-tabs border-0">
              <li class="nav-item">
                <a class="nav-link p-2 active" aria-current="page" href="#">
                  Все
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link p-2" href="#">
                  Активные (10)
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link p-2 disabled"
                  href="#"
                  tabindex="-1"
                  aria-disabled="true"
                >
                  Архив
                </a>
              </li>
            </ul> */}
          </div>
          <div className="d-flex align-items-center filters">
            <input
              placeholder="Поиск"
              className="ms-2 ps-2 rounded text-secondary border-secondary border-1 filters-input"
            />
            <button className="btn btn-outline-dark ms-2">
              <i className="bi bi-search" />
            </button>
          </div>
        </div>
        <div className="border rounded mb-3">
          <table className="table table-striped mb-0">
            <thead className="th-title">
              <tr>
                <th className="table-checkbox">
                  <input type="checkbox" className="form-check-input" />
                </th>
                <th>Название</th>
                <th>Тип</th>
                <th>Доступ к CMS</th>
                <th className="text-end pe-4">Управление</th>
              </tr>
            </thead>
            <tbody>{listUsersHTML}</tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <nav aria-label="Page navigation example">
              <ul class="pagination m-0">
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="input-group input-group_small">
            <span class="input-group-text">Выводить</span>
            <input type="text" class="form-control" placeholder="10" />
          </div>
        </div>
      </div>
    </main>
  );
}

export default PersonList;
