// import { observer } from "mobx-react";
import React, { useState, useEffect } from "react";

import UploadMediaModal from "./common/UploadMediaModal";
import Endpoints from "./common/Endpoints";

import api from "../func/api";
import { SERVER_URL } from "../func/constants";
import Pagination from "./common/Pagination";
import ModalDelete from "./common/ModalDelete";

const { Component } = React;

const grid = 8;

/**
 *
 * @param {{title?:string,text?:string, open?:boolean, closePopUp?:()=>void, deleteFunc?:()=>void, additString?:string, disabled?:boolean}} defaultValues
 * @returns {[{title:string,text:string, open:boolean, closePopUp:()=>void, deleteFunc:()=>void, additString:string, disabled?:boolean}, (newData:{title?:string,text?:string, open?:boolean, closePopUp?:()=>void, deleteFunc?:()=>void, additString?:string, disabled?:boolean})=>void]}
 */
const useDeletePopupHook = (defaultValues = {}) => {
  const [title, setTitle] = useState(defaultValues.title ?? "");
  const [text, setText] = useState(defaultValues.text ?? "");
  const [open, setOpen] = useState(defaultValues.open ?? false);
  const [deleteFunc, setDeleteFunc] = useState(
    defaultValues.deleteFunc ?? (() => () => null)
  );
  const [additString, setAdditString] = useState(
    defaultValues.additString ?? ""
  );
  const [disabled, setDisabled] = useState(defaultValues.disabled ?? false);
  const [closePopUp, setClosePopUp] = useState(() => {
    return () => {
      setOpen(false);
      setAdditString("");
      setText("");
      setDeleteFunc(() => null);
    };
  });

  /**
   *
   * @param {{title:string,text:string, open:boolean, closePopUp:()=>void, deleteFunc:()=>void, additString:string, disabled?:boolean}} param0
   */
  const setDeletePopupData = ({
    title,
    text,
    open,
    closePopUp,
    deleteFunc,
    additString,
    disabled,
  }) => {
    if (title) setTitle(title);
    if (text) setText(text);
    if (open) setOpen(open);
    if (closePopUp) setClosePopUp(() => closePopUp);
    if (deleteFunc) setDeleteFunc(() => deleteFunc);
    if (additString) setAdditString(additString);
    if (disabled) setDisabled(disabled);
  };

  return [
    { title, text, open, closePopUp, deleteFunc, additString, disabled },
    setDeletePopupData,
  ];
};

const Media = ({ slug, routProps }) => {
  const [limit, setLimit] = useState(40);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [data, setData] = useState(null);
  const [popupLink, setPopupLink] = useState("");
  const [popupActive, setPopupActive] = useState(false);
  const [name, setName] = useState("");
  const [showUrl, setShowUrl] = useState(false);
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [activePic, setActivePic] = useState(null);
  const [isCollection, setIsCollection] = useState(false);
  const [endPointsOn, setEndPointsOn] = useState(false);
  const [sort, setSort] = useState({});

  const [filter, setFilter] = useState({});
  const [notUsedFilter, setNotUsedFilter] = useState(false);
  const [chooseAll, setChooseAll] = useState(false);
  const [choose, setChoose] = useState({});
  const [deleteData, setDeleteData] = useDeletePopupHook();

  let newSection = slug === "new";

  const getData = () => {
    api
      .getMedia({
        filter,
        settings: {
          sort,
          limit: +limit,
          skip: +limit * (page - 1),
        },
      })
      .then((data) => {
        setData(data);
        setChooseAll(false);
        setChoose({});
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  };

  useEffect(() => {
    api
      .getMediaCount({ filter })
      .then((result) => {
        setCount(result.count);
      })
      .catch((err) => {
        console.error("err :>> ", err);
      });
  }, [filter]);

  useEffect(() => {
    getData();
  }, [limit, page, sort, filter]);

  const clearActivePic = () => {
    setActivePic(null);
    setOpenMediaModal(false);
  };

  const popupClose = () => {
    setPopupActive(false);
  };

  const changeUploadType = () => {
    setShowUrl((showUrl) => !showUrl);
  };

  const getEndPointsOn = () => {
    setEndPointsOn((endPointsOn) => !endPointsOn);
  };

  const closeDeletePopup = () => {
    setDeleteData({ open: false, title: "" });
  };

  const saveHandler = () => {
    setPopupActive(false);

    getData();
  };

  const deletePhoto = ({ type, data }) => {
    api
      .deleteMediaBatch({ type, data })
      .then((result) => {
        getData();
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  };

  let media;

  const dataReady = data !== null;
  if (dataReady) {
    console.log(`data`, data);
    media = data.map((el) => {
      if (/.jpeg|.png|.jpg|.svg/.test(el.url)) {
        const imgLink = `${SERVER_URL + el.url}`;
        const size = el.size / 1024;
        const inChoose = choose[el._id];

        return (
          <div
            className="col-3 mb-4"
            // data-bs-toggle="modal"
            // data-bs-target="#uploadMediaModal"
          >
            <div
              className={`media-block p-3 ${
                inChoose || chooseAll ? "choose" : ""
              }`}
              style={{
                backgroundImage: "url(" + imgLink + ")",
              }}
              onClick={(e) => {
                setActivePic(el);
                setOpenMediaModal(true);
              }}
            >
              <input
                type="checkbox"
                className="media-input form-check-input pointer"
                checked={chooseAll || inChoose !== undefined}
                onClick={(e) => {
                  if (inChoose) {
                    setChoose((ch) => {
                      delete ch[el._id];
                      return { ...ch };
                    });
                  } else {
                    setChoose((ch) => {
                      ch[el._id] = el;

                      return { ...ch };
                    });
                  }
                  e.stopPropagation();
                }}
                // onChange={(e) => {
                //   e.preventDefault();
                //   e.stopPropagation();

                // }}
              />
            </div>
            <p className="m-0">{el.name}</p>
            <p className="text-secondary m-0">
              {el.ext.toUpperCase()} - {el.width}x{el.height} -{" "}
              {size > 1024
                ? (size / 1024).toFixed(2) + " MB"
                : size.toFixed(2) + " KB"}
            </p>
          </div>
        );
        // return (
        //   <div className="col-3 mb-4">
        //     <div
        //       className="media-block p-3"
        //       style={{
        //         backgroundImage: "url(" + imgLink + ")",
        //       }}
        //       onClick={() => {
        //         this.setState({
        //           popupLink: imgLink,
        //           popupActive: true,
        //           name: el.name,
        //         });
        //       }}
        //     >
        //       <i
        //         className="bi bi-trash"
        //         onClick={(e) => {
        //           e.stopPropagation();
        //           api
        //             .deleteMedia({ name: el.name })
        //             .then((result) => {
        //               if (result.ok) {
        //                 this.getData();
        //               }
        //             })
        //             .catch((err) => {
        //               console.log(`err`, err);
        //             });
        //         }}
        //       ></i>
        //     </div>
        //   </div>
        // );
      }
    });
  }

  return (
    dataReady && (
      <main className="main">
        {console.log(`data`, data)}
        {endPointsOn ? (
          <div className="media-endpoints">
            <Endpoints path="/media" />
          </div>
        ) : null}
        <div className="container-fluid">
          <div className="d-flex mb-3">
            <h4 className="me-4">{data.title}</h4>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center mb-4">
              <div>
                <h5 className="m-0">Изображения</h5>
              </div>
              <div>
                <button
                  className="btn btn-outline-dark"
                  onClick={getEndPointsOn}
                >
                  Endpoints
                </button>
                {/* <button className="btn btn-outline-danger ms-3 disabled">
                  Удалить
                </button> */}
                <button
                  className="btn btn-primary ms-3"
                  onClick={() => {
                    setOpenMediaModal(true);
                  }}
                >
                  + Медиа файл
                </button>
                <div>
                  {/* <EmptyMediaModal
                      showURL={showURL}
                      changeUploadType={this.changeUploadType}
                      isCollection={isCollection}
                    /> */}
                </div>
                {/* <UploadMediaModal
                    showURL={showURL}
                    changeUploadType={this.changeUploadType}
                    isCollection={isCollection}
                  /> */}
              </div>
              {/* <button
                  className="btn btn-sm btn-primary"
                  onClick={(e) => {
                    $(e.target).find('input[type="file"]').click();
                  }}
                >
                  Upload
                  <input
                    type="file"
                    name="uploadImg"
                    accept="image/jpeg,image/png,image/svg+xml"
                    onInput={(e) => {
                      if (e.target.files.length) {
                        const formData = new FormData();
                        e.target.files[0].alt = "test";
                        formData.append("uploadImg", e.target.files[0]);
                        let xhr = new XMLHttpRequest();

                        xhr.onloadend = () => {
                          if (xhr.status == 200) {
                            console.log("Успех");
                            this.getData();
                          } else {
                            console.log("Ошибка " + this.status);
                          }
                        };

                        xhr.open("POST", SERVER_URL + "/upload-media");
                        xhr.send(formData);
                      }
                    }}
                  />
                </button> */}
              {/* <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="integrations">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {banners}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext> */}
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 d-flex align-items-center">
              <div className="me-3">
                <input
                  type="checkbox"
                  id="checkboxChooseAll"
                  className="me-1 form-check-input"
                  checked={chooseAll}
                  onChange={() => setChooseAll((ch) => !ch)}
                />
                <label for="checkboxChooseAll">Выбрать все</label>
              </div>
              <div class="dropdown me-3">
                <button
                  class="btn btn-outline-primary fs-6 dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Сортировать
                  <i className="bi bi-chevron-down ms-3" />
                </button>
                <ul
                  class="dropdown-menu mt-2"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <p
                      class="dropdown-item m-0 m-0"
                      onClick={() => setSort({ dateCreate: -1 })}
                    >
                      Сначала недавно загруженные
                    </p>
                  </li>
                  <li>
                    <p
                      class="dropdown-item m-0"
                      onClick={() => setSort({ dateCreate: 1 })}
                    >
                      Сначало давно загруженные
                    </p>
                  </li>
                  <li>
                    <p
                      class="dropdown-item m-0"
                      onClick={() => setSort({ name: 1 })}
                    >
                      По алфавиту (А-Я)
                    </p>
                  </li>
                  <li>
                    <p
                      class="dropdown-item m-0"
                      onClick={() => setSort({ name: -1 })}
                    >
                      По алфавиту обратно (Я-А)
                    </p>
                  </li>
                  <li>
                    <p
                      class="dropdown-item m-0"
                      onClick={() => setSort({ updatedAt: -1 })}
                    >
                      Сначала недавно обновленные
                    </p>
                  </li>
                  <li>
                    <p
                      class="dropdown-item m-0"
                      onClick={() => setSort({ updatedAt: 1 })}
                    >
                      Сначала давно обновленные
                    </p>
                  </li>
                </ul>
              </div>
              <div class="dropdown">
                <button
                  class="btn btn-outline-dark fs-6 dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="bi bi-filter me-2" />
                  Фильтры
                </button>
                <ul
                  class="dropdown-menu mt-2"
                  aria-labelledby="dropdownMenuButton2"
                >
                  <li>
                    <p
                      class="dropdown-item m-0"
                      onClick={() => {
                        setFilter({});
                        setNotUsedFilter(false);
                      }}
                    >
                      Все
                    </p>
                  </li>
                  <li>
                    <p
                      class="dropdown-item m-0"
                      onClick={() => {
                        setFilter({
                          $and: [
                            { whereUsed: { $not: { $size: 0 } } },
                            { whereUsed: { $exists: true } },
                          ],
                        });
                        setNotUsedFilter(false);
                      }}
                    >
                      Используемые
                    </p>
                  </li>
                  <li>
                    <p
                      class="dropdown-item m-0"
                      onClick={() => {
                        setFilter({
                          $or: [
                            { whereUsed: { $size: 0 } },
                            { whereUsed: { $exists: false } },
                          ],
                        });
                        setNotUsedFilter(true);
                      }}
                    >
                      Не используемые
                    </p>
                  </li>
                </ul>
              </div>
              {(chooseAll || Object.keys(choose).length > 0) && (
                <button
                  className="btn btn-outline-danger ms-3"
                  onClick={() => {
                    const checkAndCreateAdditString = (image) => {
                      const warningStringWithUsedPhoto = [];

                      image.forEach((img) => {
                        if (img.whereUsed && img.whereUsed.length) {
                          img.whereUsed.forEach((used) => {
                            warningStringWithUsedPhoto.push(
                              <p>
                                Фотография {img.name} используется в документе
                                коллекции {used.nameDBCol}, id:{" "}
                                <a
                                  href={`/front/${used.cmsSlug}/${used.type}/${used.slugCol}/${used.id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {used.id}
                                </a>
                              </p>
                            );
                          });
                        }
                      });
                      return warningStringWithUsedPhoto;
                    };

                    const newDeleteData = {
                      open: true,
                      title: `Удалить ${
                        chooseAll ? count : Object.keys(choose).length
                      } фотографий?`,
                      text: "Вы уверены?",
                      // closePopUp: closeDeletePopup,
                      disabled: false,
                      deleteFunc: () =>
                        deletePhoto({
                          type: chooseAll ? "filter" : "id",
                          data: chooseAll ? filter : Object.keys(choose),
                        }),
                    };

                    if (!notUsedFilter) {
                      if (!chooseAll) {
                        const warningStringWithUsedPhoto =
                          checkAndCreateAdditString(Object.values(choose));
                        if (warningStringWithUsedPhoto.length) {
                          newDeleteData.text =
                            "ВНИМАНИЕ, ИЗОБРАЖЕНИЯ ИСПОЛЬЗУЮТСЯ";
                          newDeleteData.additString = [
                            ...warningStringWithUsedPhoto,
                          ];
                        }
                      } else {
                        newDeleteData.disabled = true;
                        newDeleteData.text =
                          "Производится проверка использования фотографий";
                        api
                          .checkUsedMedia({ filter })
                          .then((result) => {
                            if (result && result.length) {
                              const warningStringWithUsedPhoto =
                                checkAndCreateAdditString(result);
                              if (warningStringWithUsedPhoto.length) {
                                setDeleteData({
                                  additString: warningStringWithUsedPhoto,
                                  disabled: false,
                                  text: "ВНИМАНИЕ, ИЗОБРАЖЕНИЯ ИСПОЛЬЗУЮТСЯ",
                                });
                              } else {
                                setDeleteData({
                                  disabled: false,
                                  text: "Вы уверены?",
                                });
                              }
                            } else {
                              setDeleteData({
                                disabled: false,
                                text: "Вы уверены?",
                              });
                            }
                          })
                          .catch((err) => {
                            console.log("err", err);
                          });
                      }
                    }

                    setDeleteData(newDeleteData);
                  }}
                >
                  Удалить {chooseAll ? count : Object.keys(choose).length}{" "}
                  фотографий
                </button>
              )}
            </div>
          </div>
          <div className="row mb-5">{media}</div>
          <div className="row">
            <Pagination
              page={page}
              limitInPage={limit}
              setPage={setPage}
              setLimitInPage={setLimit}
              countAll={count}
              route={routProps}
            />
          </div>
        </div>
        {/* <PopupMedia
            link={popupLink}
            active={popupActive}
            name={name}
            close={this.popupClose}
            save={this.saveHandler}
          />
          <div className="container-fluid">
            <div className="col-12">
              <div className="row">{media}</div>
            </div>
          </div> */}

        <UploadMediaModal
          open={openMediaModal}
          changeUploadType={changeUploadType}
          showURL={showUrl}
          activePic={activePic}
          setActivePic={setActivePic}
          close={clearActivePic.bind(this)}
          getData={getData}
        />
        <ModalDelete {...deleteData} />
      </main>
    )
  );
};

const EmptyMediaModal = (props) => {
  const { state, changeUploadType } = props;
  return (
    <div
      class="modal fade"
      id="emptyMediaModal"
      tabindex="-1"
      aria-labelledby="emptyMediaModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-media">
        <div class="modal-content">
          <div class="modal-header bg-light">
            <h5 class="modal-title" id="emptyMediaModalLabel">
              Загрузка медиа
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body"></div>
        </div>
      </div>
    </div>
  );
};

const Overlay = ({ children, open, close }) => {
  return (
    <div
      className={`overlay fade ${open ? "show" : ""}`}
      onClick={() => {
        close();
      }}
    ></div>
  );
};

export default Media;
