import React, { useReducer, useEffect, useState } from "react";
import $ from "jquery";
import { changeData } from "../../func/common";
import { FIELDS_TYPE_FOR_SEARCH, settingsFields } from "../../func/constants";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import api from "../../func/api";

const Edit = ({
  dataField,
  setDataField,
  componentsSelect,
  schemasSelect,
  data,
}) => {
  console.log("data :>> ", data);
  return (
    <div className="modal-body edit-field">
      <div className="row">
        <div className="col col-6">
          <p className="m-0">Имя</p>
          <div className="input-group mb-2">
            <input
              className="form-control"
              type="text"
              value={dataField.name}
              onChange={(e) => {
                dataField.name = e.target.value;
                setDataField({ ...dataField });
              }}
            />
          </div>
          {"settingField" in dataField &&
            (dataField.typeField === "automat" ? (
              <>
                <p>Тип автоматизации</p>
                <div className="input-group mb-2">
                  <CreatableSelect
                    options={[
                      { label: "Слагифатор", value: "slugifator" },
                      { label: "=", value: "=" },
                      { label: ">", value: ">" },
                      { label: "<", value: "<" },
                      // { label: " | ", value: "|" },
                    ]}
                    defaultValue={dataField.settingField}
                    className="select-modal"
                    onChange={(e) => {
                      dataField.settingField = e.value;
                      setDataField({ ...dataField });

                      //   console.log(`e`, e);
                    }}
                  />
                </div>
                {dataField.settingField === "slugifator" && (
                  <>
                    <p>Поле для слагифатора</p>
                    <div className="input-group mb-2">
                      <CreatableSelect
                        options={data.body.map(
                          (field) =>
                            dataField.nameDB !== field.nameDB && {
                              label: field.name,
                              value: field.nameDB,
                            }
                        )}
                        defaultValue={dataField.settingField}
                        className="select-modal"
                        onChange={(e) => {
                          dataField.data = { nameDBField: e.value };
                          setDataField({ ...dataField });

                          //   console.log(`e`, e);
                        }}
                      />
                    </div>
                  </>
                )}
                {(dataField.settingField === ">" ||
                  dataField.settingField === "<" ||
                  dataField.settingField === "=") && (
                  <>
                    <p>Поле 1</p>
                    <div className="input-group mb-2">
                      <CreatableSelect
                        options={data.body.map(
                          (field) =>
                            dataField.nameDB !== field.nameDB && {
                              label: field.name,
                              value: field.nameDB,
                            }
                        )}
                        defaultValue={dataField.settingField}
                        className="select-modal"
                        onChange={(e) => {
                          if (dataField.data?.nameDBFields) {
                            dataField.data.nameDBFields[0] = e.value;
                          } else {
                            dataField.data = { nameDBFields: [e.value] };
                          }
                          setDataField({ ...dataField });

                          //   console.log(`e`, e);
                        }}
                      />
                    </div>
                    <p>Поле 2</p>
                    <div className="input-group mb-2">
                      <CreatableSelect
                        options={data.body.map(
                          (field) =>
                            dataField.nameDB !== field.nameDB && {
                              label: field.name,
                              value: field.nameDB,
                            }
                        )}
                        defaultValue={dataField.settingField}
                        className="select-modal"
                        onChange={(e) => {
                          if (dataField.data?.nameDBFields) {
                            dataField.data.nameDBFields[1] = e.value;
                          } else {
                            dataField.data = { nameDBFields: [null, e.value] };
                          }
                          setDataField({ ...dataField });

                          //   console.log(`e`, e);
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <p>Тип</p>
                <div
                  class="form-check form-check__radio mb-2"
                  onClick={(e) => {
                    dataField.settingField = "input";

                    setDataField({ ...dataField });
                  }}
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={dataField.settingField === "input"}
                    onChange={(e) => {
                      dataField.settingField = e.target.checked
                        ? "input"
                        : "textarea";
                      setDataField({ ...dataField });
                    }}
                  />
                  <p class="form-check-label fs-12" for="flexRadioDefault1">
                    Короткий текст
                    <br />
                    <label>
                      {" "}
                      Применимо для названий, заголовков, ссылок... (учавствует
                      в поиске)
                    </label>
                  </p>
                </div>
                <div
                  class="form-check form-check__radio"
                  onClick={(e) => {
                    dataField.settingField = "textarea";

                    setDataField({ ...dataField });
                  }}
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={dataField.settingField === "textarea"}
                    onChange={(e) => {
                      dataField.settingField = e.target.checked
                        ? "textarea"
                        : "input";
                      setDataField({ ...dataField });
                    }}
                  />
                  <p class="form-check-label" for="flexRadioDefault2">
                    Большой текст
                    <br />
                    <label>
                      Применимо для описания, биографии... (не учавствует в
                      поиске)
                    </label>
                  </p>
                </div>
                <div className="slash my-3"></div>
              </>
            ))}
          {"separator" in dataField && (
            <>
              <p>Разделитель</p>
              <div className="input-group mb-2">
                <CreatableSelect
                  options={[
                    { label: " , ", value: "," },
                    { label: " | ", value: "|" },
                  ]}
                  className="select-modal"
                  onChange={(e) => {
                    dataField.separator = e.value;
                    setDataField({ ...dataField });

                    //   console.log(`e`, e);
                  }}
                />
              </div>
            </>
          )}
          {dataField.typeField === "component" && (
            <>
              <p>Коллекция, на документ из которой будет ссылка</p>
              <div className="input-group mb-2">
                <Select
                  options={componentsSelect}
                  className="select-modal"
                  onChange={(e) => {
                    dataField.component = e.value;
                    setDataField({ ...dataField });

                    //   console.log(`e`, e);
                  }}
                />
              </div>
              <p>Тип</p>
              <div class="form-check form-check__radio mb-2">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked={!dataField.isMulti}
                  onChange={(e) => {
                    dataField.isMulti = !e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <p class="form-check-label" for="flexRadioDefault1">
                  Одиночный компонент <br />
                  <label>
                    Применимо для группировки полей, таких как полный адрес,
                    основная информация и т.д.
                  </label>
                </p>
              </div>
              <div class="form-check form-check__radio">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked={dataField.isMulti}
                  onChange={(e) => {
                    dataField.isMulti = e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <p class="form-check-label" for="flexRadioDefault2">
                  Повторяющийся компонент
                  <br />
                  <label>
                    Применимо для множественных вхождений (массивов)
                    ингредиентов, мета-тегов и т.д.
                  </label>
                </p>
              </div>
              <div className="slash my-3"></div>
            </>
          )}
          {dataField.typeField === "ref" && (
            <>
              <p>Choose you schema, Jedi</p>
              <div className="input-group mb-2">
                <Select
                  options={schemasSelect}
                  className="select-modal"
                  onChange={(e) => {
                    dataField.refTo = e.value;
                    setDataField({ ...dataField });

                    //   console.log(`e`, e);
                  }}
                />
              </div>
              <p>Тип</p>
              <div class="form-check form-check__radio mb-2">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked={!dataField.isMulti}
                  onChange={(e) => {
                    dataField.isMulti = !e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <p class="form-check-label" for="flexRadioDefault1">
                  Одиночная связь <br />
                  <label>
                    Product ссылается на один элемент из коллекции File
                  </label>
                </p>
              </div>
              <div class="form-check form-check__radio">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked={dataField.isMulti}
                  onChange={(e) => {
                    dataField.isMulti = e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <p class="form-check-label" for="flexRadioDefault2">
                  Множественная связь
                  <br />
                  <label>
                    {" "}
                    Product ссылается на множество элементов из коллекции File
                  </label>
                </p>
              </div>
              <div className="slash my-3"></div>
            </>
          )}
          {dataField.typeField === "image" && (
            <>
              <p>Тип</p>
              <div class="form-check form-check__radio mb-2">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked={dataField.isMulti}
                  onChange={(e) => {
                    console.log(`1`, e.target.checked);
                    dataField.isMulti = e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <p class="form-check-label" for="flexRadioDefault1">
                  Множественные медиа <br />
                  <label> Применимо для слайдеров, каруселей и т.д.</label>
                  <br />
                </p>
              </div>
              <div class="form-check form-check__radio">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked={!dataField.isMulti}
                  onChange={(e) => {
                    console.log(`2`, e.target.checked);
                    dataField.isMulti = !e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <p class="form-check-label" for="flexRadioDefault2">
                  Одиночное медиа
                  <br />
                  <label>Применимо для аватаров, картинок профиля и т.д.</label>
                </p>
              </div>
              <div className="slash my-3"></div>
            </>
          )}
          {dataField.typeField === "select" && (
            <>
              <p>Значения (одна линия на значение)</p>
              <div className="input-group mb-2">
                <textarea
                  className="form-control"
                  defaultValue={dataField.selectVars.reduce((str, el) => {
                    return str + el + "\n";
                  }, "")}
                  onChange={(e) => {
                    dataField.selectVars = e.target.value.split("\n");
                    setDataField({ ...dataField });
                  }}
                />
              </div>
            </>
          )}
          {"defaultValue" in dataField && (
            <>
              <p>Стандартное значение</p>
              {dataField.typeField === "boolean" ? (
                <div class="btn-group">
                  <button
                    type="button"
                    class={`btn ${
                      dataField.defaultValue === true
                        ? "btn-primary"
                        : "bg-light"
                    }`}
                    onClick={() => {
                      dataField.defaultValue = true;
                      setDataField({ ...dataField });
                    }}
                  >
                    True
                  </button>
                  <button
                    type="button"
                    class={`btn ${
                      dataField.defaultValue === "" ? "btn-primary" : "bg-light"
                    }`}
                    onClick={() => {
                      dataField.defaultValue = "";
                      setDataField({ ...dataField });
                    }}
                  >
                    Null
                  </button>
                  <button
                    type="button"
                    class={`btn ${
                      dataField.defaultValue === false
                        ? "btn-primary"
                        : "bg-light"
                    }`}
                    onClick={() => {
                      dataField.defaultValue = false;
                      setDataField({ ...dataField });
                    }}
                  >
                    False
                  </button>
                </div>
              ) : (
                <div className="input-group mb-2">
                  <input
                    className="form-control"
                    type="text"
                    value={dataField.defaultValue}
                    onChange={(e) => {
                      dataField.defaultValue = e.target.value;
                      setDataField({ ...dataField });
                    }}
                  />
                </div>
              )}
            </>
          )}
          {"regexpField" in dataField && (
            <>
              <p>Шаблон регулярного выражения</p>
              <div className="input-group mb-2">
                <input
                  className="form-control"
                  type="text"
                  value={dataField.regexpField}
                  onChange={(e) => {
                    dataField.regexpField = e.target.value;
                    setDataField({ ...dataField });
                  }}
                />
              </div>
            </>
          )}
          {"placeholderField" in dataField && (
            <>
              <p>Placeholder</p>
              <div className="input-group mb-2">
                <input
                  className="form-control"
                  type="text"
                  value={dataField.placeholderField}
                  onChange={(e) => {
                    dataField.placeholderField = e.target.value;
                    setDataField({ ...dataField });
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="col col-6">
          <p>Настройки</p>
          {dataField.typeField === "ref" && (
            <>
              <p className="mb-1">Колличество спуска</p>
              <Select
                options={[
                  { label: 0, value: 0 },
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                  { label: 3, value: 3 },
                ]}
                defaultValue={{ label: 1, value: 1 }}
                className="select-modal w-50 mb-2"
                onChange={(e) => {
                  dataField.autoPaginMaxDepth = +e.value;
                  setDataField({ ...dataField });

                  //   console.log(`e`, e);
                }}
              />
              {/* <input
                className="form-control mb-2"
                type="number"
                value={dataField.autoPaginMaxDepth}
                onChange={(e) => {
                  dataField.autoPaginMaxDepth = +e.target.value;
                  setDataField({ ...dataField });
                }}
              /> */}
            </>
          )}
          {"requiredField" in dataField && (
            <>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="requiredField"
                  checked={dataField.requiredField}
                  onChange={(e) => {
                    dataField.requiredField = e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <label className="form-check-label" htmlFor="requiredField">
                  Обязательное поле
                </label>
              </div>
              <p className="check-discl description">
                Вы не можете создать запись, если это поле не заполнено
              </p>
            </>
          )}
          {"uniqueField" in dataField && (
            <>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="uniqueField"
                  checked={dataField.uniqueField}
                  onChange={(e) => {
                    dataField.uniqueField = e.target.checked;
                    setDataField({ ...dataField });
                  }}
                />
                <label className="form-check-label" htmlFor="uniqueField">
                  Уникальное поле
                </label>
              </div>
              <p className="check-discl description">
                Вы не можете создать запись, если уже существует запись с таки
                значением
              </p>
            </>
          )}

          {"isMulti" in dataField &&
            dataField.typeField !== "ref" &&
            dataField.typeField !== "image" &&
            dataField.typeField !== "component" && (
              <>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="isMulti"
                    checked={dataField.isMulti}
                    onChange={(e) => {
                      dataField.isMulti = e.target.checked;
                      setDataField({ ...dataField });
                    }}
                  />
                  <label className="form-check-label" htmlFor="isMulti">
                    Мультиселект
                  </label>
                </div>
                <p className="check-discl description">
                  Возможность выбрать несколько вариантов
                </p>
              </>
            )}
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="closeField"
              checked={dataField.closeField}
              onChange={(e) => {
                dataField.closeField = e.target.checked;
                setDataField({ ...dataField });
              }}
            />
            <label className="form-check-label" htmlFor="closeField">
              Закрытое поле
            </label>
          </div>
          <p className="check-discl description">
            Это поле не будет отображаться в ответе API
          </p>
          {"maxLengthField" in dataField && (
            <div className="row">
              <div className="col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="maxLengthField"
                  />
                  <label className="form-check-label" htmlFor="maxLengthField">
                    Максимальная длина
                  </label>
                </div>
                <input
                  className="form-control"
                  type="number"
                  onChange={(e) => {
                    dataField.maxLengthField = e.target.value;
                    setDataField({ ...dataField });
                  }}
                />
              </div>
              <div className="col col-6">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="minLengthField"
                  />
                  <label className="form-check-label" htmlFor="minLengthField">
                    Минимальная длина
                  </label>
                </div>
                <input
                  className="form-control"
                  type="number"
                  onChange={(e) => {
                    dataField.minLengthField = e.target.value;
                    setDataField({ ...dataField });
                  }}
                />
              </div>
            </div>
          )}
          {FIELDS_TYPE_FOR_SEARCH.includes(dataField.typeField) && (
            <div className="row">
              <div className="col col-12">
                <label className="form-check-label" htmlFor="nameDB">
                  Важность поля при текстовом поиске
                </label>

                <input
                  className="form-control"
                  type="number"
                  defaultValue={dataField.textIndexWeight ?? 1}
                  onChange={(e) => {
                    dataField.textIndexWeight = +e.target.value;
                    setDataField({ ...dataField });
                  }}
                />
                <p className="check-discl description">
                  Чем больше значение, тем выше приоритет в поиске. 0 отключает
                  поле от поиска
                </p>
              </div>
            </div>
          )}
          <div className="row mb-1">
            <div className="col col-12">
              <label className="form-check-label" htmlFor="nameDB">
                Имя в БД
              </label>

              <input
                className="form-control"
                type="text"
                defaultValue={dataField.nameDB}
                onChange={(e) => {
                  dataField.nameDB = e.target.value;
                  setDataField({ ...dataField });
                }}
              />
            </div>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="indexField"
              checked={dataField.indexField}
              onChange={(e) => {
                dataField.indexField = e.target.checked;
                setDataField({ ...dataField });
              }}
            />
            <label className="form-check-label" htmlFor="indexField">
              Индексируемое поле
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const Choose = ({ setDataField }) => {
  const varsList = Object.keys(settingsFields).map((el) => (
    <div
      className="type-fields__elem"
      onClick={(e) => {
        setDataField(settingsFields[el].def);
      }}
    >
      {settingsFields[el].badge}
      <p>{settingsFields[el].name}</p>
      <span>{settingsFields[el].desc}</span>
    </div>
  ));

  return (
    <div className="modal-body type-fields">
      <h6>Выберите поле для типа контента</h6>
      <div className="type-fields__cont">{varsList}</div>
    </div>
  );
};

const StartPopup = ({
  type,
  dataField,
  setDataField,
  setNewName,
  newName,
  chooseLockTypeNameBD,
  setChooseLockTypeNameBD,
  lockTypes,
}) => {
  const lockTypesArr = Object.values(lockTypes);

  // type !== "component" ? (
  return (
    <div className="modal-body edit-field">
      <div className="row">
        <div className="col col-6">
          <p>Отображаемое имя</p>
          <div className="input-group mb-2">
            <input
              className="form-control"
              type="text"
              value={newName}
              onChange={(e) => {
                setNewName(e.target.value);
              }}
            />
          </div>
          <p>UID</p>
          <div className="input-group mb-2">
            <input className="form-control" type="text" />
          </div>
        </div>
        {lockTypesArr.length > 0 && (
          <div className="col col-6">
            <p>
              <i className="bi bi-lock-fill" /> Жесткие шаблоны
            </p>
            <div className="d-flex">
              <button
                className={`btn btn-${
                  chooseLockTypeNameBD ? "light" : "primary"
                }`}
              >
                Без шаблона
              </button>
              {lockTypesArr.map((ltype) => (
                <button
                  className={`btn btn-${
                    chooseLockTypeNameBD === ltype.nameDB ? "primary" : "light"
                  }`}
                  onClick={() => setChooseLockTypeNameBD(ltype.nameDB)}
                >
                  {ltype.name}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
  // ) : (
  //   <div className="modal-body edit-field">
  //     <div className="row">
  //       <div className="col col-6">
  //         <p>Имя</p>
  //         <div className="input-group mb-2">
  //           <input
  //             className="form-control"
  //             type="text"
  //             value={newName}
  //             onChange={(e) => {
  //               setNewName(e.target.value);
  //             }}
  //           />
  //         </div>
  //       </div>
  //       <div className="col col-6">
  //         <p>Тип</p>
  //         <div class="form-check form-check__radio mb-2">
  //           <input
  //             class="form-check-input"
  //             type="radio"
  //             name="flexRadioDefault"
  //             id="flexRadioDefault1"
  //             checked={dataField.isMulti}
  //             onChange={(e) => {
  //               dataField.isMulti = e.target.checked ? true : false;
  //               setDataField({ ...dataField });
  //             }}
  //           />
  //           <label class="form-check-label" for="flexRadioDefault1">
  //             Повторяющийся компонент <br />
  //             Применимо для множественных вхождений (массивов) ингредиентов,
  //             мета-тегов и т.д.
  //           </label>
  //         </div>
  //         <div class="form-check form-check__radio">
  //           <input
  //             class="form-check-input"
  //             type="radio"
  //             name="flexRadioDefault"
  //             id="flexRadioDefault2"
  //             checked={!dataField.isMulti}
  //             onChange={(e) => {
  //               dataField.isMulti = e.target.checked ? false : true;
  //               setDataField({ ...dataField });
  //             }}
  //           />
  //           <label class="form-check-label" for="flexRadioDefault2">
  //             Одиночный компонент
  //             <br />
  //             Применимо для группировки полей, таких как полный адрес, основная
  //             информация и т.д.
  //           </label>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

const ModalEdit = ({
  open,
  setOpen,
  dispatch,
  data,
  dataField,
  path,
  setDataField,
  componentsSelect,
  schemasSelect,
  newName,
  setNewName,
  closePopUp,
  lockTypes,
}) => {
  const i = path[path.length - 1];
  console.log(`dataField`, dataField);
  const checkDataField = Object.keys(dataField).length;
  const newCard = data.name.length === 0;
  // const [newName, setNewName] = useState("");

  // const closePopUp = () => {
  //   setOpen(false);
  //   setDataField({});
  //   setNewName("");
  // };
  const [chooseLockTypeNameBD, setChooseLockTypeNameBD] = useState(null);

  return (
    open && (
      <div className={`modal  fade ${open && "show"}`} tabindex="-1">
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Modal title</h5>
              <button
                type="button"
                class="btn-close"
                onClick={closePopUp}
              ></button>
            </div>
            {newCard && (
              <StartPopup
                newName={newName}
                setNewName={setNewName}
                lockTypes={lockTypes}
                chooseLockTypeNameBD={chooseLockTypeNameBD}
                setChooseLockTypeNameBD={setChooseLockTypeNameBD}
              />
            )}
            {!newCard && checkDataField === 0 && (
              <Choose setDataField={setDataField} />
            )}
            {!newCard && checkDataField !== 0 && (
              <Edit
                dataField={dataField}
                setDataField={setDataField}
                componentsSelect={componentsSelect}
                schemasSelect={schemasSelect}
                data={data}
              />
            )}
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={closePopUp}
              >
                Отменить
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  if (newCard) {
                    if (chooseLockTypeNameBD) {
                      data = { ...lockTypes[chooseLockTypeNameBD] };
                    } else data.name = newName;
                  } else {
                    if (i === undefined) {
                      data.body.push(dataField);
                    } else {
                      data.body[i] = dataField;
                    }
                    setOpen(false);
                  }
                  dispatch({ data });
                }}
              >
                {newCard || Object.keys(dataField).length === 0
                  ? "Продолжить"
                  : i !== undefined
                  ? "Сохранить"
                  : "Добавить"}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ModalEdit;
