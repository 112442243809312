import { SERVER_URL } from "./constants";

/**
 * @typedef fetchProperty
 *
 * @property {string} [serverUrl]
 * @property {string} type
 * @property {string} [cmsSlug]
 * @property {string} [path]
 * @property {'POST'|'GET'|'DELETE'|'PUT'} method
 * @property {object} [data]
 * @property {string} [contentType]
 */

class Api {
  constructor() {
    // if (cmsData) this.cmsData = cmsData;

    if (window.location.pathname.includes("/front/")) {
      let path = window.location.pathname.split("/front/");
      path.shift();

      if (path.length) {
        path = path[0].split("/")[0];
      }

      this.cmsData.slug = path;
    }
  }

  /**
   * @type {{slug:string, name:string}} cmsData
   */
  cmsData = {
    slug: "",
  };

  /**
   *
   * @param {fetchProperty} param0
   * @returns
   */
  fetchConstructor({
    serverUrl = SERVER_URL,
    type,
    cmsSlug = this.cmsData.slug,
    path,
    method,
    data,
    contentType = "application/json",
  }) {
    const settings = {
      method: method,
      credentials: "include",
      headers: {
        "Content-Type": contentType,
      },
    };

    if (data) settings.body = JSON.stringify(data);
    return fetch(
      `${serverUrl}/api${cmsSlug ? "/" + cmsSlug : ""}/${type}${
        path ? "/" + path : ""
      }`,
      settings,
    ).then((res) => {
      let json = res.json();
      if (res.ok) {
        return json;
      }
      return json.then((err) => {
        throw err;
      });
    });
  }
  // regist(data) {
  //   return fetch(SERVER_URL + "/registration", {
  //     method: "POST",
  //     credentials: "include",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   }).then((res) => {
  //     return res.json();
  //   });
  // }
  //?Конструктор
  postConstr(data) {
    // return fetch(SERVER_URL + "/schema/collection", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    // return this.fetchConstructor(
    //   SERVER_URL,
    //   "/schema/",
    //   +"/schema/collection",
    //   "POST",
    //   data
    // );
    return this.fetchConstructor({
      type: "schema",
      path: "collection",
      method: "POST",
      data,
    });
  }

  getLockTypes() {
    // return fetch(SERVER_URL + "/schema/collection", {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    // return this.fetchConstructor(SERVER_URL + "/schema/collection", "GET");
    return this.fetchConstructor({
      type: "schema",
      path: "lock-types",
      method: "GET",
    });
  }

  postConstrOrder(data) {
    // return fetch(SERVER_URL + "/schema-order/collection", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });
    // return this.fetchConstructor(
    //   SERVER_URL + "/schema-order/collection",
    //   "POST",
    //   data
    // );
    return this.fetchConstructor({
      type: "schema-order",
      path: "collection",
      method: "POST",
      data,
    });
  }
  getConstr() {
    // return fetch(SERVER_URL + "/schema/collection", {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    // return this.fetchConstructor(SERVER_URL + "/schema/collection", "GET");
    return this.fetchConstructor({
      type: "schema",
      path: "collection",
      method: "GET",
    });
  }
  getSchema(name) {
    // return fetch(SERVER_URL + "/schema/collection/" + name, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    // return this.fetchConstructor(
    //   SERVER_URL + "/schema/collection/" + name,
    //   "GET"
    // );
    return this.fetchConstructor({
      type: "schema",
      path: "collection/" + name,
      method: "GET",
    });
  }
  deleteSchema(id) {
    // return fetch(SERVER_URL + "/schema/collection/" + id, {
    //   method: "DELETE",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    // return this.fetchConstructor(
    //   SERVER_URL + "/schema/collection/" + id,
    //   "DELETE"
    // );
    return this.fetchConstructor({
      type: "schema",
      path: "collection/" + id,
      method: "DELETE",
    });
  }
  //?Страница
  postSinglePage(data) {
    // return fetch(SERVER_URL + "/schema/pages", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });
    // return this.fetchConstructor(SERVER_URL + "/schema/pages", "POST", data);
    return this.fetchConstructor({
      type: "schema",
      path: "pages",
      method: "POST",
      data,
    });
  }
  postSinglePageOrder(data) {
    // return fetch(SERVER_URL + "/schema-order/pages", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "schema-order",
      path: "pages",
      method: "POST",
      data,
    });
  }
  getSinglePages() {
    // return fetch(SERVER_URL + "/schema/pages", {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "schema",
      path: "pages",
      method: "GET",
    });
  }
  getSinglePage(name) {
    // return fetch(SERVER_URL + "/schema/pages/" + name, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "schema",
      path: "pages/" + name,
      method: "GET",
    });
  }
  deleteSinglePage(id) {
    // return fetch(SERVER_URL + "/schema/pages/" + id, {
    //   method: "DELETE",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "schema",
      path: "pages/" + id,
      method: "DELETE",
    });
  }

  //?Компоненты
  getComponents() {
    // return fetch(SERVER_URL + "/schema/component", {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "schema",
      path: "component",
      method: "GET",
    });
  }

  getComponent(name) {
    // return fetch(SERVER_URL + "/schema/component/" + name, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "schema",
      path: "component/" + name,
      method: "GET",
    });
  }
  deleteComponent(id) {
    // return fetch(SERVER_URL + "/schema/component/" + id, {
    //   method: "DELETE",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    // return this.fetchConstructor(
    //   SERVER_URL + "/schema/component/" + id,
    //   "DELETE"
    // );
    return this.fetchConstructor({
      type: "schema",
      path: "component/" + id,
      method: "DELETE",
    });
  }

  postComponents(data) {
    // return fetch(SERVER_URL + "/schema/component", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "schema",
      path: "component/",
      method: "POST",
      data,
    });
  }
  //?Данные экземпляра модели
  getData({ typeModel, model, id, type, page }) {
    // return fetch(
    //   `${SERVER_URL}/data/${typeModel}/${model}${
    //     id
    //       ? "/" + id
    //       : `${type ? "?select=" + type : page ? `?page=${page}` : ""}`
    //   }`,
    //   {
    //     method: "GET",
    //     credentials: "include",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // ).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "data",
      path: `${typeModel}/${model}${
        id
          ? "/" + id
          : `${type ? "?select=" + type : page ? `?page=${page}` : ""}`
      }`,
      method: "GET",
    });
  }
  getDatas({ typeModel, model, type, pagin }) {
    // return fetch(
    //   `${SERVER_URL}/filter/${typeModel}/${model}${
    //     type ? "?select=" + type : ""
    //   }`,
    //   {
    //     method: "POST",
    //     credentials: "include",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ pagin }),
    //   }
    // ).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "filter",
      path: `${typeModel}/${model}${type ? "?select=" + type : ""}`,
      method: "POST",
      data: { pagin },
    });
  }
  deleteDatas({ typeModel, model, data }) {
    // return fetch(`${SERVER_URL}/data/${typeModel}/${model}`, {
    //   method: "DELETE",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "data",
      path: `${typeModel}/${model}`,
      method: "DELETE",
      data,
    });
  }
  postDatas(typeModel, model, data) {
    // return fetch(SERVER_URL + `/data/${typeModel}/` + model, {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "data",
      path: `${typeModel}/${model}`,
      method: "POST",
      data,
    });
  }
  putDatas(typeModel, model, data) {
    // return fetch(SERVER_URL + `/data/${typeModel}/` + model, {
    //   method: "PUT",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "data",
      path: `${typeModel}/${model}`,
      method: "PUT",
      data,
    });
  }
  filterDatas({ typeModel, model, data, type, pagin }) {
    // return fetch(
    //   `${SERVER_URL}/filter/${typeModel}/${model}${
    //     type ? "?select=" + type : ""
    //   }`,
    //   {
    //     method: "POST",
    //     credentials: "include",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(data),
    //   }
    // ).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "filter",
      path: `${typeModel}/${model}${type ? "?select=" + type : ""}`,
      method: "POST",
      data,
    });
  }

  getCountDatas({ typeModel, model }) {
    //fetch(SERVER_URL + `/data/${typeModel}/${model}/count`
    return this.fetchConstructor({
      type: "count",
      path: `${typeModel}/${model}`,
      method: "GET",
    });
  }

  //?Медиа
  getMediaCount(filter) {
    // return fetch(SERVER_URL + "/media/count", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(filter),
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "media",
      path: `count`,
      method: "POST",
      data: filter,
    });
  }
  getMedia(filter) {
    // return fetch(SERVER_URL + "/get-media", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(filter),
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "get-media",

      method: "POST",
      data: filter,
    });
  }
  updMedia(data) {
    // return fetch(SERVER_URL + "/media", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "media",
      method: "POST",
      data,
    });
  }
  postAltMedia(data) {
    // return fetch(SERVER_URL + "/media-alt", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "media-alt",
      method: "POST",
      data,
    });
  }

  deleteMediaBatch(data) {
    // return fetch(SERVER_URL + "/media-alt", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "media",
      path: "batch",
      method: "DELETE",
      data,
    });
  }

  checkUsedMedia(data) {
    // return fetch(SERVER_URL + "/media-alt", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "check-media",
      method: "POST",
      data,
    });
  }
  //?Настройки
  getSettingsSelect(select) {
    // return fetch(SERVER_URL + "/settings/" + select, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });
    return this.fetchConstructor({
      type: "settings",
      path: select,
      method: "GET",
    });
  }
  postSettings(data) {
    // return fetch(SERVER_URL + "/settings", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "settings",

      method: "POST",
      data,
    });
  }

  //? шаблоны
  getTemplate(name) {
    // return fetch(SERVER_URL + "/settings/" + select, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });
    return this.fetchConstructor({
      type: "templates",
      path: name,
      method: "GET",
    });
  }
  postTemplate(name, data) {
    // return fetch(SERVER_URL + "/settings/" + select, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });
    return this.fetchConstructor({
      type: "templates",
      path: name,
      method: "POST",
      data: data,
    });
  }

  putTemplate(name, data) {
    // return fetch(SERVER_URL + "/settings/" + select, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });
    return this.fetchConstructor({
      type: "templates",
      path: name,
      method: "PUT",
      data: data,
    });
  }

  deleteTemplate(name, data) {
    // return fetch(SERVER_URL + "/settings/" + select, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });
    return this.fetchConstructor({
      type: "templates",
      path: name,
      method: "DELETE",
      data: data,
    });
  }

  //?Роли
  getRoles(settings) {
    // return fetch(SERVER_URL + "/roles", {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: `roles${settings ? "?" + settings : ""}`,

      method: "GET",
    });
  }
  getRolesCount() {
    // return fetch(SERVER_URL + "/roles", {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: `roles`,
      path: "count",
      method: "GET",
    });
  }
  getRole(slug) {
    // return fetch(SERVER_URL + "/role/" + slug, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "role",
      path: slug,
      method: "GET",
    });
  }
  getRolesActive() {
    // return fetch(SERVER_URL + "/roles-act", {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "roles-act",
      method: "GET",
    });
  }
  deleteRole(slug) {
    // return fetch(SERVER_URL + "/role/" + slug, {
    //   method: "DELETE",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });
    return this.fetchConstructor({
      type: "role",
      path: slug,
      method: "DELETE",
    });
  }
  postRole(data) {
    // return fetch(SERVER_URL + "/role", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "role",
      method: "POST",
      data,
    });
  }
  //?настройки REST
  getSettingsRests() {
    // return fetch(SERVER_URL + "/settings-rests", {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "settings-rests",
      method: "GET",
    });
  }
  getSettingsRest(slug) {
    // return fetch(SERVER_URL + "/settings-rest/" + slug, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "settings-rest",
      path: slug,
      method: "GET",
    });
  }
  getSettingsRestsActive() {
    // return fetch(SERVER_URL + "/settings-rests-act", {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "settings-rests-act",

      method: "GET",
    });
  }
  deleteSettingsRest(slug) {
    // return fetch(SERVER_URL + "/settings-rest/" + slug, {
    //   method: "DELETE",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "settings-rest",
      path: slug,
      method: "DELETE",
    });
  }
  postSettingsRest(data) {
    // return fetch(SERVER_URL + "/settings-rest", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "settings-rest",

      method: "POST",
      data,
    });
  }
  //?настройки CMS
  getInfoCMSs(settings) {
    // return fetch(SERVER_URL + "/info-cms", {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: `info-cms${settings ? "?" + settings : ""}`,
      method: "GET",
    });
  }
  getInfoCMSCount() {
    // return fetch(SERVER_URL + "/info-cms", {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: `info-cms`,
      path: "count",
      method: "GET",
    });
  }
  getInfoCMS(slug) {
    // return fetch(SERVER_URL + "/info-cms/" + slug, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "info-cms",
      path: slug,
      method: "GET",
      // cmsSlug: slug,
    });
  }

  getInfoCMSCollections(ids) {
    return this.fetchConstructor({
      type: "info-cms-coll",
      method: "POST",
      data: ids,
    });
  }

  deleteInfoCMS(slug) {
    // return fetch(SERVER_URL + "/info-cms/" + slug, {
    //   method: "DELETE",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });
    return this.fetchConstructor({
      type: "info-cms",
      path: slug,
      method: "DELETE",
    });
  }
  postInfoCMS(data) {
    // return fetch(SERVER_URL + "/info-cms", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "info-cms",

      method: "POST",
      data,
    });
  }
  //?Пользователи
  getUsers() {
    // return fetch(SERVER_URL + "/users", {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "users",

      method: "GET",
    });
  }
  getUser(slug) {
    // return fetch(SERVER_URL + "/user/" + slug, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "user",
      path: slug,
      method: "GET",
    });
  }

  deleteUser(slug) {
    // return fetch(SERVER_URL + "/user/" + slug, {
    //   method: "DELETE",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "user",
      path: slug,
      method: "DELETE",
    });
  }
  postUser(data) {
    // return fetch(SERVER_URL + "/user", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "user",

      method: "POST",
      data,
    });
  }

  getUserData() {
    // return fetch(SERVER_URL + "/user-data", {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "user-data",
      cmsSlug: null,
      method: "GET",
    });
  }
  postProfile(data) {
    // return fetch(SERVER_URL + "/profile", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   console.log("res :>> ", res);
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "profile",

      method: "POST",

      data,
    });
  }
  //?Login
  login(data) {
    // return fetch(SERVER_URL + "/login", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "login",
      cmsSlug: false,
      method: "POST",

      data,
    });
  }

  //?Автоматизации
  postInForModels(typeModel, model, data) {
    // return fetch(SERVER_URL + `/automat/infor-model/${typeModel}/` + model, {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "automat/infor-model",
      path: `${typeModel}/${model}`,
      method: "POST",

      data,
    });
  }

  deleteInForModels(typeModel, model, data) {
    // return fetch(SERVER_URL + `/automat/infor-model/${typeModel}/` + model, {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "automat/infor-model",
      path: `${typeModel}/${model}`,
      method: "DELETE",

      data,
    });
  }

  getWebhookSync(typeModel, model) {
    // return fetch(SERVER_URL + `/automat/webhook/${typeModel}/` + model, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "automat/webhook",
      path: `${typeModel}/${model}`,
      method: "GET",
    });
  }

  getCSVFromLink() {
    // return fetch(SERVER_URL + "/export-csv", {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   if (res.ok) {
    //     return res.json();
    //   }
    //   return Promise.reject(res);
    // });

    return this.fetchConstructor({
      type: "export-csv",
      method: "GET",
    });
  }
}

const api = new Api();

export default api;
