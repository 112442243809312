import React, { useState, useEffect } from "react";

const DatePicker = ({
  body,
  activeIndex,
  rowLength,
  dataObjInside,
  dispatch,
  changeData,
  data,
  path,
}) => {
  const [day, setDay] = useState(0);
  const [time, setTime] = useState(0);
  const [date, setDate] = useState(
    dataObjInside[body[activeIndex].nameDB] &&
      typeof dataObjInside[body[activeIndex].nameDB] === "string"
      ? dataObjInside[body[activeIndex].nameDB].split("T")
      : ["", ""]
  );
  const [timeValue, setTimeValue] = useState(
    date[1] ? date[1].split(":") : ["", ""]
  );

  console.log("timeValue :>> ", timeValue);
  console.log("time :>> ", time);
  console.log("date :>> ", date);
  console.log("day :>> ", day);
  console.log("day+time :>> ", day + time);

  useEffect(() => {
    let day = 0;
    let time = 0;
    const date =
      dataObjInside[body[activeIndex].nameDB] &&
      typeof dataObjInside[body[activeIndex].nameDB] === "string"
        ? dataObjInside[body[activeIndex].nameDB].split("T")
        : ["", ""];

    if (Date.parse(date[0])) {
      day = Date.parse(date[0]);
    } else if (dataObjInside[body[activeIndex].nameDB]) {
      let newDate = new Date(dataObjInside[body[activeIndex].nameDB]);
      console.log("newDate :>> ", newDate);

      day = Date.parse(
        `${newDate.getDate()}.${newDate.getMonth()}.${newDate.getFullYear}`
      );
      time = Date.parse(newDate) - day;
    }

    const timeValue = date[1] ? date[1].split(":") : ["", ""];
    if (+timeValue[0]) time = +timeValue[0] * 60 * 60 * 1000;

    setDay(day);

    setTime(time);
    // setDate(date);
    // setTimeValue(timeValue);
  }, []);

  return (
    <div className={`col-${12 / rowLength}`} key={body[activeIndex].nameDB}>
      <h6>{body[activeIndex].name}</h6>
      <form
        value={dataObjInside[body[activeIndex].nameDB]}
        onChange={(e) => {
          // dispatch({
          //   data: changeData({
          //     data,
          //     path: [...path, el.nameDB],
          //     value: e.target.value,
          //   }),
          // });
        }}
        className="row mb-4"
      >
        <div className="col">
          <div class="input-group">
            <label class="input-group-text">Дата</label>
            <input
              onChange={(e) => {
                setDay(e.target.valueAsNumber);
                console.log("dis", 123);
                dispatch({
                  data: changeData({
                    data,
                    path: [...path, body[activeIndex].nameDB],
                    value: e.target.valueAsNumber + time,
                  }),
                });
              }}
              defaultValue={date[0]}
              className="form-control"
              type="date"
            />
          </div>
        </div>
        <div className="col">
          <div class="input-group">
            <label class="input-group-text">Время</label>
            <input
              onChange={(e) => {
                setTime(e.target.valueAsNumber);

                console.log("dis", 123);
                dispatch({
                  data: changeData({
                    data,
                    path: [...path, body[activeIndex].nameDB],
                    value: day + e.target.valueAsNumber,
                  }),
                });
              }}
              className="form-control"
              type="time"
              defaultValue={timeValue[0] + ":" + timeValue[1]}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default DatePicker;
