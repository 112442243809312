import React, { useState, useEffect } from "react";

/**
 *
 * @param {React.ProfilerProps&{open:boolean}} props
 * @returns
 */
const SidebarBox = (props) => {
  return (
    <div
      className={`modal2-collection ${props.open ? "show" : ""} ${
        props.className ?? ""
      }`}
    >
      {props.open ? props.children : null}
    </div>
  );
};

export default SidebarBox;
