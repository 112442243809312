import React, { useState, useEffect } from "react";
import api from "../../../../func/api";
import SelectAsycn from "react-select/async";
import SelectSync from "react-select";
import { errorCatch } from "../../../../func/common";
import { ReactSortable } from "react-sortablejs";
import { Link } from "react-router-dom";

const PLACEHOLDER = "Выберете нужное значение";
const PLACEHOLDER_MULTI = "Выберете несколько значений";
const PLACEHOLDER_ASYNC = "Введите значение";

const Ref = ({
  changeData,
  data,
  path,
  dataObjInside,
  bodyInsaid,
  namesRefColl,
  dataRefColl,
  setNamesRefColl,
  defaultsNames,
  dispatch,
}) => {
  const [input, setInput] = useState("");
  const [selectVars, setSelectVars] = useState([]);
  const [arr, setArr] = useState(
    bodyInsaid.isMulti && dataObjInside && dataObjInside[bodyInsaid.nameDB]
      ? dataObjInside[bodyInsaid.nameDB].map((el) => ({ value: el }))
      : []
  );

  let optionsRef = [];
  let cmsSlug = "";
  if (window.location.pathname.includes("/front/")) {
    let path = window.location.pathname.split("/front/");
    path.shift();

    if (path.length) {
      path = path[0].split("/")[0];
    }

    cmsSlug = path;
  }

  if (!namesRefColl.includes(bodyInsaid.refTo)) {
    setNamesRefColl([...namesRefColl, bodyInsaid.refTo]);
  }
  if (dataRefColl[bodyInsaid.refTo] && dataObjInside !== null)
    dataRefColl[bodyInsaid.refTo].forEach((option) => {
      if (!bodyInsaid.isMulti && dataObjInside[bodyInsaid.nameDB]) {
        return;
      }
      console.log(`option`, option);
      let key = Object.keys(option);

      key.splice(key.indexOf("_id"), 1);

      optionsRef.push({ label: option[key[0]], value: option["_id"] });
    });

  const convertDataToList = (data, defaultKey = "_id") => {
    return {
      label: defaultsNames[bodyInsaid.refTo]
        ? data[defaultsNames[bodyInsaid.refTo]]
        : data[defaultKey],
      value: data._id,
      _id: data._id,
      link: `/front/${cmsSlug}/collections/${bodyInsaid.refTo}/${data._id}`,
    };
  };

  useEffect(() => {
    if (path.length) {
      api
        .filterDatas({
          typeModel: "collections",
          model: bodyInsaid.refTo,
          type: "forSelect",
        })
        .then((result) => {
          if (result.length) {
            let key = Object.keys(result[0]);
            key.splice(key.indexOf("_id"), 1);
            const newData = result.map((el) => convertDataToList(el, key[0]));
            setSelectVars([...newData]);
          }
        })
        .catch((err) => {
          errorCatch(err);
        });
    }
  }, []);

  const getOptionLabel = (option) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "5px",
        }}
      >
        <p
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          {option.label}
        </p>

        <p
          style={{
            margin: 0,
            padding: 0,
            flexShrink: 1,
            flexGrow: 0,
          }}
        >
          <span
            style={{
              fontSize: "0.8rem",
              color: "gray",
              marginRight: "5px",
            }}
          >
            ...{option.value?.slice(-6)}
          </span>
          <a
            target="_blank"
            rel="noreferrer"
            href={option.link}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <i className="bi bi-box-arrow-up-right" />
          </a>
        </p>
      </div>
    );
  };

  let listik =
    bodyInsaid.isMulti && arr.length
      ? arr.map((val, i) => {
          let option = val.value;

          if (typeof option === "string") {
            option = selectVars.find((e) => e.value === option);
          }

          const convertedData = convertDataToList(option);

          return (
            <div
              className="cursor-drag d-flex border rounded p-1 align-items-center"
              style={{
                gap: "5px",
              }}
            >
              {getOptionLabel(convertedData)}
              <i
                onClick={() => {
                  arr.splice(i, 1);
                  setArr([...arr]);
                }}
                className="ms-2 bi bi-x-circle-fill text-primary pointer"
              />
            </div>
          );
        })
      : [];

  useEffect(() => {
    if (bodyInsaid.isMulti && dataObjInside) {
      dataObjInside[bodyInsaid.nameDB] = arr.map((e) => e.value);

      dispatch({ data });
    }
  }, [arr]);

  //   useEffect(() => {}, []);
  return (
    <>
      <h6>{bodyInsaid.name}</h6>
      <div className="mb-4 border-0">
        {path.length ? (
          <SelectSync
            className="mb-1"
            options={selectVars}
            placeholder={PLACEHOLDER}
            value={
              bodyInsaid.isMulti
                ? null
                : dataObjInside[bodyInsaid.nameDB]
                ? selectVars.filter(
                    (e) =>
                      e.value ===
                      (dataObjInside[bodyInsaid.nameDB]._id ??
                        dataObjInside[bodyInsaid.nameDB])
                  )
                : null
            }
            // styles={colourStyles}
            onChange={(e) => {
              const key = defaultsNames[bodyInsaid.refTo];
              const newValue = {
                _id: e.value,
              };
              if (key) newValue[key] = e.label;
              if (bodyInsaid.isMulti)
                setArr(
                  dataObjInside[bodyInsaid.nameDB]
                    ? [...arr, { value: newValue }]
                    : [{ value: newValue }]
                );
              else
                dispatch({
                  data: changeData({
                    data,
                    path: [...path, bodyInsaid.nameDB],
                    value: bodyInsaid.isMulti
                      ? dataObjInside[bodyInsaid.nameDB]
                        ? [...dataObjInside[bodyInsaid.nameDB], e.value]
                        : [e.value]
                      : e.value,
                  }),
                });
            }}
            getOptionLabel={getOptionLabel}
            filterOption={(option, input) => {
              const { data, label: selectLabel } = option;
              let label = selectLabel;

              if (typeof label !== "string") {
                label = data?.label;
              }

              return label.toLowerCase().includes(input.toLowerCase());
            }}
          />
        ) : (
          <>
            <SelectAsycn
              className="mb-1"
              cacheOptions
              placeholder={PLACEHOLDER_ASYNC}
              inputValue={input}
              onChange={(e) => {
                const newValue = {
                  _id: e.value,
                  [defaultsNames[bodyInsaid.refTo]]: e.label,
                };
                if (bodyInsaid.isMulti)
                  setArr(
                    dataObjInside[bodyInsaid.nameDB]
                      ? [...arr, { value: e.value }]
                      : [{ value: e.value }]
                  );
                else
                  dispatch({
                    data: changeData({
                      data,
                      path: [...path, bodyInsaid.nameDB],
                      value: bodyInsaid.isMulti
                        ? dataObjInside[bodyInsaid.nameDB]
                          ? [...dataObjInside[bodyInsaid.nameDB], newValue]
                          : [newValue]
                        : newValue,
                    }),
                  });
              }}
              onInputChange={(value, info) => {
                if (info.action === "input-change") setInput(value);
              }}
              loadOptions={(value, callback) => {
                if (value.length >= 3)
                  api
                    .filterDatas({
                      typeModel: "collections",
                      model: bodyInsaid.refTo,
                      data: { filter: { $text: { $search: value } } },
                      type: "forSelect",
                    })
                    .then((result) => {
                      const newData = result.map((el) => convertDataToList(el));
                      callback(newData);
                    })
                    .catch((err) => {
                      errorCatch(err);
                    });
              }}
              getOptionLabel={getOptionLabel}
            />
            {dataObjInside[bodyInsaid.nameDB] !== undefined &&
              dataObjInside[bodyInsaid.nameDB] !== null && (
                <label className="text-secondary fs-12">
                  Выбрано:{" "}
                  {/* <Link
                to={`/collections/${bodyInsaid.refTo}/${
                  dataObjInside[bodyInsaid.nameDB]._id
                }`}
              > */}
                  <a
                    href={`/front/${cmsSlug}/collections/${bodyInsaid.refTo}/${
                      dataObjInside[bodyInsaid.nameDB]?._id
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {defaultsNames[bodyInsaid.refTo] &&
                    dataObjInside[bodyInsaid.nameDB]
                      ? dataObjInside[bodyInsaid.nameDB][
                          defaultsNames[bodyInsaid.refTo]
                        ]
                      : dataObjInside[bodyInsaid.nameDB]?._id}
                  </a>
                  <i
                    onClick={() => {
                      dispatch({
                        data: changeData({
                          data,
                          path: [...path, bodyInsaid.nameDB],
                          value: null,
                        }),
                      });
                    }}
                    style={{
                      position: "relative",
                      top: "1px",
                    }}
                    className="bi bi-x-circle ms-2 text-danger pointer"
                  />
                  {/* </Link> */}
                </label>
              )}
          </>
        )}
        {bodyInsaid.isMulti ? (
          <ReactSortable
            list={arr}
            setList={setArr}
            // className="row"
            style={{ display: "flex", flexWrap: "wrap", gap: "7px" }}
            handle=".cursor-drag"
          >
            {listik}
          </ReactSortable>
        ) : // <div className="d-flex flex-wrap">{listik} </div>
        null}
      </div>
    </>
  );
};

export default Ref;
