import React, { useState, useEffect } from "react";
import { settingsFields } from "../../func/constants";
import { ReactSortable } from "react-sortablejs";
import Sortable, { Swap } from "sortablejs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Select from "react-select";
import { sortable } from "react-sortable";
import api from "../../func/api";

const grid = 15;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  // userSelect: "none",
  // margin: `0 0 ${grid}px 0`,
  width: "100%",
  userSelect: "none",
  //   padding: grid * 2,
  //   margin: `0 0 ${grid}px 0`,
  border: isDragging ? "1px solid #dee2e6" : "",
  //   display: "flex",
  // change background colour if dragging

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyleHorisontal = (isDraggingOver) => ({
  display: "flex",
  padding: grid,
  width: "100%",
});

const getListStyle = (isDraggingOver) => ({
  padding: grid,
  width: "100%",
});

let idCount = 0;

const createNew = ({ type, data }) => {
  if (type === "model") {
    return api.postConstr(data);
  } else if (type === "component") {
    return api.postComponents(data);
  } else {
    return api.postSinglePage(data);
  }
};

const Item = (props, ref) => {
  console.log(`props`, props);
  return (
    <div
      className={`col-${12 / this.props.colCount}`}
      {...this.props}
      style={{ background: this.props.children }}
    >
      {this.props.children}
    </div>
  );
};

const colsNormolizate = ({ data, active }) => {
  // let activeIndex = 0;
  // data.cols.forEach((row, rowIndex) => {
  //   row.forEach((col, colIndex) => {
  //     if (!col) {
  //       active.splice(activeIndex, 0, { empty: true });
  //     }

  //     activeIndex += 1;
  //   });
  // });

  // if (activeIndex < active.length) {
  //   for (let index = activeIndex; index < active.length; index++) {
  //     data.cols.push([1]);
  //   }
  // }
  let activeIndex = 0;
  // data.viewParams?.tabs.forEach((tab) => {
  //   tab.cols.forEach((row, rowIndex) => {
  //     row.forEach((col, colIndex) => {
  //       if (!col) {
  //         active.splice(activeIndex, 0, { empty: true });
  //       }

  //       activeIndex += 1;
  //     });
  //   });
  // });
  // data.cols.forEach((row, rowIndex) => {
  //   row.forEach((col, colIndex) => {
  //     if (!col) {
  //       active.splice(activeIndex, 0, { empty: true });
  //     }

  //     activeIndex += 1;
  //   });
  // });

  if (activeIndex < active.length) {
    for (let index = activeIndex; index < active.length; index++) {
      data.cols.push([1]);
    }
  }
};

Sortable.mount(new Swap());

function Order({ data, dispatch, type }) {
  console.log(`data`, data);

  const onSortItems = (e) => {
    console.log(`e`, e);

    let activeIndex = 0;
    data.viewParams.forEach((block, blockI) => {
      block.tabs.forEach((tab, tabI) => {
        tab.cols.forEach((row, rowIndex) => {
          row.forEach((col, colIndex) => {
            if (col === 1 && e[activeIndex].empty) {
              data.viewParams[blockI].tabs[tabI].cols[rowIndex][colIndex] = {
                type: "empty",
              };
            } else if (col === 0 && !e[activeIndex].empty) {
              data.viewParams[blockI].tabs[tabI].cols[rowIndex][colIndex] = {
                type: "elem",
                index: activeIndex,
              };
            }

            activeIndex += 1;
          });
        });
      });
    });

    // data.cols.forEach((row, rowIndex) => {
    //   row.forEach((col, colIndex) => {
    //     if (col === 1 && e[activeIndex].empty) {
    //       data.cols[rowIndex][colIndex] = 0;
    //     } else if (col === 0 && !e[activeIndex].empty) {
    //       data.cols[rowIndex][colIndex] = 1;
    //     }
    //     activeIndex += 1;
    //   });
    // });
    // data.body = [...e, ...deactive];

    dispatch({ data });

    setActive([...e]);
  };

  const [active, setActive] = useState([]);
  const [deactive, setDeactive] = useState([]);
  const [optionsSelect, setOptionsSelect] = useState([]);
  const [defaultField, setDefaultField] = useState({});
  // const [blocksCols, setBlockCols] = useState([])
  console.log("defaultField :>> ", defaultField);
  useEffect(() => {
    data.body.forEach((el, i) => {
      if (el.visible) active.push({ ...el, posInBody: i });
      else deactive.push({ ...el, posInBody: i });
      optionsSelect.push({ value: el.nameDB, label: el.name });
      if (data.defaultField === el.nameDB)
        setDefaultField({ value: el.nameDB, label: el.name });
    });
    setOptionsSelect([...optionsSelect]);

    if (data.viewParams === undefined) {
      data.viewParams = [];
    }

    if (data.viewParams.length === 0) {
      const viewParamsElem = {
        tabs: [
          {
            cols: [],
          },
        ],
      };

      let i = 0;

      while (i < active.length) {
        viewParamsElem.tabs[0].cols.push([
          { indexInData: i, type: "elem", id: idCount },
        ]);
        idCount++;
        i += 1;
      }
      data.viewParams.push(viewParamsElem);
      dispatch({ data });
    } else {
      // let activeIndex = 0;
      // data.viewParams.tabs.cols.forEach((row, rowIndex) => {
      //   row.forEach((col, colIndex) => {
      //     if (!col) {
      //       active.splice(activeIndex, 0, { empty: true });
      //     }
      //     activeIndex += 1;
      //   });
      // });
      colsNormolizate({ data, active });
      dispatch({ data });
    }

    // if (data.cols.length === 0) {
    //   let i = 0;

    //   while (i < active.length) {
    //     data.cols.push([1]);
    //     i += 1;
    //   }
    //   dispatch({ data });
    // } else {
    //   // let activeIndex = 0;
    //   // data.cols.forEach((row, rowIndex) => {
    //   //   row.forEach((col, colIndex) => {
    //   //     if (!col) {
    //   //       active.splice(activeIndex, 0, { empty: true });
    //   //     }

    //   //     activeIndex += 1;
    //   //   });
    //   // });

    //   // if (activeIndex < active.length) {
    //   //   for (let index = activeIndex; index < active.length; index++) {
    //   //     data.cols.push([1]);
    //   //   }
    //   // }
    //   colsNormolizate({ data, active });
    // }
    setActive([...active]);
    setDeactive([...deactive]);
  }, []);
  console.log("active :>> ", active);
  console.log("data.viewParams :>> ", data.viewParams);
  let tabsHTML = [];
  let indexData = 0;
  let index = 0;
  if (active.length) {
    tabsHTML = data.viewParams.map((block, blockI) => {
      const tabsHTML = block.tabs.map((tab, tabI) => {
        const rowHTML = tab.cols.map((colCount, rowI) => {
          const colsNew = [];

          const colCountLength = colCount.length;
          const colHTML = [];
          colCount.forEach((col, colI) => {
            let indexDataInMap = indexData;
            console.log("indexData :>> ", indexData);
            if (col.type === "elem") {
              colsNew.push(
                <Draggable
                  draggableId={
                    active[indexDataInMap].nameDB + "_" + indexDataInMap
                  }
                  index={index}
                  className={`col-${12 / colCountLength}`}
                  key={active[indexDataInMap].nameDB}
                  type="rows"
                >
                  {(provided, snapshot) => (
                    <div className="d-flex position-relative align-items-center dashed ps-3 pe-3 drag-item">
                      <div
                        class="block-group d-flex w-100"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <span className="block-group-left pointer elem-hand">
                          <i className="bi bi-arrows-move" />
                        </span>
                        <span className="block-group-name d-flex align-items-center p-2">
                          {
                            settingsFields[active[indexDataInMap].typeField]
                              .badge
                          }
                          {active[indexDataInMap].name}
                        </span>
                        <span className="block-group-right ms-auto pointer">
                          <i className="bi bi-x" />
                        </span>
                      </div>
                      {/* {colI === colCountLength - 1 ? (
                        <span
                          className="block-group-btn position-absolute end-0 pointer"
                          onClick={() => {
                            data.viewParams[blockI].tabs[tabI].cols[rowI].push({
                              type: "empty",
                              indexInData: indexDataInMap + 1,
                              id: idCount,
                            });
                            idCount++;
                            // active.splice(indexDataInMap + 1, 0, {
                            //   empty: true,
                            // });
                            dispatch({ data });
                            // setActive([...active]);
                          }}
                        >
                          <i className="bi bi-plus text-primary" />
                        </span>
                      ) : (
                        <span
                          className="block-group-btn position-absolute end-0 pointer"
                          onClick={() => {
                            data.viewParams[blockI].tabs[tabI].cols[
                              rowI
                            ].splice(rowI, 1);
                            active.splice(indexDataInMap, 1);
                            dispatch({ data });
                            setActive([...active]);
                          }}
                        >
                          <i className="bi bi-dash text-danger" />
                        </span>
                      )} */}
                    </div>
                  )}
                </Draggable>
                // </ItemSortable>
              );
              indexData += 1;
            } else
              colsNew.push(
                // <ItemSortable
                //   key={indexData}
                //   onSortItems={onSortItems}
                //   items={active}
                //   sortId={indexData}
                //   colCount={colCountLength}
                // >
                <Draggable
                  draggableId={String(colI) + String(rowI)}
                  index={colI}
                  className={`col-${12 / colCount.length}`}
                  key={String(colI) + String(rowI)}
                  type="rows"
                >
                  <div className="d-flex position-relative align-items-center dashed ps-3 pe-3 drag-item">
                    <span
                      className="block-group-btn position-absolute end-0 pointer"
                      onClick={() => {
                        if (colCountLength === 1) {
                          data.viewParams[blockI].tabs[tabI].cols.splice(
                            rowI,
                            1
                          );
                        } else {
                          data.viewParams[blockI].tabs[tabI].cols[rowI].splice(
                            rowI,
                            1
                          );
                        }

                        active.splice(indexDataInMap, 1);
                        dispatch({ data });
                        setActive([...active]);
                      }}
                    >
                      <i className="bi bi-dash text-danger" />
                    </span>
                  </div>
                </Draggable>
                // {/* </ItemSortable> */}
              );
            index += 1;
          });
          console.log("colsNew :>> ", colsNew);
          return colsNew;
          // <Droppable
          //   droppableId={"droppable_" + rowI}
          //   direction="horizontal"
          //   type="cols"
          //   //   list={active}
          //   //   className="row"
          //   //   swapClass="highlighted"
          //   //   id={blockI + "_" + rowI}
          //   //   swap={true}
          //   //   setList={(newActive) => {
          //   //     // setActive([...newActive]);
          //   //   }}
          //   //   onEnd={(evt, t, a) => {}}
          //   //   onUpdate={(e) => {
          //   //     console.log(`e`, e);
          //   //   }}
          //   //   group="shared"
          //   //   swapThreshold={0.65}
          //   //   handle=".elem-hand"
          //   //   // draggable=".drag-item"
          // >
          //   {(provided, snapshot) => (
          //     <div
          //       ref={provided.innerRef}
          //       style={getListStyleHorisontal(snapshot.isDraggingOver)}
          //     >
          //       {colsNew}
          //     </div>
          //   )}
          // </Droppable>
        });
        return rowHTML;
      });
      // setSortData({ ...sortData });
      // console.log(`sortData`, sortData);
      return (
        <div className="card-body bg-gray">
          <div className="d-flex justify-content-end align-items-center mb-3">
            <i className="bi bi-list me-2 block-hand" />
            <i className="bi bi-trash-fill" />
          </div>
          <div className="border p-3 bg-white">
            <div className="mb-3">
              <Droppable
                droppableId="droppable3"
                type="rows"
                //   list={active}
                //   className="row"
                //   swapClass="highlighted"
                //   id={blockI + "_" + rowI}
                //   swap={true}
                //   setList={(newActive) => {
                //     // setActive([...newActive]);
                //   }}
                //   onEnd={(evt, t, a) => {}}
                //   onUpdate={(e) => {
                //     console.log(`e`, e);
                //   }}
                //   group="shared"
                //   swapThreshold={0.65}
                //   handle=".elem-hand"
                //   // draggable=".drag-item"
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className="row"
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {tabsHTML}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              {/* <div ></div> */}
            </div>
            <div className="row">
              <div className="col-6 position-relative">
                <button
                  className="btn btn-outline-primary w-100"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="bi bi-caret-down me-2" />
                  Восстановить поле
                </button>
                <ul
                  class="dropdown-menu shadow w-100"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {deactive.map((el, i) => (
                    <li
                      key={el.name}
                      onClick={() => {
                        data.body[el.posInBody].visible = true;
                        active.push(el);
                        deactive.splice(i, 1);
                        setActive([...active]);
                        setDeactive([...deactive]);
                      }}
                    >
                      <span className=" ps-2  dropdown-item block-group-name d-flex align-items-center p-2">
                        <span class="badge block-group-badge img-badge me-2">
                          <i class="bi bi-image" />
                        </span>
                        {el.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    data.cols.push([0]);
                    active.push({ empty: true });
                    setActive([...active]);
                    dispatch({ data });
                  }}
                >
                  <i className="bi bi-plus me-2" />
                  Новый ряд
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  console.log("tabsHTML :>> ", tabsHTML);

  return (
    <div className="container-fuild">
      <div className="row">
        <div className="col ">
          <div className="d-flex justify-content-between align-items-center mb-3 mt-4 p-3">
            <div>
              <h5 className="">Настройка представления - Product</h5>
            </div>
            <div>
              <button className="btn btn-outline-danger">Отменить</button>
              <button
                className="btn btn-primary ms-3"
                onClick={() => {
                  createNew({ type, data })
                    .then((newSchema) => {
                      console.log(`newSchema`, newSchema);
                      dispatch({ data: newSchema });
                    })
                    .catch((err) => {
                      console.log(`err`, err);
                    });
                }}
              >
                Сохранить
              </button>
            </div>
          </div>

          <div className="card mb-5 m-3">
            <div className="m-2 border-bottom row">
              <div className="col">
                <h6 className="mb-4">Настройки</h6>
                <p className="mb-1">Заголовок записи</p>
                <Select
                  className="mb-2"
                  options={optionsSelect}
                  placeholder="Выберете нужное значение"
                  value={defaultField}
                  // styles={colourStyles}
                  onChange={(e) => {
                    data.defaultField = e.value;
                    setDefaultField(e);
                    dispatch({
                      data,
                    });
                  }}
                />
                <p className="text-secondary text_small">
                  Установите отображаемое поле вашей записи
                </p>
              </div>
              <div className="col">
                <p className="m-0">Связанные поля</p>
                <p className="text-secondary text_small mb-4">
                  Перетащите поля, что бы изменить макет
                </p>
                <div className="card p-2 mb-4">
                  <div class="block-group d-flex mb-2">
                    <span className="block-group-left pointer">
                      <i className="bi bi-arrows-move" />
                    </span>
                    <span className="block-group-name p-2">Name</span>
                    <span className="block-group-right ms-auto pointer">
                      <i className="bi bi-x" />
                    </span>
                  </div>
                  <button className="btn btn-outline-primary">
                    + Новое связанное поле
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="m-4">
              <h6 className="mb-4">Вид</h6>
              <p className="m-0">Отображение полей</p>
              <p className="text-secondary text_small">
                Перетащите поля, что бы изменить макет
              </p>
              <div className="card">
                <DragDropContext
                  onDragEnd={(e, b, d) => {
                    console.log(e, b, d);
                  }}
                  onDragUpdate={(e, b, d) => {
                    console.log(e, b, d);
                  }}
                >
                  {tabsHTML}
                </DragDropContext>
              </div>
              <button
                className="w-100 btn btn-primary mt-3"
                onClick={() => {
                  active.push({ empty: true });
                  data.viewParams.push({
                    typeView: "block",
                    tabs: [
                      { cols: [[{ type: "empty", index: active.length - 1 }]] },
                    ],
                  });

                  setActive([...active]);
                  dispatch({ data });
                }}
              >
                Новый блок
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Order;
