import React, { useRef, useState } from "react";
import { SERVER_URL } from "../../func/constants";
import $ from "jquery";
import { copyToBuffer } from "../../func/common";
import api from "../../func/api";
import { ReactSortable } from "react-sortablejs";

const ManyMedia = ({
  pics = [],
  name,
  getData,
  openChooseMedia,
  setEditMedia,
  onChangeArray,
}) => {
  const [showURL, setShowURL] = useState(false);

  const deletePic = (i) => {
    pics.splice(i, 1);
    onChangeArray(pics);
  };
  console.log(`pics`, pics);

  const picsHTML = pics?.map((pic, i) => {
    return (
      <ManyMediaElement
        pic={pic}
        i={i}
        setEditMedia={setEditMedia}
        key={pic.hash + i}
        deletePic={deletePic}
      />
    );
  });

  return (
    <div className="card-body">
      <h5>{name}</h5>

      <ReactSortable
        animation={200}
        delayOnTouchStart={true}
        delay={2}
        list={pics}
        setList={(newOrderArr) => {
          console.log(`newOrderArr`, newOrderArr);
          //   setOrderMenu(newOrderArr);
          onChangeArray(newOrderArr);
        }}
        group="shared"
        className="row"
        swapThreshold={0.65}
        handle=".bi-list"
      >
        {picsHTML}
      </ReactSortable>

      {showURL ? (
        <div class="input-group mt-3 mb-3">
          <input type="text" class="form-control" />
          <span class="input-group-text pointer">Upload</span>
          <span
            class="input-group-text pointer"
            onClick={() => {
              setShowURL(false);
            }}
          >
            <i className="bi bi-x" />
          </span>
        </div>
      ) : (
        <div className="input-group mt-3 mb-3">
          <button
            type="button"
            class="btn border bg-btn w-95 text-start"
            onClick={(e) => {
              $(e.target).find('input[type="file"]').click();
            }}
          >
            Загрузить
            <input
              type="file"
              name="uploadImg"
              accept="image/jpeg,image/png,image/svg+xml"
              onInput={(e) => {
                if (e.target.files.length) {
                  let cmsSlug = null;
                  if (window.location.pathname.includes("/front/")) {
                    let path = window.location.pathname.split("/front/");
                    path.shift();

                    if (path.length) {
                      path = path[0].split("/")[0];
                    }

                    cmsSlug = path;
                  }
                  if (!cmsSlug) return;
                  const formData = new FormData();

                  formData.append("uploadImg", e.target.files[0]);
                  let xhr = new XMLHttpRequest();

                  xhr.onloadend = () => {
                    if (xhr.status == 200) {
                      console.log("Успех");
                      getData(xhr.response);
                    } else {
                      console.log("Ошибка " + this.status);
                    }
                  };

                  xhr.open(
                    "POST",
                    SERVER_URL + "/" + cmsSlug + "/upload-media"
                  );
                  xhr.send(formData);
                }
              }}
            />
          </button>
          <div className="dropdown">
            <button
              type="button"
              class="btn border bg-btn dropdown-toggle"
              id="dropdownUploadMenu"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="bi bi-caret-down" />
            </button>
            <ul
              class="dropdown-menu mt-2"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <a
                  class="dropdown-item"
                  type="button"
                  onClick={() => {
                    setShowURL(true);
                  }}
                >
                  URL
                </a>
              </li>

              {/* <li>
                <a
                  class="dropdown-item"
                  type="button"
                  onClick={openChooseMedia}
                >
                  Media
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

const ManyMediaElement = ({ pic, i, setEditMedia, deletePic }) => {
  const imgLink = `${SERVER_URL + "/" + pic.url}`;
  const linkImg = useRef(null);
  const btnClickEffect = (e) => {
    e.target.classList.add("text-success");
    setTimeout(() => {
      e.target.classList.remove("text-success");
    }, 2000);
  };
  return (
    <div className="col-4">
      <div className="card rounded p-3 bg-gray mb-2 border-0">
        <div
          className="rounded bg-white p-3"
          style={{
            backgroundImage: "url(" + imgLink + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          <div className="d-flex flex-column align-items-end">
            <div
              className="icon-badge bg-white rounded text-center mb-1 pointer"
              onClick={() => {
                deletePic(i);
              }}
            >
              <i className="bi bi-trash-fill text-danger" />
            </div>
            <div
              className="icon-badge bg-white rounded text-center mb-1 pointer"
              onClick={() => {
                setEditMedia(pic, i);
              }}
            >
              <i className="bi bi-pencil-fill" />
            </div>
            <div
              className="icon-badge bg-white rounded text-center mb-1 pointer"
              onClick={(e) => {
                copyToBuffer(imgLink);
                btnClickEffect(e);
              }}
            >
              <i className="bi bi-link-45deg" />
            </div>
            <div
              className="icon-badge bg-white rounded text-center mb-1 pointer"
              onClick={() => {
                linkImg.current.click();
                // var a = document.createElement("a");
                // a.setAttribute("href", imgLink);
                // a.setAttribute("download", true);
                // a.click();
              }}
            >
              <i className="bi bi-box-arrow-in-down" />
              <a
                href={imgLink}
                download
                style={{ opacity: 0, visibility: "hidden" }}
                ref={linkImg}
              ></a>
            </div>
          </div>
        </div>
        <div className="d-flex border rounded progbar mt-2">
          <div className="rounded-start border w-50 bg-primary"></div>
        </div>
        <div class="input-group mt-2">
          <span class="input-group-text">Alt</span>
          <input
            type="text"
            class="form-control"
            defaultValue={pic.alt}
            onChange={(e) => {
              pic.alt = e.target.value;
            }}
            onBlur={() => {
              api
                .postAltMedia({ _id: pic._id, alt: pic.alt })
                .then((result) => {})
                .catch((err) => {});
            }}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center mt-3">
          <span className="text-secondary me-4">{i + 1}</span>
          <i className="bi bi-list" />
        </div>
      </div>
    </div>
  );
};

export default ManyMedia;
