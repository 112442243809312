import React, { useState, useEffect } from "react";
import { errorCatch } from "../../func/common";
import { ReactSortable } from "react-sortablejs";
import api from "../../func/api";
import Select, { components } from "react-select";
import { customStyles } from "../common/funcs/common";
import { useMemo } from "react";

/**
 *
 * @param {{nameColl:string, selectOps:object[], fields:object[]}} param0
 * @returns
 */
function ExcelSettings({ nameColl, selectOps, fields }) {
  const filterHTML = [];
  const [nameList, setNameList] = useState("");
  const [numberString, setNumberString] = useState(1);
  const [startWith, setStartWith] = useState();
  const [keyIdentCount, setKeyIdentCount] = useState(1);
  const [addIdToExport, setAddIdToExport] = useState(false);
  const [fieldsKV, setFrieldsKV] = useState([]);
  const [fullExcelSettings, setFullExcelSettings] = useState({});

  const keyIdentCountSafe = useMemo(
    () => (!keyIdentCount || keyIdentCount < 1 ? 1 : keyIdentCount),
    [keyIdentCount]
  );

  const chooseElems = fieldsKV.map((el) => el.key);

  useEffect(() => {
    api
      .getSettingsSelect("excel")
      .then((result) => {
        if (result.settings.excel && result.settings.excel[nameColl]) {
          setFrieldsKV(result.settings.excel[nameColl].fieldsKV);
          setNameList(result.settings.excel[nameColl].nameList);
          setNumberString(result.settings.excel[nameColl].numberString);
          setStartWith(result.settings.excel[nameColl].startWith);
          setKeyIdentCount(result.settings.excel[nameColl].keyIdentCount);
          setAddIdToExport(result.settings.excel[nameColl].addIdToExport);
        }

        setFullExcelSettings(result.settings.excel);
      })
      .catch((err) => {
        errorCatch(err);
      });
  }, [nameColl]);

  fieldsKV.forEach((el, i) => {
    const options = selectOps.filter((el) => !chooseElems.includes(el.value));

    filterHTML.push(
      <div
        className={`row align-items-center position-relative pb-2 pe-3 ${
          i < keyIdentCountSafe ? "bg-light rounded p-2 ps-5" : "ps-5"
        } ${i >= keyIdentCountSafe - 1 ? "mb-3" : ""}`}
        key={i}
      >
        <div className="position-absolute pointer  start-0 ">
          <i className="bi bi-list" />
        </div>

        <div className="col">
          <Select
            options={options}
            styles={customStyles}
            fields={fields}
            value={
              el.key
                ? { value: el.key, label: el.label, type: fields[el.label] }
                : null
            }
            onChange={(e) => {
              fieldsKV[i].key = e.value;
              fieldsKV[i].label = e.label;
              fieldsKV[i].typeField = e.type;

              setFrieldsKV([...fieldsKV]);
            }}
          ></Select>
        </div>
        {el.typeField === "overVar" && (
          <div className=" col input-group">
            <input
              className="form-control"
              type="text"
              value={fieldsKV[i].numName}
              onChange={(e) => {
                fieldsKV[i].numName = e.target.value;
                setFrieldsKV([...fieldsKV]);
              }}
            />
          </div>
        )}
        <div className=" col input-group">
          <input
            className="form-control"
            type="text"
            value={fieldsKV[i].value}
            onChange={(e) => {
              fieldsKV[i].value = e.target.value;
              setFrieldsKV([...fieldsKV]);
            }}
          />
        </div>

        {i >= keyIdentCountSafe ? (
          <div className="col col-1 p-0" style={{ zIndex: 2 }}>
            <input
              className="form-check-input pointer"
              type="checkbox"
              checked={fieldsKV[i].onlyExport ?? false}
              onChange={({ target }) => {
                console.log(`target.checked`, target.checked);
                fieldsKV[i].onlyExport = target.checked;
                setFrieldsKV([...fieldsKV]);
              }}
              data-toggle="tooltip"
              data-placement="top"
              title="Only for export"
              aria-label={"Only for export"}
            />
          </div>
        ) : null}

        <span
          className="position-absolute block-group-btn pointer d-flex justify-content-center"
          style={{ top: "50%", transform: "translateY(-50%)", right: "3px" }}
          onClick={() => {
            fieldsKV.splice(i, 1);
            setFrieldsKV([...fieldsKV]);
          }}
        >
          <i className="bi bi-plus text-danger rotate" />
        </span>
        {i === keyIdentCountSafe - 1 && (
          <p className="col-12 fs-14 text-secondary m-0">
            Ключевое поле для сопоставления данных
          </p>
        )}
      </div>
    );
  });

  return (
    <>
      <div className="d-flex bg-light align-items-center mb-4 mb-4 p-3">
        <h6 className="m-0">Настройки экспорта и импорта</h6>
        <div className="ms-auto">
          <button
            className="btn btn-outline-primary me-2"
            onClick={() => {
              setFrieldsKV([]);
            }}
          >
            Сбросить
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              const exportData = {};
              const importData = {};

              fieldsKV.forEach(({ key, value }) => {
                if (key) {
                  exportData[key] = value;
                  importData[value] = key;
                }
              });

              api
                .postSettings({
                  excel: {
                    ...fullExcelSettings,
                    [nameColl]: {
                      nameList,
                      numberString,
                      startWith,
                      exportData,
                      importData,
                      fieldsKV,
                      keyIdentCount: keyIdentCountSafe,
                      addIdToExport,
                    },
                  },
                })
                .then((result) => {
                  console.log(`result`, result);
                })
                .catch((err) => {
                  errorCatch(err);
                });
            }}
          >
            Применить
          </button>
        </div>
      </div>
      <div className="p-3">
        <div className=" col input-group mb-3">
          <span class="input-group-text">Имя листа</span>
          <input
            className="form-control"
            type="text"
            value={nameList}
            onChange={(e) => {
              setNameList(e.target.value);
            }}
          />
        </div>
        <div className=" col input-group mb-3">
          <span class="input-group-text">Номер строки с title</span>
          <input
            className="form-control"
            type="number"
            value={numberString}
            onChange={(e) => {
              setNumberString(e.target.value);
            }}
          />
        </div>
        <div className=" col input-group mb-3">
          <span class="input-group-text">Номер строки начала контента</span>
          <input
            className="form-control"
            type="number"
            value={startWith}
            onChange={(e) => {
              setStartWith(e.target.value);
            }}
          />
        </div>
        <div className=" col input-group mb-2">
          <span class="input-group-text">Кол-во ключевых полей</span>
          <input
            className="form-control"
            type="text"
            value={keyIdentCount}
            onChange={(e) => {
              let value = e.target.value;
              if (value === "") {
                value = null;
              } else {
                value = +value;
                if (value < 1) value = 1;
              }
              setKeyIdentCount(value);
            }}
          />
        </div>
        <div className=" col input-group mb-3" style={{ zIndex: "0" }}>
          <span class="me-4">Добавить ID в выгрузку [_id]</span>
          <div className="form-check form-switch ">
            <input
              className="form-check-input pointer"
              type="checkbox"
              checked={addIdToExport}
              onChange={({ target }) => {
                setAddIdToExport(target.checked);
              }}
            />
          </div>
        </div>
        <div className="row align-items-center position-relative mb-2 me-2">
          <div className="col-1"></div>
          <div className="col-5 text-secondary fs-16">Поле в CMS</div>

          <div className=" col input-group text-secondary fs-16">
            Поле (title) в Exel
          </div>
        </div>
        {filterHTML.slice(0, keyIdentCountSafe)}
        <ReactSortable
          animation={200}
          list={fieldsKV.length ? fieldsKV.slice(1, fieldsKV.length) : []}
          setList={(newOrderArr) => {
            setFrieldsKV(
              fieldsKV[0] ? [fieldsKV[0], ...newOrderArr] : [...newOrderArr]
            );
          }}
          swapThreshold={0.65}
          handle=".bi-list"
        >
          {filterHTML.slice(keyIdentCountSafe, filterHTML.length)}
        </ReactSortable>

        <div className="w-100 d-flex justify-content-center mt-4">
          <div
            className="block-group-btn pointer d-flex justify-content-center"
            onClick={() => {
              setFrieldsKV([...fieldsKV, { key: "", value: "" }]);
            }}
          >
            <i className="bi bi-plus text-primary" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ExcelSettings;
