import React, { useState, useEffect } from "react";
/**
 * @callback onClickSearch
 * @param {string} searchString
 * @returns {void}
 */
/**
 *
 * @param {{
 * className:string
 * onClick:onClickSearch
 * onChangeTracking:boolean
 * }} param0
 * @returns {JSX.Element}
 */
const SearchElem = ({ className, onClick, onChangeTracking }) => {
  const [search, setSearch] = useState("");
  const [startSearch, setStartSearch] = useState(false);

  return (
    <div class={`input-group ${className ?? ""}`}>
      <input
        type="text"
        class="form-control border-end-0"
        id="inputGroup"
        aria-describedby="inputGroup"
        placeholder="Поиск"
        onChange={({ target }) => {
          setSearch(target.value);
          setStartSearch(false);
          if (onChangeTracking) {
            onClick(target.value);
          }
        }}
        value={search}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onClick(search);
            setStartSearch(true);
          }
        }}
      />
      <i
        className={`bi ${
          startSearch ? "bi-x-circle" : "bi-search"
        } pointer input-group-text bg-light border-start-0 text-secondary`}
        onClick={() => {
          if (startSearch) {
            setSearch("");
            onClick("");
            setStartSearch(false);
          } else {
            onClick(search);
            setStartSearch(true);
          }
        }}
      />
    </div>
  );
};

export default SearchElem;
