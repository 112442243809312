import React, { useReducer, useState, useEffect, useRef } from "react";
import EditorJs from "react-editor-js";
import List from "@editorjs/list";
import LinkTool from "@editorjs/link";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Embed from "@editorjs/embed";
import InlineCode from "@editorjs/inline-code";
import edjsParser from "editorjs-parser";
import edjsHTML from "editorjs-html";

const customParsers = {
  customBlock: function (data, config) {
    return data.text;
  },
  image: function (data, config) {
    return data.text;
  },
  paragraph: function (data, config) {
    return data.text;
  },
  embed: function (data, config) {
    return data.text;
  },
  list: function (data, config) {
    return data.items;
  },
};

const parser = new edjsParser(undefined, customParsers);
const edjsParserHTML = edjsHTML();

const EDITOR_JS_TOOLS = {
  embed: Embed,
  list: {
    class: List,
    inlineToolbar: true,
  },
  linkTool: {
    class: LinkTool,
    inlineToolbar: true,
  },
  header: {
    class: Header,
    config: {
      levels: [2, 3],
      defaultLevel: 3,
    },
    inlineToolbar: true,
  },
  quote: Quote,
  inlineCode: InlineCode,
};

const RichText = ({
  changeData,
  data,
  path,
  dataObjInside,
  rowLength,
  bodyInsaid,
  dispatch,
}) => {
  const textEditor = useRef(null);

  return (
    <div className={`col-${12 / rowLength}`} key={bodyInsaid.nameDB}>
      <h6>{bodyInsaid.name}</h6>
      <div className="text-edit mb-4">
        <EditorJs
          data={dataObjInside[bodyInsaid.nameDB].json}
          tools={EDITOR_JS_TOOLS}
          // enableReInitialize={true}
          onChange={async (e) => {
            const value = await textEditor.current.save();
            const dataUpd = {
              json: value,
              lengthText: parser.parse(value).length,
              text: parser.parse(value),
              html: edjsParserHTML.parse(value),
            };
            dispatch({
              data: changeData({
                data,
                path: [...path, bodyInsaid.nameDB],
                value: dataUpd,
              }),
            });
            // e.toolbar.open();
          }}
          onReady={(ed) => (textEditor.current = ed)}
        />
      </div>
    </div>
  );
};

export default RichText;
