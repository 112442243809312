import React, { useState, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import SelectAsync from "react-select/async";
import api from "../../func/api";
import { createDefaultMenu, errorCatch } from "../../func/common";
import ModalDelete from "../common/ModalDelete";
import SearchElem from "../common/SearchElem";
import { slugify } from "transliteration";

const getData = (setData) => {
  //!перенести запросы на сервер
  api
    .getTemplate("navigation")
    .then((nav) => {
      console.log(`nav`, nav);
      if (nav.null)
        api
          .postTemplate("navigation", {
            name: "Меню",
            main: true,
          })
          .then(() => getData(setData));
      else setData(nav);
    })
    .catch((err) => {
      console.log(`err`, err);
    });
};

const NavElem = ({
  elem,
  pos,
  path,
  orderMenu,
  setOrderMenu,
  setChangeData,
  setOpenCreateFolder,
  closeAll,
  setDeleteFunc,
  setDeleteText,
}) => {
  const [showChilds, setShowChilds] = useState(false);
  const [variations, setVariations] = useState([]);
  const last = orderMenu.length - 1 === pos;
  useEffect(() => {
    if (closeAll > 0) setShowChilds(false);
    else if (elem.childs && elem.childs.length) setShowChilds(true);
  }, [closeAll]);

  const getVariations = () => {
    if (!variations.length)
      api
        .filterDatas({
          typeModel: "collections",
          model: elem.collectionData.nameDBCol,
          data: { filter: { parent: elem.collectionData.id } },
          type: "forSelect",
        })
        .then((result) => {
          setVariations(result);
          console.log("result :>> ", result);
        })
        .catch((err) => {
          errorCatch(err);
        });
  };

  const addElem = async (newElem) => {
    return api
      .postTemplate("navigation", {
        name: newElem.name,
        collectionData: {
          nameDBCol: elem.collectionData.nameDBCol,
          id: newElem._id,
        },
      })
      .catch((err) => {
        errorCatch(err);
      });
  };

  let childs;
  if (elem.childs && elem.childs.length) {
    childs = elem.childs.map((el, iC) => {
      return (
        <NavElem
          {...{
            elem: el,
            pos,
            path: [...path, "childs", iC],
            orderMenu,
            setOrderMenu,
            setChangeData,
            setOpenCreateFolder,
            closeAll,
            setDeleteFunc,
            setDeleteText,
          }}
        />
      );
    });
  }

  return (
    <div key={elem.name} className={path.length === 2 ? "border-bottom" : ""}>
      <div className="d-flex flex-row justify-content-between p-3 ">
        <div className="d-flex flex-row align-items-center">
          {path.length > 2 && (
            <div
              style={{
                width: "27px",
                height: "1px",
                backgroundColor: "#E0E0E0",
              }}
              className="me-3"
            ></div>
          )}
          <i
            className={`bi ${
              elem.manual ? "bi-link" : "bi-file-text"
            } me-3 fs-14`}
          />
          <span className="pe-1 ">{elem.name}</span>
          <span className="fs-12 text-muted ms-2">
            /{elem.manual ? elem.manual.slug : elem.collectionData.id.slug}
          </span>
        </div>
        <div className="">
          <i
            className={`bi bi-chevron-down text-muted me-3 ${
              showChilds ? "rotate180i" : ""
            }`}
            onClick={() => setShowChilds((show) => !show)}
          />

          <i
            className="bi bi-trash text-muted me-3"
            onClick={() => {
              setDeleteText(
                `Вы удаляете элемент «${elem.name}» и все его дочерние элементы из навигации.`
              );
              setDeleteFunc(() => {
                return () => {
                  setOrderMenu((orderMenu) => {
                    const partPath = [...path];
                    console.log("partPath", partPath);
                    const lastElem = partPath.pop();
                    const obj = partPath.reduce(
                      (prev, el) => prev[el],
                      orderMenu
                    );

                    obj.splice(lastElem, 1);
                    api
                      .putTemplate("navigation", obj)
                      .then((result) => {})
                      .catch((err) => {
                        errorCatch(err);
                      });

                    api
                      .deleteTemplate("navigation", elem)
                      .then((result) => {})
                      .catch((err) => {
                        errorCatch(err);
                      });
                    console.log("obj :>> ", obj);
                    console.log("orderMenu :>> ", orderMenu);
                    return { ...orderMenu };
                  });
                  setDeleteText("");
                  setDeleteFunc(null);
                };
              });
            }}
          />

          {/* <i
              className="bi bi-pencil me-4 pointer"
              onClick={() =>
                setChangeData({ data: elem, path: pos }) ||
                setOpenCreateFolder(true)
              }
            />
          
          <p className="text-secondary me-4 constr-number">{pos + 1}</p> */}
          <i className="bi bi-list folder-move" />
        </div>
      </div>

      <div
        className={`ms-4 mb-4 ps-4 accordion-collapse collapse ${
          showChilds ? "show" : ""
        }`}
      >
        <ReactSortable
          animation={200}
          delayOnTouchStart={true}
          delay={2}
          list={elem.childs}
          setList={(elm) => {
            console.log(`elm`, elm);

            console.log("orderMenu", orderMenu);
            setOrderMenu((orderMenu) => {
              const obj = path.reduce((prev, el) => prev[el], orderMenu);

              obj.childs = elm;
              api
                .putTemplate("navigation", obj)
                .then((result) => {})
                .catch((err) => {
                  errorCatch(err);
                });
              return { ...orderMenu };
            });
          }}
          onEnd={function (event) {
            console.log(`event`, event);
          }}
          group="shared"
          handle=".bi-list"
          swapThreshold={0.65}
        >
          {childs}
        </ReactSortable>
        <div class="btn-group" role="group">
          <button
            id="btnGroupDrop1"
            type="button"
            class="btn btn-outline-primary dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={getVariations}
          >
            + Новое поле
          </button>
          <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
            {variations.map((variant) =>
              elem.childs.some((el) => variant.name === el.name) ? null : (
                <li key={variant.name}>
                  <p
                    class="dropdown-item"
                    onClick={() =>
                      addElem(variant).then((point) => {
                        console.log("point", point);
                        setOrderMenu((orderMenu) => {
                          const obj = path.reduce(
                            (prev, el) => prev[el],
                            orderMenu
                          );
                          if (!obj.childs) obj.childs = [];
                          if (!obj.childs.some((el) => el.name === point.name))
                            obj.childs.push(point);
                          api
                            .putTemplate("navigation", obj)
                            .then((result) => {})
                            .catch((err) => {
                              errorCatch(err);
                            });

                          return { ...orderMenu };
                        });
                      })
                    }
                  >
                    {variant.name}
                  </p>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const CategoryesCostructor = ({ setRegetMenu }) => {
  const [orderMenu, setOrderMenu] = useState({
    childs: [],
  });
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  const [changeData, setChangeData] = useState(null);
  const [closeAll, setCloseAll] = useState(0);

  const [deleteText, setDeleteText] = useState("");

  const [deleteFunc, setDeleteFunc] = useState(null);
  const [collections, setCollections] = useState([]);

  const [searchCollectionInput, setSearchCollectionInput] = useState("");

  const [searchInput, setSearchInput] = useState("");

  const [chooseElems, setChooseElems] = useState([]);
  const [activeColl, setActiveColl] = useState("");

  console.log(`orderMenu`, orderMenu);

  useEffect(() => {
    setChooseElems([]);
    setSearchInput("");
  }, [activeColl]);

  useEffect(() => {
    getData(setOrderMenu, api);
    api
      .getConstr()
      .then((result) => {
        const filterCollections = result.filter((a) =>
          a.body.some((field) => field.nameDB === "slug")
        );

        filterCollections.sort((a, b) =>
          a.nameDB === "categories" ? -1 : b.nameDB === "categories" ? 1 : 0
        );

        setCollections([...filterCollections]);
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  }, []);

  const createFolder = (name) => {
    if (changeData) {
      orderMenu[changeData.path].name = name;
      setChangeData(null);
    } else {
      orderMenu.unshift({
        folder: true,
        elems: [],
        icon: "folder2",
        name: name,
      });
    }

    setOrderMenu([...orderMenu]);
  };

  const closePopupCreateFolder = () => {
    setChangeData(null);
    setOpenCreateFolder(false);
  };

  const addMainElems = (elems, manual) => {
    const data = elems.map((elem) => {
      const elemData = {
        name: elem.label,
      };

      if (manual) elemData.manual = { slug: elem.slug };
      else
        elemData.collectionData = {
          nameDBCol: activeColl,
          id: elem.value._id,
        };

      return elemData;
    });

    api
      .postTemplate("navigation", data)
      .then((newPoints) => {
        orderMenu.childs.push(...newPoints);
        api
          .putTemplate("navigation", orderMenu)
          .then((result) => {
            getData(setOrderMenu);
            setActiveColl("");
          })
          .catch((err) => {
            errorCatch(err);
          });
      })
      .catch((err) => {
        errorCatch(err);
      });
  };

  const names = [];
  let count = 0;

  const html = orderMenu.childs
    ? orderMenu.childs.map((e, i) => {
        // names.push(e.name);
        // count++;
        // if (e.folder) {
        //   e.elems.forEach((el) => {
        //     names.push(el.name);
        //     count++;
        //   });
        // }

        return (
          <NavElem
            key={e.name}
            elem={e}
            pos={i}
            path={["childs", i]}
            orderMenu={orderMenu}
            setOrderMenu={setOrderMenu}
            setOpenCreateFolder={setOpenCreateFolder}
            setChangeData={setChangeData}
            closeAll={closeAll}
            setDeleteFunc={setDeleteFunc}
            setDeleteText={setDeleteText}
          />
        );
      })
    : null;

  const collectionsList = collections.map((coll) =>
    !searchCollectionInput ||
    coll.name.toLowerCase().includes(searchCollectionInput.toLowerCase()) ? (
      <div class="text-dark constr-item mb-2" type="button">
        <p
          className="m-0"
          onClick={() =>
            setActiveColl((ac) => (ac === coll.nameDB ? "" : coll.nameDB))
          }
        >
          {coll.name}
        </p>
        {coll.nameDB === activeColl && (
          <div className="d-flex w-100 align-items-center mt-2">
            <SelectAsync
              className="mb-1 w-100 me-2"
              cacheOptions
              inputValue={searchInput}
              isMulti
              onChange={(e, a) => {
                setChooseElems([...e]);
                setSearchInput("");
              }}
              onInputChange={(value, info) => {
                if (info.action === "input-change") setSearchInput(value);
              }}
              loadOptions={(value, callback) => {
                if (value.length >= 3)
                  api
                    .filterDatas({
                      typeModel: "collections",
                      model: coll.nameDB,
                      data: {
                        filter: { $text: { $search: value } },
                        sort: {
                          _id: 1,
                        },
                      },
                      type: "forSelect",
                    })
                    .then((result) => {
                      const newData = result.map((el) => ({
                        label: el[coll.defaultField],
                        value: el,
                      }));
                      callback(newData);
                      console.log("result :>> ", result);
                    })
                    .catch((err) => {
                      errorCatch(err);
                    });
              }}
            />
            <i
              className="bi bi-plus-circle-fill text-primary"
              onClick={() => {
                addMainElems(chooseElems);
              }}
            ></i>
          </div>
        )}
      </div>
    ) : null
  );

  return (
    <main>
      <div className="container-fluid ">
        <div className="row">
          <div className="col col-9 drop-bg vh-100">
            <div className="d-flex justify-content-between align-items-center mb-4 mt-4">
              <div>
                <h5 className="mb-0">Конструктор навигации</h5>
              </div>
              <div>
                <button
                  className="btn btn-outline-danger"
                  onClick={() => {
                    getData(setOrderMenu, api);
                  }}
                >
                  Отменить
                </button>
                <button
                  className="btn btn-primary ms-3"
                  onClick={() => {
                    api
                      .putTemplate("navigation", orderMenu)
                      .then((result) => {
                        getData(setOrderMenu);
                      })
                      .catch((err) => {
                        errorCatch(err);
                      });
                  }}
                >
                  Сохранить
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center mb-4">
              <div>
                <button
                  className="btn btn-outline-dark"
                  onClick={() => setCloseAll((cl) => !cl)}
                >
                  {closeAll > 0 ? "Развернуть всё" : "Свернуть всё"}
                </button>
              </div>
            </div>

            <div className="border rounded bg-white mb-5">
              <ReactSortable
                animation={200}
                delayOnTouchStart={true}
                delay={2}
                list={orderMenu.childs}
                setList={(newOrderArr) => {
                  console.log(`newOrderArr`, newOrderArr);
                  api
                    .putTemplate("navigation", {
                      ...orderMenu,
                      childs: newOrderArr,
                    })
                    .then((result) => {})
                    .catch((err) => {
                      errorCatch(err);
                    });
                  setOrderMenu((order) => {
                    order.childs = [...newOrderArr];

                    return order;
                  });
                }}
                group="shared"
                swapThreshold={0.65}
                handle=".folder-move"
              >
                {html}
              </ReactSortable>
            </div>
          </div>
          <div
            className="col col-3  vh-100 mb-4 mt-4"
            style={{ backgroundColor: "#F4F4F4" }}
          >
            <SearchElem
              className="mb-3 p-3"
              onClick={(searchString) => setSearchCollectionInput(searchString)}
              onChangeTracking={true}
            />
            <div class="mb-3 px-3">
              <h6 className="d-flex justify-content-between align-items-center">
                Произвольно
              </h6>

              <div className=" constr-item">
                <p
                  class="text-dark ps-0 m-0"
                  type="button"
                  onClick={() =>
                    setActiveColl((act) =>
                      act === "manual_link" ? "" : "manual_link"
                    )
                  }
                >
                  Ссылка
                </p>

                {activeColl === "manual_link" && (
                  <div className="row mt-2">
                    <div className="col col-10">
                      <input
                        type="text"
                        class="form-control mb-2"
                        id="nameLink"
                        aria-describedby="inputGroup"
                        placeholder="Название"
                      />
                    </div>
                    <div className="col col-10">
                      <input
                        type="text"
                        class="form-control"
                        id="slugLink"
                        aria-describedby="inputGroup"
                        placeholder="Ссылка"
                      />
                    </div>
                    <div className="col col-2">
                      <i
                        className="bi bi-plus-circle-fill text-primary"
                        onClick={() => {
                          const name =
                            document.getElementById("nameLink").value;
                          const slug =
                            document.getElementById("slugLink").value;

                          addMainElems([{ label: name, slug }], true);
                        }}
                      ></i>
                    </div>
                  </div>
                )}
              </div>

              <div className=" constr-item">
                <p class="text-dark ps-0 m-0" type="button">
                  Папка
                </p>
              </div>
            </div>
            <div class="mb-3 px-3">
              <h6
                class=""
                className="d-flex justify-content-between align-items-center"
              >
                Записи
              </h6>
              <div>{collectionsList.slice(0, 3)}</div>
              {collectionsList.length > 3 && (
                <>
                  <div
                    class="collapse bg-transparent border-0 w-100 "
                    id="collapseOneType"
                  >
                    {collectionsList.slice(3, collectionsList.length)}
                  </div>
                  <div>
                    <a
                      class="ps-0 text-primary"
                      data-bs-toggle="collapse"
                      href="#collapseOneType"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseOneType"
                      type="button"
                      style={{
                        opacity: 0.5,
                        padding: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      Посмотреть все ({collectionsList.length})
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {openCreateFolder && (
        <FolderCreateModal
          createFolder={createFolder}
          close={closePopupCreateFolder}
          names={names}
          changeData={changeData}
        />
      )}
      <ModalDelete
        title={"Удалить папку?"}
        open={deleteText}
        text={deleteText}
        closePopUp={() => {
          setDeleteText("");
          setDeleteFunc(null);
        }}
        deleteFunc={deleteFunc}
      />
    </main>
  );
};

const FolderCreateModal = ({ createFolder, close, changeData }) => {
  const [name, setName] = useState(changeData ? changeData.data.name : "");

  useEffect(() => {
    return () => {
      setName(null);
    };
  }, []);

  const iconsHTML = [];

  const dataIcons = [
    { icon: "chevron-down" },
    { icon: "controller" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "cloud-arrow-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "clipboard-data" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
    { icon: "chevron-down" },
  ];

  dataIcons.forEach((e) => {
    iconsHTML.push(<i className={`bi bi-${e.icon} me-4 mb-4 fs-5 pointer`} />);
  });

  return (
    <div
      className="modal fade show"
      id="folderModal"
      tabindex="-1"
      aria-labelledby="folderModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-folder">
        <div className="modal-content">
          <div className="modal-header bg-light">
            <h5 className="modal-title" id="uploadMediaModalLabel">
              {changeData !== null ? "Изменение" : "Создание"} папки
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={close}
            ></button>
          </div>
          <div className="modal-body p-0">
            <h6 className="p-4 border-bottom">Конфигурации</h6>
            <div className="row m-4">
              <div className="col-6">
                <label for="foldername" className="mb-1 ">
                  Имя
                </label>
                <input
                  id="foldername"
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {/* <div className="mt-4 d-flex align-items-center">
                  <input
                    id="foldercheck"
                    type="checkbox"
                    className="form-check-input me-2"
                    defaultChecked
                  />
                  <label for="foldercheck" type="checkbox" className="">
                    Отображать иконку в меню навигации
                  </label>
                </div> */}
              </div>
              {/* <div className="col-6">
                <p>Иконка</p>
                <div className="card flex-row flex-wrap bg-gray p-3 pb-0 pe-0">
                  {iconsHTML}
                </div>
              </div> */}
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-end">
            <div>
              <button className="btn btn-secondary me-2" onClick={close}>
                Отменить
              </button>
              <button
                className="btn btn-primary"
                onClick={() => createFolder(name) || close()}
              >
                {changeData !== null ? "Сохранить" : "Добавить"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryesCostructor;
