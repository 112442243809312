import React, { useState, useRef, useEffect, Component } from "react";
import { changeImageData, changeData } from "../../func/common";
import EditorJs from "react-editor-js";
import List from "@editorjs/list";
import LinkTool from "@editorjs/link";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Embed from "@editorjs/embed";
import InlineCode from "@editorjs/inline-code";

const EDITOR_JS_TOOLS = {
  embed: Embed,
  list: {
    class: List,
    inlineToolbar: true,
  },
  linkTool: {
    class: LinkTool,
    inlineToolbar: true,
  },
  header: {
    class: Header,
    config: {
      levels: [2, 3],
      defaultLevel: 3,
    },
    inlineToolbar: true,
  },
  quote: Quote,
  inlineCode: InlineCode,
};

// import JoditEditor from "jodit-react";

const TextBlock = ({ data, text, type, field, i, updFunk, block }) => {
  const editor = useRef(null);

  return (
    // <div id="textBlock"></div>
    <div className="text-edit">
      <EditorJs
        data={text}
        tools={EDITOR_JS_TOOLS}
        // enableReInitialize={true}
        onChange={(e, value) => {
          console.log(`e`, e);
          console.log(`value`, value);
          changeData({
            data,
            value,
            type,
            field,
            i,
            updFunk,
            block,
          });
          // e.toolbar.open();
        }}
      />
    </div>
  );
};

export default TextBlock;
