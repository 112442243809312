import logo from "./logo.svg";
import React, { useState, useEffect, useReducer } from "react";
import api from "./func/api";
import "./style.scss";
import Menu from "./comp/Menu";
import Header from "./comp/Header";
import Login from "./comp/Login";
import Constructor from "./comp/pageConstructor/Constructor";
import Navconstr from "./comp/pageConstructor/Navconstr";
import Person from "./comp/pageConstructor/Person";
import PersonList from "./comp/pageConstructor/PersonList";
import RoleList from "./comp/pageConstructor/RoleList";
import Role from "./comp/pageConstructor/Role";
import Profile from "./comp/Profile";
import SettingsMedia from "./comp/pageConstructor/SettingsMedia";
import SettingsRestList from "./comp/pageConstructor/SettingsRestList";
import SettingsRest from "./comp/pageConstructor/SettingsRest";

import PageConstructor from "./comp/pageConstructor/PageConstructor";
import TableConstruct from "./comp/pageConstructor/TableConstruct";
import Collection from "./comp/pageConstructor/Collection";
import EditConstr from "./comp/pageConstructor/EditConstr";

import Media from "./comp/Media";

import { Route, Switch, Redirect } from "react-router";
import { withRouter } from "react-router";

import $ from "jquery";
import bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import InfoCMSList from "./comp/pageConstructor/InfoCMSList";
import InfoCMS from "./comp/pageConstructor/InfoCMS";
import { errorCatch } from "./func/common";
import CategoryesCostructor from "./comp/pageConstructor/CategoryesCostructor";

function App() {
  const [userData, setUserData] = useState("");
  const [regetMenu, setRegetMenu] = useState(0);
  const [cmsData, setCmsData] = useState(null);

  const getCMSData = (slug) => {
    api
      .getInfoCMS(slug)
      .then((result) => {
        setCmsData(result);
      })
      .catch((err) => {
        errorCatch(err);
      });
  };

  useEffect(() => {
    api
      .getUserData()
      .then((result) => {
        setUserData(result);
      })
      .catch((err) => {
        console.log(`err`, err);
        setUserData(null);
      });
  }, []);

  // console.log(`userData`, userData);

  const userCheck = userData !== undefined && userData !== null;

  return (
    <div>
      {/* <Navconstr /> */}
      {/* <Header user={userData} /> */}
      <div className="wrapper flex-nowrap position-relative">
        {userCheck && cmsData && (
          <Menu user={userData} regetMenu={regetMenu} cmsData={cmsData} />
        )}
        {/* <Person /> */}
        {/* <PersonList /> */}
        {/* <RoleList /> */}
        {/* <Admin /> */}
        {/* <Profile /> */}
        {/* <SettingsRest /> */}
        <Switch>
          <Route path="/login" exact render={(routProps) => <Login />} />
          {!userCheck && <Redirect from="*" to="/login" />}
          {/* {!cmsData && (
            <Route
              path=""
              render={(routProps) => {
                getCMSDataSetApi(routProps.match.params.cms);
                // return <Redirect to={`/front/${cmsData.slug}/constructor`} />;
              }}
            />
          )}
          {!cmsData && <Redirect from="*" to="/front/:cms/front/main" />} */}
          <Route
            path="/front/:cms"
            render={(routProps) => {
              if (
                !cmsData ||
                (cmsData && cmsData.slug !== routProps.match.params.cms)
              )
                getCMSData(routProps.match.params.cms);
              return (
                <Switch>
                  <Route
                    path={[
                      "/front/:cms/constructor",
                      "/front/:cms/constructor/:slug",
                      "/front/:cms/constructor/:slug/:type",
                    ]}
                    exact
                    render={(routProps) => (
                      <Constructor
                        cms={routProps.match.params.cms}
                        nameColl={routProps.match.params.namecoll}
                        slug={routProps.match.params.slug}
                        type={routProps.match.params.type}
                      />
                    )}
                  />
                  <Route
                    path={[
                      "/front/:cms/pages/:namecoll",
                      "/front/:cms/single-pages/:namecoll",
                    ]}
                    exact
                    render={(routProps) => (
                      <EditConstr
                        cms={routProps.match.params.cms}
                        typeModel={"pages"}
                        nameColl={routProps.match.params.namecoll}
                        userData={userData}
                      />
                    )}
                  />
                  <Route
                    path="/front/:cms/collections/:namecoll/:id"
                    exact
                    render={(routProps) => (
                      <EditConstr
                        cms={routProps.match.params.cms}
                        typeModel={"collections"}
                        nameColl={routProps.match.params.namecoll}
                        id={routProps.match.params.id}
                        userData={userData}
                        router={routProps}
                      />
                    )}
                  />
                  <Route
                    path="/front/:cms/collections/:namecoll"
                    exact
                    render={(routProps) => (
                      <Collection
                        cms={routProps.match.params.cms}
                        nameColl={routProps.match.params.namecoll}
                        typeModel={"collections"}
                        routProps={routProps}
                        userData={userData}
                        setUserData={setUserData}
                        cmsData={cmsData}
                      />
                    )}
                  />
                  <Route
                    path="/front/:cms/templates/menu"
                    exact
                    render={(routProps) => (
                      <CategoryesCostructor
                        setRegetMenu={setRegetMenu}
                        cms={routProps.match.params.cms}
                      />
                    )}
                  />
                  <Route
                    path="/front/:cms/settings/menu"
                    exact
                    render={(routProps) => (
                      <Navconstr
                        setRegetMenu={setRegetMenu}
                        cms={routProps.match.params.cms}
                      />
                    )}
                  />

                  <Route
                    path="/front/:cms/media"
                    exact
                    render={(routProps) => (
                      <Media
                        routProps={routProps}
                        cms={routProps.match.params.cms}
                      />
                    )}
                  />

                  <Route
                    path="/front/:cms/settings/media"
                    exact
                    render={(routProps) => (
                      <SettingsMedia cms={routProps.match.params.cms} />
                    )}
                  />
                  <Route
                    path="/front/:cms/settings/rest"
                    exact
                    render={(routProps) => (
                      <SettingsRestList cms={routProps.match.params.cms} />
                    )}
                  />
                  <Route
                    path="/front/:cms/settings/rest/:id"
                    exact
                    render={(routProps) => (
                      <SettingsRest id={routProps.match.params.id} />
                    )}
                  />
                  <Route
                    path="/front/:cms/profile"
                    exact
                    render={() => <Profile />}
                  />
                  <Route
                    path="/front/:cms/settings/user"
                    exact
                    render={(routProps) => (
                      <PersonList cms={routProps.match.params.cms} />
                    )}
                  />
                  <Route
                    path="/front/:cms/settings/user/:id"
                    exact
                    render={(routProps) => (
                      <Person
                        id={routProps.match.params.id}
                        cms={routProps.match.params.cms}
                        userData={userData}
                      />
                    )}
                  />
                  <Route
                    path="/front/:cms/settings/role"
                    exact
                    render={(routProps) => (
                      <RoleList cms={routProps.match.params.cms} />
                    )}
                  />
                  <Route
                    path="/front/:cms/settings/role/:id"
                    exact
                    render={(routProps) => (
                      <Role
                        id={routProps.match.params.id}
                        cms={routProps.match.params.cms}
                      />
                    )}
                  />
                  <Route
                    path="/front/:cms/settings/info-cms"
                    exact
                    render={(routProps) => (
                      <InfoCMSList cms={routProps.match.params.cms} />
                    )}
                  />
                  <Route
                    path="/front/:cms/settings/info-cms/:id"
                    exact
                    render={(routProps) => (
                      <InfoCMS
                        id={routProps.match.params.id}
                        cms={routProps.match.params.cms}
                      />
                    )}
                  />
                </Switch>
              );
            }}
          ></Route>
          <Route
            path={["/", "/front"]}
            exact
            render={(routProps) => {
              if (userData) {
                const cmsSlug = Object.keys(userData.cmsAccess).find(
                  (key) => userData.cmsAccess[key]
                );
                if (cmsSlug) window.location.href = "/front/" + cmsSlug;
              }
            }}
          />
          {/* <Route
            path="/front/:cms/profile"
            exact
            render={(routProps) => <Profile user={userData} />}
          />
          <Route path="/front/:cms/pages" exact render={(routProps) => <Pages />} />
          <Route
            path="/front/:cms/pages/home-page"
            exact
            render={(routProps) => <HomePage />}
          />
          <Route
            path="/front/:cms/pages/about"
            exact
            render={(routProps) => <AboutPage />}
          />
          <Route
            path="/front/:cms/pages/catalog"
            exact
            render={(routProps) => <CatalogPage />}
          />
          <Route path="/front/:cms/pages/faq" exact render={(routProps) => <FAQPage />} />
          <Route
            path="/front/:cms/pages/blog"
            exact
            render={(routProps) => <BlogPage />}
          />
          <Route path="/front/:cms/sections" exact render={(routProps) => <Sections />} />
          <Route
            path="/front/:cms/section/:slug"
            exact
            render={(routProps) => (
              <Section slug={routProps.match.params.slug} />
            )}
          />
          <Route path="/front/:cms/posts" exact render={(routProps) => <Posts />} />
          <Route
            path="/front/:cms/post/:slug"
            exact
            render={(routProps) => <Post slug={routProps.match.params.slug} />}
          />
          <Route path="/front/:cms/brands" exact render={(routProps) => <Brands />} />
          <Route
            path="/front/:cms/brand/:slug"
            exact
            render={(routProps) => <Brand slug={routProps.match.params.slug} />}
          />
          <Route
            path="/front/:cms/categories"
            exact
            render={(routProps) => <Categories />}
          />
          <Route
            path="/front/:cms/category/:slug"
            exact
            render={(routProps) => (
              <Category slug={routProps.match.params.slug} />
            )}
          />
          
          <Route path="/front/:cms/products" exact render={(routProps) => <Products />} />
          <Route
            path="/front/:cms/product/:slug"
            exact
            render={(routProps) => (
              <Product slug={routProps.match.params.slug} />
            )}
          />
          <Route path="/front/:cms/roles" exact render={(routProps) => <Roles />} />
          <Route
            path="/front/:cms/role/:slug"
            exact
            render={(routProps) => <Role slug={routProps.match.params.slug} />}
          />
          <Route path="/front/:cms/users" exact render={(routProps) => <Users />} />
          <Route
            path="/front/:cms/user/:slug"
            exact
            render={(routProps) => <User slug={routProps.match.params.slug} />}
          />
          <Route path="/front/:cms/meta" exact render={(routProps) => <Meta />} />
          <Route path="/front/:cms/styles" exact render={(routProps) => <Styles />} />
          <Route path="/front/:cms/scripts" exact render={(routProps) => <Scripts />} />
          <Route
            path="/front/:cms/integrations"
            exact
            render={(routProps) => <Integrations />}
          />
          <Route
            path="/front/:cms/integration/:slug"
            exact
            render={(routProps) => (
              <Integration slug={routProps.match.params.slug} />
            )}
          />
          <Route path="/front/:cms/information" exact render={(routProps) => <Info />} />
          <Route path="/front/:cms/media" exact render={(routProps) => <Media />} />
          <Route
            path="/front/:cms/documents"
            exact
            render={(routProps) => <Documents />}
          /> */}
        </Switch>
      </div>
    </div>
  );
}

// const textEdits = $(".text-editor").summernote({
//   toolbar: [
//     ["font", ["bold"]],
//     ["para", ["ul", "ol"]],
//     ["insert", ["link"]],
//     ["view", ["codeview"]],
//   ],
//   height: 260,
// });

export default withRouter(App);
