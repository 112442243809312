import React, { useReducer, useState, useEffect } from "react";
import api from "../../func/api";
import $ from "jquery";
import { errorCatch } from "../../func/common";
import {
  createDynamicLineEditAccess,
  createLineEditAccess,
  createLineEditAccessMainSettings,
  // checkChooseType,
  // changeChooseType,
} from "../../func/roleCommon";

const staticPoints = [
  {
    name: "Контруктор",
    nameDB: "constructorCMS",
  },
  {
    name: "Конструктор навигации",
    nameDB: "constructorCMSNav",
  },
  {
    name: "Media",
    nameDB: "media",
  },
  {
    name: "Пользователи",
    nameDB: "users",
  },
  {
    name: "Роли",
    nameDB: "roles",
  },
  {
    name: "Настройки сайта",
    nameDB: "siteSettings",
  },
  {
    name: "Настройка REST-запросов",
    nameDB: "restSettings",
  },
];

const settingsTypes = {
  edit: ["edit", "read"],
  read: ["read"],
  create: ["edit", "read", "create", "delete"],
  ban: [],
  settings: ["settings"],
  default: ["default"],
};

const staticPointsCollections = [];
const staticPointsPages = [];

const initialState = {
  collections: {},
  pages: {},

  endpoints: false,
  name: "",
};

staticPoints.forEach((el) => {
  initialState[el.nameDB] = {
    edit: false,
    read: true,
    create: false,
    delete: false,
    settings: false,
  };
});

const reducer = (state, action) => {
  return { ...action.state };
};

const Role = ({ id }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [dataReady, setDataReady] = useState(false);
  const [cmsList, setCmsList] = useState({});

  const newSection = id === "new";

  useEffect(() => {
    api
      .getRole(id)
      .then((roleData) => {
        console.log(`roleData`, roleData);
        const havntRole = roleData.role === null;
        state.collectionsList = {};
        state.pagesList = {};
        const CMSIdToCollPage = {};
        roleData.collections.forEach(({ name, slug, infoCMS }) => {
          staticPointsCollections.push({
            name,
            nameDB: slug,
            collection: true,
          });
          // if (havntRole) {
          //   state.collectionsList[slug] = {
          //     default: true,
          //     edit: false,
          //     read: false,
          //     create: false,
          //     delete: false,
          //     settings: false,
          //   };
          // } else if (roleData.role.collectionsList[slug] === undefined) {
          //   roleData.role.collectionsList[slug] = {
          //     default: true,
          //     edit: false,
          //     read: false,
          //     create: false,
          //     delete: false,
          //     settings: false,
          //   };
          // }

          if (!CMSIdToCollPage[infoCMS]) {
            CMSIdToCollPage[infoCMS] = {};
          }
          if (CMSIdToCollPage[infoCMS].collections) {
            CMSIdToCollPage[infoCMS].collections.push({
              name,
              slug,
              infoCMS,
            });
          } else {
            CMSIdToCollPage[infoCMS].collections = [{ name, slug, infoCMS }];
          }
        });
        roleData.pages.forEach(({ name, slug, infoCMS }) => {
          staticPointsPages.push({
            name,
            nameDB: slug,
            pages: true,
          });
          // if (havntRole) {
          //   state.pagesList[slug] = {
          //     default: true,
          //     edit: false,
          //     read: false,
          //     create: false,
          //     delete: false,
          //     settings: false,
          //   };
          // } else if (roleData.role.pagesList[slug] === undefined) {
          //   roleData.role.pagesList[slug] = {
          //     default: true,
          //     edit: false,
          //     read: false,
          //     create: false,
          //     delete: false,
          //     settings: false,
          //   };
          // }

          if (!CMSIdToCollPage[infoCMS]) {
            CMSIdToCollPage[infoCMS] = {};
          }
          if (CMSIdToCollPage[infoCMS].pages) {
            CMSIdToCollPage[infoCMS].pages.push({ name, slug, infoCMS });
          } else {
            CMSIdToCollPage[infoCMS].pages = [{ name, slug, infoCMS }];
          }
        });

        roleData.cms.forEach((cms, i) => {
          if (!CMSIdToCollPage[cms._id]) CMSIdToCollPage[cms._id] = {};
          CMSIdToCollPage[cms._id].name = cms.name;
          CMSIdToCollPage[cms._id].slug = cms.slug;

          if (havntRole) {
            state.pages[cms.slug] = {
              edit: false,
              read: true,
              create: false,
              delete: false,
              settings: false,
            };
            state.collections[cms.slug] = {
              edit: false,
              read: true,
              create: false,
              delete: false,
              settings: false,
            };
            state.collectionsList[cms.slug] = {};
            state.pagesList[cms.slug] = {};
          } else {
            //!Нужно для удаления ключей с коллекциями и страницами из правил, потом убрать i и разкоментить другие
            // if (i === 0) {
            if (roleData.role.pagesList === undefined)
              roleData.role.pagesList = {};
            if (roleData.role.collectionsList === undefined)
              roleData.role.collectionsList = {};
            // }

            if (roleData.role.pagesList[cms.slug] === undefined)
              roleData.role.pagesList[cms.slug] = {};
            if (roleData.role.collectionsList[cms.slug] === undefined)
              roleData.role.collectionsList[cms.slug] = {};

            if (roleData.role.pages === undefined) roleData.role.pages = {};
            if (roleData.role.pages[cms.slug] === undefined)
              roleData.role.pages[cms.slug] = {
                edit: false,
                read: true,
                create: false,
                delete: false,
                settings: false,
              };
            if (roleData.role.collections === undefined)
              roleData.role.collections = {};
            if (roleData.role.collections[cms.slug] === undefined)
              roleData.role.collections[cms.slug] = {
                edit: false,
                read: true,
                create: false,
                delete: false,
                settings: false,
              };
          }
        });

        Object.values(CMSIdToCollPage).forEach((cms) => {
          cms.pages?.forEach((page) => {
            if (havntRole) {
              state.pagesList[cms.slug][page.slug] = {
                default: true,
                edit: false,
                read: false,
                create: false,
                delete: false,
                settings: false,
              };
            } else if (
              roleData.role.pagesList[cms.slug][page.slug] === undefined
            ) {
              roleData.role.pagesList[cms.slug][page.slug] = {
                default: true,
                edit: false,
                read: false,
                create: false,
                delete: false,
                settings: false,
              };
            }
          });
          cms.collections?.forEach((coll) => {
            if (havntRole) {
              state.collectionsList[cms.slug][coll.slug] = {
                default: true,
                edit: false,
                read: false,
                create: false,
                delete: false,
                settings: false,
              };
            } else if (
              roleData.role.collectionsList[cms.slug][coll.slug] === undefined
            ) {
              roleData.role.collectionsList[cms.slug][coll.slug] = {
                default: true,
                edit: false,
                read: false,
                create: false,
                delete: false,
                settings: false,
              };
            }
          });
        });

        if (roleData.role === null) dispatch({ state });
        else dispatch({ state: roleData.role });

        setDataReady(true);
        setCmsList(CMSIdToCollPage);
      })
      .catch((err) => {
        errorCatch(err);
      });
  }, []);

  const tabs = [];
  const tabsBody = [];

  console.log("state :>> ", state);

  Object.values(cmsList).forEach((cms) => {
    const cmsSlug = cms.slug;
    tabs.push(
      <li class="nav-item" key={cms.slug + "-tab"}>
        <button
          class="nav-link "
          aria-current="page"
          id={`${cms.slug}-tab`}
          data-bs-toggle="tab"
          data-bs-target={`#${cms.slug}`}
          type="button"
          role="tab"
          aria-controls={`${cms.slug}`}
          aria-selected="false"
        >
          {cms.name}
        </button>
      </li>
    );

    tabsBody.push(
      <div
        class="tab-pane fade"
        id={`${cms.slug}`}
        role="tabpanel"
        aria-labelledby={`${cms.slug}-tab`}
        key={cms.slug + "-body"}
      >
        <div className="card rounded p-3 mb-4">
          <div className="pb-3 mb-3 border-bottom">
            {createLineEditAccess({
              e: { name: "Доступ к коллекциям", slug: "collections" },
              state,
              dispatch,
              cmsSlug: cmsSlug,
            })}
          </div>
          {cms.collections?.map((e) => {
            return createDynamicLineEditAccess({
              e,
              state,
              dispatch,
              field: "collectionsList",
              cmsSlug: cmsSlug,
            });
          })}
        </div>

        <div className="card rounded p-3 mb-4">
          <div className="pb-3 mb-3 border-bottom">
            {createLineEditAccess({
              e: { name: "Доступ к страницам", slug: "pages" },
              state,
              dispatch,
              cmsSlug: cmsSlug,
            })}
          </div>
          {cms.pages?.map((e) => {
            return createDynamicLineEditAccess({
              e,
              state,
              dispatch,
              field: "pagesList",
              cmsSlug: cmsSlug,
            });
          })}
        </div>
      </div>
    );
  });

  return (
    dataReady && (
      <main className="main">
        <div className="container-fluid">
          <h4 className="mb-4 mt-4">Администратор</h4>
          <div className="row">
            <div className="col-8">
              <div className="card rounded p-3 mb-4">
                <h5>Общие настройки</h5>
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    Название
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Администратор"
                    value={state.name}
                    onChange={(e) => {
                      state.name = e.target.value;
                      dispatch({ state });
                    }}
                  />
                </div>
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">
                    Доступ к редактированию списка CMS
                  </span>
                  <div className="form-check form-switch ">
                    <input
                      className="form-check-input pointer"
                      type="checkbox"
                      checked={state.cmsSettings?.edit}
                      onChange={({ target }) => {
                        state.cmsSettings = target.checked
                          ? {
                              create: true,
                              delete: true,
                              edit: true,
                              read: true,
                              settings: true,
                            }
                          : {
                              create: false,
                              delete: false,
                              edit: false,
                              read: false,
                              settings: false,
                            };
                        dispatch({ state });
                      }}
                    />
                  </div>
                </div>
              </div>
              <ul class="nav nav-tabs border-0" id="myTab" role="tablist">
                <li class="nav-item">
                  <button
                    class="nav-link p-2 active"
                    aria-current="page"
                    id="main-isntrument-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#main-isntrument"
                    type="button"
                    role="tab"
                    aria-controls="main-isntrument"
                    aria-selected="true"
                  >
                    Инструменты
                  </button>
                </li>
                {tabs}
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="main-isntrument"
                  role="tabpanel"
                  aria-labelledby="main-isntrument-tab"
                >
                  <div className="card rounded p-3 mb-4">
                    {staticPoints.map((e) => {
                      return createLineEditAccessMainSettings({
                        e,
                        state,
                        dispatch,
                      });
                    })}
                  </div>
                </div>
                {tabsBody}
              </div>

              {/* <div className="card rounded p-3 mb-4">
                <div className="pb-3 mb-3 border-bottom">
                  {createLineEditAccess({
                    e: { name: "Доступ к коллекциям", nameDB: "collections" },
                    state,
                    dispatch,
                  })}
                </div>
                {staticPointsCollections.map((e) => {
                  return createDynamicLineEditAccess({
                    e,
                    state,
                    dispatch,
                    field: "collectionsList",
                  });
                })}
              </div> */}

              {/* <div className="card rounded p-3 mb-4">
                <div className="pb-3 mb-3 border-bottom">
                  {createLineEditAccess({
                    e: { name: "Доступ к страницам", nameDB: "pages" },
                    state,
                    dispatch,
                  })}
                </div>
                {staticPointsPages.map((e) => {
                  return createDynamicLineEditAccess({
                    e,
                    state,
                    dispatch,
                    field: "pagesList",
                  });
                })}
              </div> */}
            </div>
            <div className="col-4">
              <div className=" card p-3 mb-4">
                <button
                  className="btn btn-primary mb-2"
                  onClick={(e) => {
                    api
                      .postRole(state)
                      .then((state) => {
                        console.log(`state`, state);
                        if (newSection) {
                          window.location.pathname =
                            "/settings/role/" + state.slug;
                        } else {
                          $(e.target).addClass("bg-success");
                          setTimeout(() => {
                            $(e.target).removeClass("bg-success");
                          }, 2500);
                          dispatch({ state });
                        }
                      })
                      .catch((err) => {
                        $(e.target).addClass("bg-danger");
                        setTimeout(() => {
                          $(e.target).removeClass("bg-danger");
                        }, 2500);
                        console.log(`err`, err);
                      });
                  }}
                >
                  Сохранить
                </button>
                <button className="btn btn-light mb-2">Отменить и выйти</button>
                <p className="text-secondary m-0 fs-14">
                  Правлено: 01.12.2020 12:10 (danil)
                </p>
              </div>
              <div className=" card p-3 mb-4">
                <h6>Отображение Endpoints</h6>
                <div className="row">
                  <div class="col-4 btn-group">
                    <button
                      type="button"
                      class={`btn ${
                        state.endpoints ? "bg-light" : "btn-primary"
                      }`}
                      onClick={() => {
                        state.endpoints = false;
                        dispatch({ state });
                      }}
                    >
                      OFF
                    </button>
                    <button
                      type="button"
                      class={`btn ${
                        !state.endpoints ? "bg-light" : "btn-primary"
                      }`}
                      onClick={() => {
                        state.endpoints = true;
                        dispatch({ state });
                      }}
                    >
                      ON
                    </button>
                  </div>
                </div>
                <p className="m-0 text-secondary fs-12">
                  Endpoints будут отображаться в админ. панели{" "}
                </p>
              </div>
              <div className=" card p-3 mb-4">
                <h6>Доступы</h6>
                <div className="mb-3">
                  <p className="m-0">Чтение и изменения</p>
                  <p className="m-0 text-secondary fs-12">
                    Позволяет только редактировать и просматривать запись
                  </p>
                </div>
                <div className="mb-3">
                  <p className="m-0">Только чтение</p>
                  <p className="m-0 text-secondary fs-12">
                    Позволяет только просматривать запись
                  </p>
                </div>
                <div className="mb-3">
                  <p className="m-0">Полный доступ</p>
                  <p className="m-0 text-secondary fs-12">
                    Позволяет создавать, редактировать, просматривать и удалять
                    запись
                  </p>
                </div>
                <div className="">
                  <p className="m-0">Запретить все</p>
                  <p className="m-0 text-secondary fs-12">
                    Запрещает создавать, редактировать, просматривать и удалять
                    запись
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  );
};

export default Role;
