import Select from "react-select";
import { changeData, errorCatch } from "../../../func/common";
import React, { useState, useEffect } from "react";

import { SERVER_URL } from "../../../func/constants";
import UploadMedia from "../../common/UploadMedia";
import EmptyMedia from "../../common/EmptyMedia";
import UploadMediaBlock from "../../common/UploadMediaBlock";
import ManyMedia from "../../common/ManyMedia";
import { ReactSortable } from "react-sortablejs";
import api from "../../../func/api";
import Ref from "./fields/Ref";
import Arr from "./fields/Arr";
import Automat from "./fields/Automat";
import RichText from "./fields/RichText";
import DatePicker from "../../common/DatePicker";

const switchCaseForFields = ({
  body,
  dispatch,
  data,
  path,
  activeIndex,
  namesRefColl,
  setNamesRefColl,
  dataRefColl,
  setDataRefColl,
  rowLength,
  funcOpenMedia,
  funcChooseFromMedia,
  defaultsNames,
}) => {
  let dataObjInside = path.reduce((obj, p) => {
    if (!obj[p]) obj[p] = {};
    return obj[p] || obj;
  }, data);

  let time = 0;
  let day = 0;

  const rowHTMLBlocks = [];
  const refBlock = [];

  switch (body[activeIndex].typeField) {
    case "automat":
      rowHTMLBlocks.push(
        <div className={`col-${12 / rowLength}`} key={body[activeIndex].nameDB}>
          <Automat
            changeData={changeData}
            data={data}
            path={path}
            dataObjInside={dataObjInside}
            bodyInsaid={body[activeIndex]}
            namesRefColl={namesRefColl}
            dataRefColl={dataRefColl}
            setNamesRefColl={setNamesRefColl}
            defaultsNames={defaultsNames}
            dispatch={dispatch}
          />
        </div>
      );
      break;
    case "arr":
      rowHTMLBlocks.push(
        <div className={`col-${12 / rowLength}`} key={body[activeIndex].nameDB}>
          <Arr
            changeData={changeData}
            data={data}
            path={path}
            dataObjInside={dataObjInside}
            bodyInsaid={body[activeIndex]}
            namesRefColl={namesRefColl}
            dataRefColl={dataRefColl}
            setNamesRefColl={setNamesRefColl}
            defaultsNames={defaultsNames}
            dispatch={dispatch}
          />
        </div>
      );
      break;
    case "ref":
      rowHTMLBlocks.push(
        <Ref
          changeData={changeData}
          data={data}
          path={path}
          dataObjInside={dataObjInside}
          bodyInsaid={body[activeIndex]}
          namesRefColl={namesRefColl}
          dataRefColl={dataRefColl}
          setNamesRefColl={setNamesRefColl}
          defaultsNames={defaultsNames}
          dispatch={dispatch}
        />
      );
      break;
    case "text":
      if (!dataObjInside[body[activeIndex].nameDB]) {
        dispatch({
          data: changeData({
            data,
            path: [...path, body[activeIndex].nameDB],
            value: { html: [], json: {}, lengthText: 0, text: "" },
          }),
        });
      }
      rowHTMLBlocks.push(
        <RichText
          changeData={changeData}
          data={data}
          path={path}
          dataObjInside={dataObjInside}
          rowLength={rowLength}
          bodyInsaid={body[activeIndex]}
          dispatch={dispatch}
        />
      );
      // rowHTMLBlocks.push(
      //   <div className={`col-${12 / rowLength}`} key={body[activeIndex].nameDB}>
      //     <h6>{body[activeIndex].name}</h6>
      //     <div className="text-edit mb-4">
      //       <EditorJs
      //         data={dataObjInside[body[activeIndex].nameDB].json}
      //         tools={EDITOR_JS_TOOLS}
      //         // enableReInitialize={true}
      //         onChange={(e, value) => {
      //           dispatch({
      //             data: changeData({
      //               data,
      //               path: [...path, body[activeIndex].nameDB],
      //               value,
      //             }),
      //           });
      //           // e.toolbar.open();
      //         }}
      //       />
      //     </div>
      //   </div>
      // );
      break;
    case "number":
      rowHTMLBlocks.push(
        <div className={`col-${12 / rowLength}`} key={body[activeIndex].nameDB}>
          <h6>{body[activeIndex].name}</h6>
          <div className="input-group mb-4">
            <input
              placeholder={
                body[activeIndex].placeholderField ?? "Введите число"
              }
              className="form-control"
              type="number"
              value={dataObjInside[body[activeIndex].nameDB]}
              onChange={(e) => {
                dispatch({
                  data: changeData({
                    data,
                    path: [...path, body[activeIndex].nameDB],
                    value: e.target.value,
                  }),
                });
              }}
            />
          </div>
        </div>
      );
      break;
    case "date":
      // const date =
      //   dataObjInside[body[activeIndex].nameDB] &&
      //   typeof dataObjInside[body[activeIndex].nameDB] === "string"
      //     ? dataObjInside[body[activeIndex].nameDB].split("T")
      //     : ["", ""];
      // console.log(
      //   "dataObjInside[body[activeIndex].nameDB] :>> ",
      //   dataObjInside[body[activeIndex].nameDB]
      // );

      // if (Date.parse(date[0])) {
      //   day = Date.parse(date[0]);
      // } else if (dataObjInside[body[activeIndex].nameDB]) {
      //   let newDate = new Date(dataObjInside[body[activeIndex].nameDB]);
      //   console.log("newDate :>> ", newDate);

      //   day = Date.parse(
      //     `${newDate.getDate()}.${newDate.getMonth()}.${newDate.getFullYear}`
      //   );
      //   time = Date.parse(newDate) - day;
      // }

      // const timeValue = date[1] ? date[1].split(":") : ["", ""];
      // if (+timeValue[0]) time = +timeValue[0] * 60 * 60 * 1000;

      rowHTMLBlocks.push(
        // <div className={`col-${12 / rowLength}`} key={body[activeIndex].nameDB}>
        //   <h6>{body[activeIndex].name}</h6>
        //   <form
        //     value={dataObjInside[body[activeIndex].nameDB]}
        //     onChange={(e) => {
        //       // dispatch({
        //       //   data: changeData({
        //       //     data,
        //       //     path: [...path, el.nameDB],
        //       //     value: e.target.value,
        //       //   }),
        //       // });
        //     }}
        //     className="row mb-4"
        //   >
        //     <div className="col">
        //       <div class="input-group">
        //         <label class="input-group-text">Дата</label>
        //         <input
        //           onChange={(e) => {
        //             day = e.target.valueAsNumber;

        //             dispatch({
        //               data: changeData({
        //                 data,
        //                 path: [...path, body[activeIndex].nameDB],
        //                 value: day + time,
        //               }),
        //             });
        //           }}
        //           defaultValue={date[0]}
        //           className="form-control"
        //           type="date"
        //         />
        //       </div>
        //     </div>
        //     <div className="col">
        //       <div class="input-group">
        //         <label class="input-group-text">Время</label>
        //         <input
        //           onChange={(e) => {
        //             time = e.target.valueAsNumber;
        //             if (day) {
        //               dispatch({
        //                 data: changeData({
        //                   data,
        //                   path: [...path, body[activeIndex].nameDB],
        //                   value: day + time,
        //                 }),
        //               });
        //             }
        //           }}
        //           className="form-control"
        //           type="time"
        //           defaultValue={timeValue[0] + ":" + timeValue[1]}
        //         />
        //       </div>
        //     </div>
        //   </form>
        // </div>
        <DatePicker
          changeData={changeData}
          data={data}
          path={path}
          dataObjInside={dataObjInside}
          rowLength={rowLength}
          bodyInsaid={body[activeIndex]}
          dispatch={dispatch}
          body={body}
          activeIndex={activeIndex}
        />
      );
      break;
    case "boolean":
      rowHTMLBlocks.push(
        <div className={`col-${12 / rowLength}`} key={body[activeIndex].nameDB}>
          <h6>{body[activeIndex].name}</h6>
          <div className="form-check form-switch mb-4">
            <input
              className="form-check-input"
              type="checkbox"
              checked={dataObjInside[body[activeIndex].nameDB]}
              onChange={(e) => {
                dispatch({
                  data: changeData({
                    data,
                    path: [...path, body[activeIndex].nameDB],
                    value: e.target.checked,
                  }),
                });
              }}
            />
          </div>
        </div>
      );
      break;
    case "image":
      if (body[activeIndex].isMulti)
        rowHTMLBlocks.push(
          <ManyMedia
            key={body[activeIndex].nameDB}
            pics={dataObjInside[body[activeIndex].nameDB]}
            name={body[activeIndex].name}
            openChooseMedia={() => {
              funcChooseFromMedia((imgData) => {
                const images = imgData;
                dispatch({
                  data: changeData({
                    data,
                    path: [
                      ...path,
                      body[activeIndex].nameDB,
                      dataObjInside[body[activeIndex].nameDB].length,
                    ],
                    value: images,
                  }),
                });
              });
            }}
            onChangeArray={(newArr) => {
              dispatch({
                data: changeData({
                  data,
                  path: [...path, body[activeIndex].nameDB],
                  value: newArr,
                }),
              });
            }}
            getData={(imgData) => {
              const imagesParse =
                typeof imgData === "string" ? JSON.parse(imgData) : imgData;
              const images = body[activeIndex].isMulti
                ? imagesParse
                : imagesParse[0];
              console.log("images :>> ", images);
              dispatch({
                data: changeData({
                  data,
                  path: [
                    ...path,
                    body[activeIndex].nameDB,
                    dataObjInside[body[activeIndex].nameDB].length,
                  ],
                  value: images[0],
                }),
              });
            }}
            setEditMedia={(pic, i) => {
              funcOpenMedia(pic, (imgData) => {
                const images =
                  typeof imgData === "string" ? JSON.parse(imgData) : imgData;
                dispatch({
                  data: changeData({
                    data,
                    path: [...path, body[activeIndex].nameDB, i],
                    value: images,
                  }),
                });
              });
            }}
          />
        );
      else
        rowHTMLBlocks.push(
          <div
            className={`col-${12 / rowLength}`}
            key={body[activeIndex].nameDB}
          >
            <h6>{body[activeIndex].name}</h6>
            <div className="card p-3 mb-4 bg-gray">
              <UploadMediaBlock
                activePic={dataObjInside[body[activeIndex].nameDB]}
                getData={(imgData) => {
                  const imagesParse =
                    typeof imgData === "string" ? JSON.parse(imgData) : imgData;
                  const images = body[activeIndex].isMulti
                    ? imagesParse
                    : imagesParse[0];

                  dispatch({
                    data: changeData({
                      data,
                      path: [...path, body[activeIndex].nameDB],
                      value: images,
                    }),
                  });
                }}
                setEditMedia={() => {
                  funcOpenMedia(
                    dataObjInside[body[activeIndex].nameDB],
                    (imgData) => {
                      const imagesParse =
                        typeof imgData === "string"
                          ? JSON.parse(imgData)
                          : imgData;

                      const images = body[activeIndex].isMulti
                        ? imagesParse
                        : imagesParse;

                      dispatch({
                        data: changeData({
                          data,
                          path: [...path, body[activeIndex].nameDB],
                          value: images,
                        }),
                      });
                    }
                  );
                }}
                isCollection={true}
                openChooseMedia={() => {
                  funcChooseFromMedia((imgData) => {
                    const images = imgData;
                    dispatch({
                      data: changeData({
                        data,
                        path: [...path, body[activeIndex].nameDB],
                        value: images,
                      }),
                    });
                  });
                }}
              />
            </div>
          </div>
          // <div className={`col-${12 / rowLength}`} key={body[activeIndex].nameDB}>
          //   <h6>{body[activeIndex].name}</h6>

          //   <div
          //     class="card card-bg text-white position-relative cursor-pointer"
          //     style={{
          //       backgroundImage: `url(${
          //         SERVER_URL +
          //         "/" +
          //         dataObjInside[body[activeIndex].nameDB][0]?.url
          //       })`,
          //       backgroundSize: "contain",
          //       backgroundRepeat: "no-repeat",
          //       backgroundPosition: "center",
          //     }}
          //     // onDrop={(e) => {
          //     //   e.preventDefault();
          //     //   const formData = new FormData();
          //     //   formData.append("uploadImg", e.dataTransfer.files[0]);
          //     //   let xhr = new XMLHttpRequest();

          //     //   xhr.onloadend = () => {
          //     //     if (xhr.status == 200) {
          //     //       console.log("Успех");
          //     //       console.log(`xhr`, xhr);
          //     //       // this.getData();
          //     //     } else {
          //     //       console.log("Ошибка " + this.status);
          //     //     }
          //     //   };

          //     //   xhr.open("POST", SERVER_URL + "/upload-media");
          //     //   xhr.send(formData);
          //     // }}
          //     onClick={() => {
          //       funcOpenMedia(
          //         dataObjInside[body[activeIndex].nameDB][0],
          //         (imgData) => {

          //         }
          //       );
          //     }}
          //   >
          //     {dataObjInside[body[activeIndex].nameDB] === "" && (
          //       <i className="bi bi-image collection-banner text-center" />
          //     )}
          //     <p class="card-text text-dark fs-5 position-absolute top-50 start-50 translate-middle">
          //       Нажмите, что бы выбрать ресурс <br /> или перетащите файл в эту
          //       область
          //     </p>
          //   </div>
          //   {/* <EmptyMedia
          //     getData={(imgData) => {
          //       const images = body[activeIndex].isMulti
          //         ? JSON.parse(imgData)
          //         : JSON.parse(imgData)[0];
          //       dispatch({
          //         data: changeData({
          //           data,
          //           path: [...path, body[activeIndex].nameDB],
          //           value: images,
          //         }),
          //       });
          //     }}
          //   /> */}
          // </div>
        );
      break;
    case "email":
      rowHTMLBlocks.push(
        <div className={`col-${12 / rowLength}`} key={body[activeIndex].nameDB}>
          <h6>{body[activeIndex].name}</h6>
          <div class="input-group mb-4">
            <label class="input-group-text">Email</label>
            <input
              className="form-control"
              type="email"
              value={dataObjInside[body[activeIndex].nameDB]}
              placeholder="Введите ваш Email"
              autoComplete="new-password"
              onChange={(e) => {
                dispatch({
                  data: changeData({
                    data,
                    path: [...path, body[activeIndex].nameDB],
                    value: e.target.value,
                  }),
                });
              }}
            />
          </div>
        </div>
      );
      break;
    case "password":
      rowHTMLBlocks.push(
        <div className={`col-${12 / rowLength}`} key={body[activeIndex].nameDB}>
          <h6>{body[activeIndex].name}</h6>
          <div className="input-group mb-4">
            <input
              id="password"
              placeholder="******"
              className="form-control w-100"
              type="password"
              autoComplete="new-password"
              value={dataObjInside[body[activeIndex].nameDB]}
              onChange={(e) => {
                dispatch({
                  data: changeData({
                    data,
                    path: [...path, body[activeIndex].nameDB],
                    value: e.target.value,
                  }),
                });
              }}
            />
            <label for="password" className="password-label text-secondary">
              Ваш пароль должен содержать от 8 до 20 символов
            </label>
          </div>
        </div>
      );
      break;
    case "input":
      const placeholder = body[activeIndex].placeholderField ?? "Введите текст";
      if (body[activeIndex].settingField === "textarea")
        rowHTMLBlocks.push(
          <div
            className={`col-${12 / rowLength}`}
            key={body[activeIndex].nameDB + activeIndex}
          >
            <h6>{body[activeIndex].name}</h6>
            <div className="input-group mb-4">
              <textarea
                className="form-control"
                type="text"
                placeholder={placeholder}
                value={dataObjInside[body[activeIndex].nameDB]}
                onChange={(e) => {
                  // data[el.nameDB] = e.target.value;
                  dispatch({
                    data: changeData({
                      data,
                      path: [...path, body[activeIndex].nameDB],
                      value: e.target.value,
                    }),
                  });
                }}
              />
            </div>
          </div>
        );
      else
        rowHTMLBlocks.push(
          <div
            className={`col-${12 / rowLength}`}
            key={body[activeIndex].nameDB + activeIndex}
          >
            <h6>{body[activeIndex].name}</h6>
            <div className="input-group mb-4">
              <input
                className="form-control"
                type="text"
                placeholder={placeholder}
                value={dataObjInside[body[activeIndex].nameDB]}
                onChange={(e) => {
                  // data[el.nameDB] = e.target.value;
                  dispatch({
                    data: changeData({
                      data,
                      path: [...path, body[activeIndex].nameDB],
                      value: e.target.value,
                    }),
                  });
                }}
              />
            </div>
          </div>
        );
      break;
    case "select":
      let list =
        dataObjInside[body[activeIndex].nameDB] && body[activeIndex].isMulti
          ? dataObjInside[body[activeIndex].nameDB].map((option, i) => {
              return (
                <div className="me-4">
                  <span>{option}</span>
                  <i
                    onClick={() => {
                      dataObjInside[body[activeIndex].nameDB].splice(i, 1);
                      dispatch({ data });
                    }}
                    className="ms-2 bi bi-x-circle-fill text-primary pointer"
                  />
                </div>
              );
            })
          : [];
      let optionsSelect = [];
      body[activeIndex].selectVars.forEach((e) =>
        dataObjInside[body[activeIndex].nameDB]
          ? dataObjInside[body[activeIndex].nameDB].includes(e)
            ? []
            : optionsSelect.push({ label: e, value: e })
          : optionsSelect.push({ label: e, value: e })
      );

      rowHTMLBlocks.push(
        <div
          className={`col-${12 / rowLength}`}
          key={body[activeIndex].nameDB + activeIndex}
        >
          <h6>{body[activeIndex].name}</h6>
          <div className="mb-4 border-0">
            <Select
              className="mb-2"
              options={optionsSelect}
              placeholder="Выберете нужное значение"
              value={
                body[activeIndex].isMulti
                  ? null
                  : dataObjInside[body[activeIndex].nameDB]
                  ? {
                      label: dataObjInside[body[activeIndex].nameDB],
                      value: dataObjInside[body[activeIndex].nameDB],
                    }
                  : null
              }
              // styles={colourStyles}
              onChange={(e) => {
                dispatch({
                  data: changeData({
                    data,
                    path: [...path, body[activeIndex].nameDB],
                    value: body[activeIndex].isMulti
                      ? dataObjInside[body[activeIndex].nameDB]
                        ? [...dataObjInside[body[activeIndex].nameDB], e.value]
                        : [e.value]
                      : e.value,
                  }),
                });
              }}
            />
            {body[activeIndex].isMulti ? (
              <div className="d-flex">{list}</div>
            ) : null}
          </div>
        </div>
      );
      break;

    case "component":
      let compData = {};
      // let dataArr = [];
      let compHTML = [];

      if (body[activeIndex].isMulti) {
        console.log(`body[activeIndex]`, body[activeIndex]);

        body[activeIndex].component.body.forEach((elChild) => {
          if (elChild.typeField === "component") {
            if (elChild.isMulti) {
              compData[elChild.nameDB] = [];
            } else {
              compData[elChild.nameDB] = {};
            }
          } else if (elChild.typeField === "image") {
            if (elChild.isMulti) compData[elChild.nameDB] = [];
          } else {
            compData[elChild.nameDB] = "";
          }
        });

        // let childsLength = dataObjInside[body[activeIndex].nameDB].length;
        let colIndex = 0;
        let rowLengthComp = body[activeIndex].component.cols[colIndex]
          ? body[activeIndex].component.cols[colIndex].length
          : 1;
        let rowLengthCompCounter = rowLengthComp;

        compHTML = dataObjInside[body[activeIndex].nameDB]?.map(
          (elChild, i) => {
            const childsHTML = [];
            let childsTimeHTML = [];
            body[activeIndex].component.body.forEach((child, iSchema) => {
              if (rowLengthCompCounter === 0) {
                colIndex += 1;
                rowLengthComp = body[activeIndex].component.cols[colIndex]
                  ? body[activeIndex].component.cols[colIndex].length
                  : 1;
                rowLengthCompCounter = rowLengthComp;
                childsHTML.push(<div className="row">{childsTimeHTML}</div>);
                childsTimeHTML = [];
              }
              rowLengthCompCounter -= 1;
              childsTimeHTML.push(
                switchCaseForFields({
                  body: body[activeIndex].component.body,
                  data,
                  dispatch,
                  activeIndex: iSchema,
                  rowLength: rowLengthComp,
                  path: [...path, body[activeIndex].nameDB, i],
                  namesRefColl,
                  setNamesRefColl,
                  dataRefColl,
                  setDataRefColl,
                  funcChooseFromMedia,
                  defaultsNames,
                })
              );
            });
            childsHTML.push(<div className="row">{childsTimeHTML}</div>);
            childsTimeHTML = [];

            return (
              <div
                className={`d-flex justify-between bg-white big-component mb-4 col-${
                  12 / rowLength
                }`}
                key={body[activeIndex].nameDB + i}
              >
                <div className="position-relative p-3 border rounded-start big-component-left pointer">
                  <i className="position-absolute top-50 start-50 translate-middle bi bi-arrows-move" />
                </div>
                <div className="w-100 p-3 pt-2">{childsHTML}</div>
                <div
                  className="position-relative p-3 rounded-end big-component-right pointer"
                  onClick={() => {
                    dataObjInside[body[activeIndex].nameDB].splice(i, 1);
                    dispatch({ data });
                  }}
                >
                  <i className="position-absolute top-50 start-50 translate-middle bi bi-x" />
                </div>
              </div>
            );
          }
        );
      } else {
        compHTML = body[activeIndex].component.body.map((child, i) => {
          return switchCaseForFields({
            body: body[activeIndex].component.body,
            data,
            dispatch,
            activeIndex: i,
            rowLength: 1,
            path: [...path, body[activeIndex].nameDB],
            namesRefColl,
            setNamesRefColl,
            dataRefColl,
            setDataRefColl,
            funcChooseFromMedia,
            defaultsNames,
          });
        });
      }

      rowHTMLBlocks.push(
        body[activeIndex].isMulti ? (
          <div
            className={`col-${12 / rowLength}`}
            key={body[activeIndex].nameDB}
          >
            <h6 className="mb-2">{body[activeIndex].name}</h6>

            <div className="card bg-gray p-3 mb-3 border-0">
              {compHTML?.length ? (
                <ReactSortable
                  animation={200}
                  delayOnTouchStart={true}
                  delay={2}
                  list={dataObjInside[body[activeIndex].nameDB]}
                  setList={(newOrderArr) => {
                    console.log(`newOrderArr`, newOrderArr);
                    //   setOrderMenu(newOrderArr);
                    dataObjInside[body[activeIndex].nameDB] = newOrderArr;
                    dispatch({ data });
                  }}
                  group="shared"
                  // className="row"
                  swapThreshold={0.65}
                  handle=".big-component-left"
                >
                  {compHTML}
                </ReactSortable>
              ) : (
                <p className="text-secondary text-center">
                  Еще нет записей. Нажмите кнопку ниже, чтобы добавить одну
                </p>
              )}
              <div className="w-100 mt-2 d-flex justify-content-center">
                <div
                  className="block-group-btn pointer d-flex justify-content-center"
                  onClick={() => {
                    if (
                      !dataObjInside[body[activeIndex].nameDB] ||
                      !Array.isArray(dataObjInside[body[activeIndex].nameDB])
                    )
                      dataObjInside[body[activeIndex].nameDB] = [];

                    dataObjInside[body[activeIndex].nameDB].push(compData);
                    dispatch({ data });
                  }}
                >
                  <i className="bi bi-plus text-primary" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`col-${12 / rowLength}`}
            key={body[activeIndex].nameDB}
          >
            <h6 className="mb-2">{body[activeIndex].name}</h6>
            <div className="card bg-gray p-3 mb-3 border-0">
              <div className="w-100 p-3 pt-2">{compHTML}</div>
            </div>
          </div>
        )
      );
      break;

    default:
      break;
  }

  return rowHTMLBlocks;
};

export default switchCaseForFields;
