import $ from "jquery";
import { SERVER_URL } from "../func/constants";
import { CONSTANT_MENU } from "./constants";

const changeDataOld = ({ data, value, type, field, i, updFunk, block }) => {
  if (!block) {
    if (i !== undefined) {
      data[type][i][field] = value;
    } else if (field !== undefined) {
      if (type) {
        data[type][field] = value;
      } else {
        data[field] = value;
      }
    } else {
      data[type] = value;
    }
  } else {
    if (type) {
      data[type].blocks[i][field] = value;
    } else {
      data.blocks[i].data[field] = value;
    }
  }
  if (updFunk !== undefined) {
    updFunk(data);
  } else {
    return data;
  }
};

const changeData = ({ data, path, value }) => {
  const lastKey = path.pop();

  const editObj = path.reduce((obj, p) => {
    if (obj[p]) return obj[p];
    else {
      obj[p] = {};
      return obj[p];
    }
  }, data);

  editObj[lastKey] = value;

  return data;
};

const upload = (file, target, callback) => {
  let cmsSlug = null;
  if (window.location.pathname.includes("/front/")) {
    let path = window.location.pathname.split("/front/");
    path.shift();

    if (path.length) {
      path = path[0].split("/")[0];
    }

    cmsSlug = path;
  }
  if (!cmsSlug) return;

  let xhr = new XMLHttpRequest();
  const progress = $(target).parent().parent().parent().find(".progress");
  // отслеживаем процесс отправки
  xhr.upload.onprogress = function (event) {
    //   console.log(`Отправлено ${event.loaded} из ${event.total}`);

    progress.addClass("active");
    progress
      .find(".progress-bar")
      .css("width", `${(event.loaded / event.total) * 100}%`);
  };

  // Ждём завершения: неважно, успешного или нет
  xhr.onloadend = function () {
    progress.removeClass("active");
    if (xhr.status == 200) {
      console.log("Успех");
      callback(JSON.parse(xhr.response));
    } else {
      console.log("Ошибка " + this.status);
    }
  };

  xhr.open("POST", SERVER_URL + "/" + cmsSlug + "/upload-media");
  xhr.send(file);
};

const changeImageData = ({ data, e, type, field, i, updFunk, block }) => {
  if (e.target.files.length) {
    const formData = new FormData();
    formData.append("uploadImg", e.target.files[0]);

    upload(formData, e.target, (result) => {
      console.log(`result`, result);
      if (!block) {
        if (i !== undefined) {
          if (data[type][i][field]) {
            data[type][i][field].link = result.fileName;
          } else {
            data[type][i][field] = {};
            data[type][i][field].link = result.fileName;
          }
        } else {
          if (data[field]) {
            data[type][field].link = result.fileName;
          } else {
            data[type][field] = {};
            data[type][field].link = result.fileName;
          }
        }
      } else {
        if (type) {
          if (data[type].blocks[i][field]) {
            data[type].blocks[i][field].link = result.fileName;
          } else {
            data[type].blocks[i][field] = {};
            data[type].blocks[i][field].link = result.fileName;
          }
        } else {
          if (data.blocks[i].data[field]) {
            data.blocks[i].data[field].link = result.fileName;
          } else {
            data.blocks[i].data[field] = {};
            data.blocks[i].data[field].link = result.fileName;
          }
        }
      }
      updFunk(data);
    });
  }
};

const createDefaultMenu = (points) => {
  const menuArr = [
    {
      name: "Конструктор",
      link: "/constructor",
      type: "constructorCMS",
      icon: "circle",
      showIcon: true,
    },
  ];
  points[0].forEach((collect) => {
    menuArr.push({
      name: collect.name,
      link: `/pages/${collect.slug}`,
      type: "pages",
      slug: collect.slug,
      icon: "circle",
      showIcon: true,
    });
  });
  points[1].forEach((collect) => {
    menuArr.push({
      name: collect.name,
      link: `/collections/${collect.slug}`,
      type: "collections",
      slug: collect.slug,
      icon: "circle",
      showIcon: true,
    });
  });

  menuArr.push(...CONSTANT_MENU);
  return menuArr;
};

const copyToBuffer = (text) => {
  const area = document.createElement("textarea");
  document.body.appendChild(area);
  area.value = text;
  area.select();
  document.execCommand("copy");
  document.body.removeChild(area);
};

const errorCatch = (err) => {
  console.error(`err`, err);
};

export {
  changeData,
  changeImageData,
  createDefaultMenu,
  copyToBuffer,
  errorCatch,
};
