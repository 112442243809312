import React, { useState, useEffect } from "react";
import Select from "react-select";
import { ReactSortable } from "react-sortablejs";
import api from "../../func/api";
import { errorCatch } from "../../func/common";
import { customStyles } from "./funcs/common.js";

const inForModelSyncFields = [
  { label: "Перенести", value: "=" },
  { label: "Суммировать числа", value: "+" },
  // { label: "*", value: "*" },
  { label: "Собрать массив", value: "[]" },
  { label: "Найти наибольшее", value: ">" },
  { label: "Найти наименьшее", value: "<" },
];

function InForModelContainer({
  typeModel,
  nameColl,
  schemaNameDB,
  fields,
  selectOps,
  lockName,
}) {
  const [savesSync, setSavesSync] = useState([]);
  const [data, setData] = useState(null);
  const [saveFunc, setSaveFunc] = useState(null);
  const [newSync, setNewSync] = useState(false);
  const [fieldsSetting, setFieldsSetting] = useState(false);

  console.log("newSync 1:>> ", newSync);
  console.log("fieldsSetting 1:>> ", fieldsSetting);

  const defaultData = {
    name: "",
    model: schemaNameDB,
    settings: {
      schemaIn: "",
      schemaFor: nameColl,
      fieldIn: "",
      fieldFor: "",
      createRefs: false,
      refsIn: "",
      refsFor: "",
      additFields: [],
    },
    active: true,
  };

  if (lockName) defaultData.lockName = lockName;

  useEffect(() => {
    if (data === null)
      api
        .getWebhookSync(typeModel, nameColl)
        .then((result) => {
          setSavesSync(result);
        })
        .catch((err) => {
          errorCatch(err);
        });
  }, [nameColl, data]);

  const savesSyncHTML = savesSync.map((saSy, i) => {
    return (
      <div className="save-preset-line p-3 ">
        <div
          className="save-preset-line__name"
          onClick={() => {
            setData(saSy);
            setFieldsSetting(true);
          }}
        >
          <p className="m-0">{saSy.name}</p>
        </div>
        <div className="save-preset-line__delete d-flex justify-content-center">
          <div className="form-check form-switch ">
            <input
              className="form-check-input pointer"
              type="checkbox"
              checked={saSy.active}
              onChange={({ target }) => {
                savesSync[i].active = target.checked;
                setSavesSync([...savesSync]);
                api
                  .postInForModels(typeModel, nameColl, savesSync[i])
                  .then((result) => {
                    console.log(`result`, result);
                  })
                  .catch((err) => {
                    errorCatch(err);
                  });
              }}
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="">
      <div className="d-flex bg-light align-items-center mb-4 p-3">
        <h6 className="m-0">Заполнение коллекции</h6>
        <div className="ms-auto">
          {data !== null && (
            <>
              {data._id !== undefined && (
                <button
                  className="btn btn-outline-danger me-2"
                  onClick={() => {
                    api
                      .deleteInForModels(typeModel, nameColl, data)
                      .then((result) => {
                        setData(null);
                      })
                      .catch((err) => {
                        errorCatch(err);
                      });
                  }}
                >
                  Удалить
                </button>
              )}
              <button
                className="btn btn-outline-primary me-2"
                onClick={() => {
                  if (newSync && fieldsSetting) {
                    setFieldsSetting(false);
                  } else {
                    setData(null);
                    setNewSync(false);
                    setFieldsSetting(false);
                  }
                }}
              >
                Назад
              </button>
            </>
          )}
          <button
            className="btn btn-primary"
            onClick={() => {
              if (newSync && !fieldsSetting) {
                setFieldsSetting(true);
              } else if (data === null) {
                setNewSync(true);
                setData(defaultData);
              } else {
                api
                  .postInForModels(typeModel, nameColl, data)
                  .then((result) => {
                    console.log(`result`, result);
                  })
                  .catch((err) => {
                    errorCatch(err);
                  });
              }
            }}
          >
            {newSync && !fieldsSetting
              ? "Далее"
              : data !== null
              ? "Сохранить"
              : "Создать"}
          </button>
        </div>
      </div>
      {
        data ? (
          <InForModel
            typeModel={typeModel}
            nameColl={nameColl}
            data={data}
            setData={setData}
            setSaveFunc={setSaveFunc}
            fields={fields}
            selectOps={selectOps}
            newSync={newSync}
            fieldsSetting={fieldsSetting}
          />
        ) : savesSyncHTML.length ? (
          savesSyncHTML
        ) : (
          <p className="p-3 pt-0 ps-16">
            Вы можете создать правила для заполнения текущей коллекции контентом
            из других коллекций.
          </p>
        )
        // <>
        //   {savesSyncHTML}
        //   <div className="w-100 mt-4 d-flex justify-content-center">
        //     <div
        //       className="block-group-btn pointer d-flex justify-content-center"
        //       onClick={() => {
        //         // setAdditFields([...additFields, { from: "", to: "" }]);
        //         setSelectData(
        //           <InForModel
        //             typeModel={typeModel}
        //             nameColl={nameColl}
        //             saveData={defaultData}
        //             setSelectData={setSelectData}
        //           />
        //         );
        //       }}
        //     >
        //       <i className="bi bi-plus text-primary" />
        //     </div>
        //   </div>
        // </>
      }
    </div>
  );
}

function InForModel({
  typeModel,
  nameColl,
  data,
  setData,
  fields,
  selectOps,
  setSaveFunc,
  newSync,
  fieldsSetting,
}) {
  const filterHTML = [];
  const [schemas, setSchemas] = useState([]);
  const [openMainSettings, setOpenMainSettings] = useState(false);
  // const [data, setData] = useState(saveData);
  // const [schemaChoose, setSchemaChoose] = useState("");
  // const [schemaChooseName, setSchemaChooseName] = useState("");
  const [schemaToFields, setSchemaToFields] = useState([]);
  // const [fieldIn, setFieldIn] = useState("");
  // const [fieldFor, setFieldFor] = useState(1);
  // const [createRefs, setCreateRefs] = useState(false);
  // const [refsIn, setRefsIn] = useState();
  // const [refsFor, setRefsFor] = useState([]);
  // const [additFields, setAdditFields] = useState([]);

  console.log("newSync :>> ", newSync);
  console.log("fieldsSetting :>> ", fieldsSetting);
  console.log("data :>> ", data);

  useEffect(() => {
    api
      .getConstr()
      .then((res) => {
        const shemasVar = res.filter((el) => el.slug !== nameColl);
        setSchemas(shemasVar.map((el) => ({ label: el.name, value: el.slug })));
      })
      .catch((err) => errorCatch(err));
  }, []);

  useEffect(() => {
    api
      .getSchema(data.settings.schemaIn)
      .then((result) => {
        if (result.body)
          setSchemaToFields(
            result.body.map(({ name, nameDB, typeField }) => ({
              label: name,
              value: nameDB,
              typeField: typeField,
            }))
          );
      })
      .catch((err) => errorCatch(err));
  }, [data.settings.schemaIn]);


  // useEffect(() => {
  //   setSaveFunc(() => {
  //     return () => {
  //       api
  //         .postInForModels(typeModel, nameColl, data)
  //         .then((result) => {
  //           console.log(`result`, result);
  //         })
  //         .catch((err) => {
  //           errorCatch(err);
  //         });
  //     };
  //   });
  // }, [data]);

  return (
    <>
      {/* <div className="d-flex mb-4 p-3 pt-0">
        <button
          className="btn btn-outline-danger me-2"
          onClick={() => {
            setSelectData(null);
          }}
        >
          Назад
        </button>
        <div className="ms-auto">
          <button
            className="btn btn-outline-primary me-2"
            onClick={() => {
              data.saveAndRun = true;
              api
                .postInForModels(typeModel, nameColl, data)
                .then((result) => {
                  console.log(`result`, result);
                })
                .catch((err) => {
                  errorCatch(err);
                });
            }}
          >
            Сохранить и применить
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              api
                .postInForModels(typeModel, nameColl, data)
                .then((result) => {
                  console.log(`result`, result);
                })
                .catch((err) => {
                  errorCatch(err);
                });
            }}
          >
            Сохранить
          </button>
        </div>
      </div> */}
      {!newSync && (
        <p
          className="ps-3 text-primary pointer text-decoration-dashed"
          onClick={() => {
            setOpenMainSettings(!openMainSettings);
          }}
        >
          Редактировать основные настройки
        </p>
      )}
      {(!fieldsSetting || openMainSettings) && (
        <div className="pe-3 ps-3">
          <p>Основные настройки</p>
          <div className=" col col-12 input-group mb-3">
            <span class="input-group-text">Взять контент из</span>
            <Select
              options={schemas}
              styles={customStyles}
              fields={fields}
              className="flex-grow-1"
              value={{ label: data.name, value: data.settings.schemaIn }}
              // value={
              //   el.key
              //     ? { value: el.key, label: el.label, type: fields[el.label] }
              //     : null
              // }

              onChange={({ value, label }) => {
                setData((data) => {
                  data.name = label;
                  data.settings.schemaIn = value;
                  return { ...data };
                });
                // setSchemaChoose(value);
                // setSchemaChooseName(label);
              }}
            ></Select>
          </div>
          <div className=" col input-group mb-3">
            <span class="input-group-text">Сгруппировать по</span>
            <Select
              options={schemaToFields}
              styles={customStyles}
              fields={fields}
              className="flex-grow-1"
              value={schemaToFields.find(
                (el) => el.value === data.settings.fieldIn
              )}
              // value={
              //   el.key
              //     ? { value: el.key, label: el.label, type: fields[el.label] }
              //     : null
              // }
              onChange={({ value }) => {
                setData((data) => {
                  data.settings.fieldIn = value;
                  return { ...data };
                });
                // setFieldIn(value);
              }}
            ></Select>
          </div>
          <div className=" col input-group mb-3">
            <span class="input-group-text">Вывести данные в</span>
            <Select
              options={selectOps.filter((el) => el.type !== "ref")}
              styles={customStyles}
              fields={fields}
              className="flex-grow-1"
              value={selectOps.find(
                (el) => el.value === data.settings.fieldFor
              )}
              // value={
              //   el.key
              //     ? { value: el.key, label: el.label, type: fields[el.label] }
              //     : null
              // }
              onChange={({ value }) => {
                setData((data) => {
                  data.settings.fieldFor = value;
                  return { ...data };
                });
                // setFieldFor(value);
              }}
            ></Select>
          </div>
          {data.lockName === "categories" && (
            <div className=" col mb-3">
              <p class="mb-1">Очередность вложенности категорий</p>

              <ReactSortable
                animation={200}
                list={
                  data.settings.categoryOrder ? data.settings.categoryOrder : []
                }
                setList={(newOrderArr) => {
                  setData((d) => {
                    d.settings.categoryOrder = [...newOrderArr];
                    return d;
                  });
                }}
                swapThreshold={0.65}
                handle=".bi-list"
              >
                {data.settings.categoryOrder
                  ? data.settings.categoryOrder.map((ord, i) => {
                      return (
                        <div className="d-flex mb-3 align-items-center">
                          <i className="bi bi-list"></i>
                          <Select
                            options={schemaToFields}
                            styles={customStyles}
                            fields={fields}
                            className="flex-grow-1"
                            value={schemaToFields.find(
                              (el) => el.value === ord
                            )}
                            // value={
                            //   el.key
                            //     ? { value: el.key, label: el.label, type: fields[el.label] }
                            //     : null
                            // }
                            onChange={({ value }) => {
                              setData((data) => {
                                data.settings.categoryOrder[i] = value;
                                return { ...data };
                              });
                              // setFieldIn(value);
                            }}
                          ></Select>
                        </div>
                      );
                    })
                  : null}
              </ReactSortable>

              <div className="w-100 mt-4 d-flex justify-content-center mb-4">
                <div
                  className="block-group-btn pointer d-flex justify-content-center"
                  onClick={() => {
                    setData((data) => {
                      if (!data.settings.categoryOrder) {
                        data.settings.categoryOrder = [];
                      }

                      data.settings.categoryOrder.push("");
                      return { ...data };
                    });
                  }}
                >
                  <i className="bi bi-plus text-primary" />
                </div>
              </div>
            </div>
          )}
          <div className=" col input-group mb-1" style={{ zIndex: "0" }}>
            <span class="me-4">Создать связи</span>
            <div className="form-check form-switch ">
              <input
                className="form-check-input pointer"
                type="checkbox"
                checked={data.settings.createRefs}
                onChange={({ target }) => {
                  setData((data) => {
                    data.settings.createRefs = target.checked;
                    return { ...data };
                  });
                }}
              />
            </div>
            {/* <div class="btn-group mx-3" style={{ zIndex: "0" }}>
            <button
              type="button"
              class={`btn ${
                data.settings.createRefs ? "btn-primary" : "bg-light"
              }`}
              onClick={() =>
                setData((data) => {
                  data.settings.createRefs = true;
                  return { ...data };
                })
              }
              style={{ zIndex: "0" }}
            >
              Да
            </button>
            <button
              type="button"
              class={`btn ${
                !data.settings.createRefs ? "btn-primary" : "bg-light"
              }`}
              onClick={() =>
                setData((data) => {
                  data.settings.createRefs = false;
                  return { ...data };
                })
              }
              style={{ zIndex: "0" }}
            >
              Нет
            </button>
          </div> */}
          </div>
          {data.settings.createRefs && (
            <>
              <div className=" col input-group mb-3">
                <span class="input-group-text">Поле ref модели "Из"</span>
                <Select
                  options={schemaToFields.filter(
                    (el) => el.typeField === "ref"
                  )}
                  styles={customStyles}
                  fields={fields}
                  className="flex-grow-1"
                  value={schemaToFields.find(
                    (el) => el.value === data.settings.refsIn
                  )}
                  onChange={({ value }) => {
                    setData((data) => {
                      data.settings.refsIn = value;
                      return { ...data };
                    });
                    // setRefsIn(value);
                  }}
                ></Select>
              </div>
              <div className=" col input-group mb-3">
                <span class="input-group-text">Поле ref модели "В"</span>
                <Select
                  options={selectOps.filter((el) => el.type === "ref")}
                  styles={customStyles}
                  fields={fields}
                  className="flex-grow-1"
                  value={selectOps.find(
                    (el) => el.value === data.settings.refsFor
                  )}
                  onChange={({ value }) => {
                    setData((data) => {
                      data.settings.refsFor = value;
                      return { ...data };
                    });
                    // setRefsFor(value);
                  }}
                ></Select>
              </div>
            </>
          )}
        </div>
      )}
      {fieldsSetting && (
        <InForModelFields
          data={data}
          setData={setData}
          fields={fields}
          selectOps={selectOps}
          schemaToFields={schemaToFields}
        />
      )}
    </>
  );
}

const InForModelFields = ({
  data,
  setData,
  fields,
  selectOps,
  schemaToFields,
}) => {
  const additFieldsHTML = data.settings.additFields.map((field, i) => {
    return (
      <div className="row mb-3 pe-5 ps-3 position-relative" key={i}>
        <div className="col-4">
          <Select
            options={inForModelSyncFields}
            defaultValue={
              data.settings.additFields[i].type
                ? inForModelSyncFields.find(
                    (e) => e.value === data.settings.additFields[i].type
                  )
                : { label: "Перенести", value: "=" }
            }
            styles={customStyles}
            className="flex-grow-1"
            // value={
            //   el.key
            //     ? { value: el.key, label: el.label, type: fields[el.label] }
            //     : null
            // }
            placeholder=""
            onChange={({ value }) => {
              setData((data) => {
                data.settings.additFields[i].type = value;
                return { ...data };
              });
              // additFields[i].type = value;
              // setAdditFields([...additFields]);
            }}
          ></Select>
        </div>
        <div className="col-4">
          <Select
            options={schemaToFields}
            styles={customStyles}
            fields={fields}
            className="flex-grow-1"
            // value={
            //   el.key
            //     ? { value: el.key, label: el.label, type: fields[el.label] }
            //     : null
            // }
            value={schemaToFields.find(
              (el) => el.value === data.settings.additFields[i].from
            )}
            onChange={({ value }) => {
              setData((data) => {
                data.settings.additFields[i].from = value;
                return { ...data };
              });
              // additFields[i].from = value;
              // setAdditFields([...additFields]);
            }}
          ></Select>
        </div>
        <div className="col-4">
          <Select
            options={selectOps}
            styles={customStyles}
            fields={fields}
            className="flex-grow-1"
            // value={
            //   el.key
            //     ? { value: el.key, label: el.label, type: fields[el.label] }
            //     : null
            // }
            value={selectOps.find((el) => {
              return el.value === data.settings.additFields[i].to;
            })}
            onChange={({ value }) => {
              setData((data) => {
                data.settings.additFields[i].to = value;
                return { ...data };
              });
              // additFields[i].to = value;
              // setAdditFields([...additFields]);
            }}
          ></Select>
        </div>

        <span
          className="position-absolute x-position block-group-btn pointer d-flex justify-content-center"
          onClick={() => {
            setData((data) => {
              data.settings.additFields.splice(i, 1);
              return { ...data };
            });
            // additFields.splice(i, 1);
            // setAdditFields([...additFields]);
          }}
        >
          <i className="bi bi-plus text-danger rotate" />
        </span>
      </div>
    );
  });

  return (
    <div>
      {additFieldsHTML.length ? (
        <>
          <div className="row mb-3 pe-5 ps-3">
            <div className="col-4"></div>
            <div className="col-4 fs-16 text-secondary">Из поля</div>
            <div className="col-4 fs-16 text-secondary">Вывести в поле</div>

            {/* <span
          className=" block-group-btn pointer d-flex justify-content-center"
          onClick={() => {
            setData((data) => {
              data.settings.additFields.splice(i, 1);
              return { ...data };
            });
            // additFields.splice(i, 1);
            // setAdditFields([...additFields]);
          }}
        >
          <i className="bi bi-plus text-danger rotate" />
        </span> */}
          </div>
          {additFieldsHTML}
        </>
      ) : (
        <p className="p-3 pb-0 m-0">
          Нажмите + что бы добавить дополнительные поля для переноса данных или
          нажмите сохранить
        </p>
      )}
      <div className="w-100 mt-4 d-flex justify-content-center">
        <div
          className="block-group-btn pointer d-flex justify-content-center"
          onClick={() => {
            // setAdditFields([...additFields, { from: "", to: "" }]);
            setData((data) => {
              data.settings.additFields.push({ from: "", to: "" });
              return { ...data };
            });
          }}
        >
          <i className="bi bi-plus text-primary" />
        </div>
      </div>
    </div>
  );
};

export default InForModelContainer;
