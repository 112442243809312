// import { observer } from "mobx-react";
import React, { useState, useEffect, useRef } from "react";
// import api from "./api";
import { NavLink } from "react-router-dom";
import api from "../func/api";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { createDefaultMenu, errorCatch } from "../func/common";

// import ProductCard from "./ProductCard";

const defaultIcons = {
  pages: "file",
  collections: "files",
  constructorCMS: "grid-1x2",
  media: "image",
};

const Menu = ({ user, regetMenu, cmsData }) => {
  const dataReady = user !== null && user !== "";

  const [points, setPoints] = useState([]);
  const [openChooseCMSFolder, setOpenChooseCMSFolder] = useState(false);
  const [CMSList, setCMSList] = useState([]);
  const location = useLocation();

  const [openFolder, setOpenFolder] = useState({});

  useEffect(() => {
    api
      .getSettingsSelect("menu")
      .then((menuSettings) => {
        if (!menuSettings.settings.menu.length) {
          api
            .getConstr()
            .then((result) => {
              setPoints(createDefaultMenu(result));
            })
            .catch((err) => {
              console.log(`err`, err);
            });
        } else {
          setPoints(menuSettings.settings.menu);
        }
      })
      .catch((err) => {
        console.log(`err`, err);
      });

    api
      .getInfoCMSs()
      .then((result) => {
        setCMSList(result);
      })
      .catch((err) => {
        errorCatch(err);
      });
  }, [regetMenu]);

  let catalogCheck = false;
  let blogCheck = false;
  let userCheck = false;

  // catalogCheck =
  //   user.role.catalog.products ||
  //   user.role.catalog.categories ||
  //   user.role.catalog.brands;
  // blogCheck = user.role.blog.posts || user.role.blog.sections;
  // userCheck = user.role.users.list || user.role.users.roles;

  const menuPoints = points.map((el, i) => {
    return (
      <NavLink className="nav-link link-light" to={`/collections/${el.nameDB}`}>
        {el.name}
      </NavLink>
    );
  });

  useEffect(() => {
    points.forEach((el, i) => {
      if (el.folder) {
        openFolder[i] = false;
        el.elems.forEach((point) => {
          if (location.pathname.includes(point.link)) openFolder[i] = true;
        });
      }
    });
    setOpenFolder({ ...openFolder });
  }, [location]);

  let menuPointsWithFolder = [];
  if (dataReady) {
    menuPointsWithFolder = points.map((el, i) => {
      if (el.folder) {
        const childs = el.elems.reduce((arr, point) => {
          if (point.slug) {
            let bool = false;
            if (
              user.role[point.type + "List"] !== undefined &&
              user.role[point.type + "List"][cmsData.slug] !== undefined
            ) {
              if (
                user.role[point.type + "List"][cmsData.slug][point.slug] ===
                  undefined ||
                user.role[point.type + "List"][cmsData.slug][point.slug].default
              ) {
                if (
                  user.role[point.type] &&
                  user.role[point.type][cmsData.slug]
                )
                  bool = Object.keys(user.role[point.type][cmsData.slug]).some(
                    (key) => {
                      return user.role[point.type][cmsData.slug][key];
                    }
                  );
              } else {
                bool = Object.keys(
                  user.role[point.type + "List"][cmsData.slug][point.slug]
                ).some((key) => {
                  return user.role[point.type + "List"][cmsData.slug][
                    point.slug
                  ][key];
                });
              }
              if (bool) {
                arr.push(
                  <NavLink
                    className="nav-link link-light ps-4"
                    to={"/front/" + cmsData.slug + point.link}
                  >
                    {point.name}
                  </NavLink>
                );

                if (point.link === "/collections/categories") {
                  arr.push(
                    <NavLink
                      className="nav-link link-light ps-4"
                      to={"/front/" + cmsData.slug + "/templates/menu"}
                    >
                      Конструктор навигации
                    </NavLink>
                  );
                }
              }
            }
          } else {
            const bool = user.role[point.type]
              ? Object.keys(user.role[point.type]).some((key) => {
                  return user.role[point.type][key];
                })
              : false;
            if (bool) {
              arr.push(
                <NavLink
                  className="nav-link link-light ps-4"
                  to={"/front/" + cmsData.slug + point.link}
                >
                  {point.name}
                </NavLink>
              );
            }
          }
          return arr;
        }, []);

        if (childs && childs.length)
          return (
            <li className="nav-item">
              <a
                className="nav-link link-light"
                role="button"
                onClick={() => {
                  openFolder[i] = !openFolder[i];
                  setOpenFolder({ ...openFolder });
                }}
              >
                <i className="bi bi-folder2 me-2"></i>
                {el.name}
              </a>
              <div className={`collapse ${openFolder[i] ? "show" : ""}`}>
                <ul className="nav bg-dark flex-column p-0">
                  {el.name === "Новое" ? (
                    <NavLink
                      to={`/front/${cmsData.slug}/settings/menu`}
                      className="sort-button"
                    >
                      Отсортировать
                    </NavLink>
                  ) : (
                    ""
                  )}
                  <li className="nav-item">
                    {childs}
                    {/* {el.elems.map((point) => {
                  return (
                    <NavLink className="nav-link link-light" to={point.link}>
                      {point.name}
                    </NavLink>
                  );
                })} */}
                  </li>
                </ul>
              </div>
            </li>
          );
      } else {
        let bool = false;
        if (el.slug) {
          if (user.role[el.type + "List"][cmsData.slug] !== undefined) {
            if (
              user.role[el.type + "List"][cmsData.slug][el.slug] ===
                undefined ||
              (user.role[el.type + "List"][cmsData.slug][el.slug] !==
                undefined &&
                user.role[el.type + "List"][cmsData.slug][el.slug].default)
            ) {
              if (user.role[el.type] && user.role[el.type][cmsData.slug])
                bool = Object.keys(user.role[el.type][cmsData.slug]).some(
                  (key) => {
                    return user.role[el.type][cmsData.slug][key];
                  }
                );
            } else {
              bool = Object.keys(
                user.role[el.type + "List"][cmsData.slug][el.slug]
              ).some((key) => {
                return user.role[el.type + "List"][cmsData.slug][el.slug][key];
              });
            }
          }
        } else {
          // console.log(`el.type + "List"`, el.type, user.role[el.type]);
          if (user.role[el.type] !== undefined)
            bool = Object.keys(user.role[el.type]).some((key) => {
              return user.role[el.type][key];
            });
        }
        if (bool)
          return (
            <NavLink
              className="nav-link link-light"
              to={"/front/" + cmsData.slug + el.link}
              key={el.name}
            >
              <i className={`bi bi-${defaultIcons[el.type] ?? ""} me-2`}></i>
              {el.name}
            </NavLink>
          );
      }
    });
  }
  // console.log(`openFolder`, openFolder);

  return (
    dataReady && (
      <ul
        className="sidebar nav nav-pills flex-column fixed-top overflow-scroll flex-nowrap justify-content-between"
        style={{ zIndex: "5", top: "0px" }}
      >
        {/* {user.role.pages && (
        <li className="nav-item">
          <NavLink className="nav-link link-light" to="/pages">
            Pages list
          </NavLink>
        </li>
      )} */}
        {/* <NavLink className="nav-link link-light" to="/constructor">
        Конструктор
      </NavLink>

      {menuPoints}
      <NavLink className="nav-link link-light" to="/media">
        Media
      </NavLink>
      <NavLink className="nav-link link-light" to={`/settings/menu`}>
        Settings menu
      </NavLink> */}
        <div>
          <li className="nav-item">
            <a
              className="nav-link link-light pt-3 pb-3"
              role="button"
              onClick={() => {
                setOpenChooseCMSFolder(!openChooseCMSFolder);
              }}
              style={{ borderBottom: "1px solid #23272C" }}
            >
              {/* <i className="bi bi-folder2 me-2"></i> */}
              {cmsData.name}
            </a>
            <div className={`collapse ${openChooseCMSFolder ? "show" : ""}`}>
              <ul className="nav bg-dark flex-column p-0">
                <li className="nav-item">
                  {CMSList.map((cms) => (
                    <a
                      className="nav-link link-light ps-4"
                      href={"/front/" + cms.slug}
                    >
                      {cms.name}
                    </a>
                  ))}
                </li>
              </ul>
            </div>
          </li>{" "}
          {menuPointsWithFolder}
          <NavLink
            className="nav-link link-light"
            to={`/front/${cmsData.slug}/profile`}
          >
            <i className="bi bi-person me-2"></i>
            Профиль
          </NavLink>
        </div>
        <div>
          <button
            className="nav-link link-light"
            onClick={() => {
              fetch(`https://api.motorfirst.ru/get-ts-file`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
              })
                .then((res) => {
                  let json = res.json();
                  if (res.ok) {
                    return json;
                  }
                  return json.then((err) => {
                    throw err;
                  });
                })
                .then((res) => {
                  const aLink = document.createElement("a");
                  aLink.href = "https://api.motorfirst.ru/temps/" + res.name;
                  aLink.target = "_blank";
                  aLink.download = "exportTS.xlsx";
                  aLink.click();
                })
                .catch((err) => {
                  console.error("err :>> ", err);
                });
            }}
          >
            Выгрузка цен ТС
          </button>
          <a
            className="nav-link link-light"
            href=""
            onClick={(e) => {
              e.preventDefault();
              api
                .logout()
                .then(() => {
                  window.location.href = "/login";
                })
                .catch((err) => {
                  console.log(`err`, err);
                });
            }}
          >
            <i className="bi bi-box-arrow-right me-2"></i>
            Выход
          </a>
        </div>
        {/* {catalogCheck && (
          <li className="nav-item">
            <a
              className="nav-link link-light"
              data-bs-toggle="collapse"
              href="#collapseStore"
              role="button"
              aria-expanded="false"
              aria-controls="collapseStore"
            >
              Catalog
            </a>
            <div className="collapse" id="collapseStore">
              <ul className="nav bg-dark flex-column">
                <li className="nav-item">
                  {user.role.catalog.products && (
                    <NavLink className="nav-link link-light" to="/products">
                      Products
                    </NavLink>
                  )}
                  {user.role.catalog.categories && (
                    <NavLink className="nav-link link-light" to="/categories">
                      Categories
                    </NavLink>
                  )}
                  {user.role.catalog.brands && (
                    <NavLink className="nav-link link-light" to="/brands">
                      Brands
                    </NavLink>
                  )}
                </li>
              </ul>
            </div>
          </li>
        )}
        {blogCheck && (
          <li className="nav-item">
            <a
              className="nav-link link-light"
              data-bs-toggle="collapse"
              href="#collapseElems"
              role="button"
              aria-expanded="false"
              aria-controls="collapseElems"
            >
              Blog
            </a>
            <div className="collapse" id="collapseElems">
              <ul className="nav bg-dark flex-column">
                <li className="nav-item">
                  {user.role.blog.posts && (
                    <NavLink className="nav-link link-light" to="/posts">
                      Posts
                    </NavLink>
                  )}
                  {user.role.blog.sections && (
                    <NavLink className="nav-link link-light" to="/sections">
                      Sections
                    </NavLink>
                  )}
                </li>
              </ul>
            </div>
          </li>
        )}
        {userCheck && (
          <li className="nav-item">
            <a
              className="nav-link link-light"
              data-bs-toggle="collapse"
              href="#collapseUsers"
              role="button"
              aria-expanded="false"
              aria-controls="collapseUsers"
            >
              Users
            </a>
            <div className="collapse" id="collapseUsers">
              <ul className="nav bg-dark flex-column">
                <li className="nav-item">
                  {user.role.users.list && (
                    <NavLink className="nav-link link-light" to="/users">
                      List
                    </NavLink>
                  )}
                  {user.role.users.roles && (
                    <NavLink className="nav-link link-light" to="/roles">
                      Roles
                    </NavLink>
                  )}
                </li>
              </ul>
            </div>
          </li>
        )} */}
        {/* <li className="nav-item">
      <a
        className="nav-link link-light"
        data-bs-toggle="collapse"
        href="#collapseSettings"
        role="button"
        aria-expanded="false"
        aria-controls="collapseUsers"
      >
        Site settings
      </a>
      <div className="collapse" id="collapseSettings">
        <ul className="nav bg-dark flex-column">
          <li className="nav-item">
            <NavLink className="nav-link link-light" to="/meta">
              Metadata
            </NavLink>
            <NavLink className="nav-link link-light" to="/styles">
              Styles
            </NavLink>
            <NavLink className="nav-link link-light" to="/scripts">
              Scripts
            </NavLink>
            <NavLink className="nav-link link-light" to="/integrations">
              Integration
            </NavLink>
            <NavLink className="nav-link link-light" to="/information">
              Information
            </NavLink>
            <a className="nav-link link-light" href="edit-meta.html">
              Метаданные
            </a>
            <a className="nav-link link-light" href="edit-style.html">
              Стили
            </a>
            <a className="nav-link link-light" href="edit-scripts.html">
              Скрипты
            </a>
            <a className="nav-link link-light" href="integrations.html">
              Интеграции
            </a>
            <a className="nav-link link-light" href="edit-info.html">
              Информация
            </a>
          </li>
        </ul>
      </div>
    </li> */}
        {/* {user.role.media && (
          <li className="nav-item">
            <a
              className="nav-link link-light"
              data-bs-toggle="collapse"
              href="#collapseMedia"
              role="button"
              aria-expanded="false"
              aria-controls="collapseMedia"
            >
              Media
            </a>
            <div className="collapse" id="collapseMedia">
              <ul className="nav bg-dark flex-column">
                <li className="nav-item">
                  <NavLink className="nav-link link-light" to="/media">
                    Images
                  </NavLink>
                  <NavLink className="nav-link link-light" to="/documents">
                    Documents
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
        )} */}
        {/* <li className="nav-item">
        <NavLink className="nav-link link-light" to="/profile">
          Profile
        </NavLink>
      </li> */}
      </ul>
    )
  );
};

export default Menu;
